import { FETCH_TAKE_ACTIONS } from '../actions/types';
const initialState = {
  takeActions: {},
};

export const TakeActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAKE_ACTIONS:
      return {
        ...state,
        takeActions: action.payload.takeActions,
      };
    default:
    return {...state};
  }
}