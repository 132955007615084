import React, { useEffect, useState } from 'react';
import blankImage from '../../../blankImage.png'
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { editHome, fetchHomes } from '../../../actions/homeActions';
import { Upload, Button, message, InputNumber , Row, Col, Modal, Input, Select } from 'antd';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';

const AddEditHome = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  const [description, setdescription] = useState('')
  const [applyLink, setapplyLink] = useState('')
  const [name, setname] = useState('')
  const [startupsNum, setstartupsNum] = useState(0)
  const [trainingHoursNum, settrainingHoursNum] = useState(0)
  const [mentorsNum, setmentorsNum] = useState(0)
  const [cohortsNum, setcohortsNum] = useState(0)
  const [ourPartnerName, setourPartnerName] = useState('')
  const [ourPartnerDescription, setourPartnerDescription] = useState('')
  const [file, setFile] = useState(null)
  // neccesary
  const [fileRead, setFileRead] = useState(null)
  const [loading, setloading] = useState(false)
  let dispatch = useDispatch();

  useEffect(async () => {
    await dispatch(fetchHomes());
    if (action === 'Edit') {
      setdescription(payload.description)
      setapplyLink(payload.applyLink)
      setname(payload.name)
      setloading(true)
      setFileRead(getFileLink(payload.logo))
      setloading(false)
      setstartupsNum(payload.startupsNum)
      settrainingHoursNum(payload.trainingHoursNum)
      setmentorsNum(payload.mentorsNum)
      setcohortsNum(payload.cohortsNum)
      setourPartnerName(payload.ourPartnerName)
      setourPartnerDescription(payload.ourPartnerDescription)
    }
  },[dispatch]);
  function validate() {
    if(!name){
      message.error('Please provide name!');
      return
    }
    let data = new FormData();
    data.append('description', description);
    data.append('applyLink', applyLink);
    data.append('name', name);
    data.append('startupsNum', startupsNum);
    data.append('trainingHoursNum', trainingHoursNum);
    data.append('mentorsNum', mentorsNum);
    data.append('cohortsNum', cohortsNum);
    data.append('ourPartnerName', ourPartnerName);
    data.append('ourPartnerDescription', ourPartnerDescription);
    if (file) data.append('logo', file);
    data.append('code', payload.code);
    dispatch(editHome(data));
    message.success('updated!')
    handleOk();
  }
  
  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFile(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFileRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFile(null);
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal title={action+' Home'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Col span={24}>
            <Upload
              {...uploaderProps}
            >
              {fileRead ? <img src={fileRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Title on Cover" value={name} onChange={(evt) => {setname(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Description on Cover <Input.TextArea {...textSizeAreaOptions()} value={description} onChange={(evt) => {setdescription(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Apply Now Url on Cover<Input addonBefore={"http:// or https://"} value={applyLink} onChange={(evt) => {setapplyLink(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><InputNumber addonBefore="Startups Count" className='col-12' value={startupsNum} onChange={(evt) => {setstartupsNum(evt);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><InputNumber addonBefore="Training Hours" className='col-12' value={trainingHoursNum} onChange={(evt) => {settrainingHoursNum(evt);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><InputNumber addonBefore="Mentors Count" className='col-12' value={mentorsNum} onChange={(evt) => {setmentorsNum(evt);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><InputNumber addonBefore="Cohorts Count" className='col-12' value={cohortsNum} onChange={(evt) => {setcohortsNum(evt);}} /></Col>
          <hr/>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Our Partners - Name" className='' value={ourPartnerName} onChange={(evt) => {setourPartnerName(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Our Partners - Description <Input.TextArea {...textSizeAreaOptions()} value={ourPartnerDescription} onChange={(evt) => {setourPartnerDescription(evt.target.value);}} /></Col>
        </Row>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  homes: state.home.homes,
});

export default connect(mapStateToProps, null)(AddEditHome);