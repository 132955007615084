import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { editPartial, fetchPartials } from '../../../actions/partialActions';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import { Divider, Upload, Button, message, InputNumber , Row, Col, Modal, Input } from 'antd';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import { mainColors } from '../../../values/colors';

const AddEditPartial = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  const [teamDescription, setteamDescription] = useState('')
  const [teamName, setteamName] = useState('')
  const [teamLogo, setteamLogo] = useState(null)
  const [teamLogoRead, setteamLogoRead] = useState(null)
  let [loading, setloading] = useState(null);
  let dispatch = useDispatch();

  let {code} = useParams();

  useEffect(async () => {
    await dispatch(fetchPartials());
      setteamDescription(payload.teamDescription);
      setteamName(payload.teamName)
      setteamLogoRead(getFileLink(payload.teamLogo))
    },[dispatch]);

  function validate() {
    let data = new FormData();
    data.append('teamName', teamName)
    data.append('teamDescription', teamDescription)
    if (teamLogo) data.append('teamLogo', teamLogo)
    data.append('code', payload.code)
    dispatch(editPartial(data, code));
    handleOk();
    message.success('Updated!')
  }

  const teamuploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setteamLogo(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setteamLogoRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setteamLogo(null);
        setloading(false)
      }
    },
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal title={action+' Partial'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Col span={24}>
            Cover
            <Upload
              {...teamuploaderProps}
            >
              {teamLogoRead ? <img src={teamLogoRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Name <Input value={teamName} defaultValue={teamName} onChange={(evt) => {setteamName(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Description <Input.TextArea {...textSizeAreaOptions()} value={teamDescription} onChange={(evt) => {setteamDescription(evt.target.value);}} /></Col>
        </Row>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  partials: state.partial.partials,
});

export default connect(mapStateToProps, null)(AddEditPartial);