import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { editProfile, userAuth } from '../../../actions/admin/adminAuthActions';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import { Divider, Upload, Button, message, InputNumber , Row, Col, Modal, Input } from 'antd';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import { mainColors } from '../../../values/colors';

const EditProfile = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  const [username, setusername] = useState('')
  const [name, setname] = useState('')
  const [password, setpassword] = useState(null)
  const [confirmPassword, setconfirmPassword] = useState(null)
  const [avatar, setavatar] = useState(null)
  const [avatarRead, setavatarRead] = useState(null)
  let [loading, setloading] = useState(false);
  useEffect(() => {
    setusername(payload?.username);
    setname(payload?.name);
    setavatarRead(getFileLink(payload.avatar))
  },[payload]);
  let dispatch = useDispatch();
  function validate() {
    let data = new FormData();
    data.append('name', name)
    data.append('username', username)
    if (password && confirmPassword) {
      if (password === confirmPassword) {
        data.append('password', password)
      } else {
        message.error("Password mismatch")
        return
      }
    }
    if (avatar) data.append('avatar', avatar)
    data.append('code', payload.code)
    dispatch(editProfile(data));
    handleOk();
    message.success('Updated!')
  }
  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setavatar(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setavatarRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setavatar(null);
        setloading(false)
      }
    },
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Modal title={'Edit Profile'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Col span={24}>
            Cover
            <Upload
              {...uploaderProps}
            >
              <div className="fillImg">
                {avatarRead ? <img src={avatarRead} alt="Profile Picture" style={{ overflow:'hidden', width: '100%' }} /> : uploadButton}
              </div>
            </Upload>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Name <Input value={name} defaultValue={name} onChange={(evt) => {setname(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Username <Input value={username} defaultValue={username} onChange={(evt) => {setusername(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Password <Input.Password value={password} defaultValue={password} onChange={(evt) => {setpassword(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Confirm Password <Input.Password value={confirmPassword} defaultValue={confirmPassword} onChange={(evt) => {setconfirmPassword(evt.target.value);}} /></Col>
        </Row>
      </Modal>
    </>
  );
};
export default EditProfile;