import React, { useState,useRef, useEffect } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchPrograms } from '../actions/programActions';
import { fetchStartups } from '../actions/startupActions';
import StartupSection from '../components/home/StartupSection'
import {Container} from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import { DividerSvg } from '../components/DividerSvg'
import Box from '@mui/material/Box';
import { mainColors } from '../values/colors';
import {btn,countingNumStyle} from '../values/styles'
import humanizeDuration from 'humanize-duration'
import { getFileLink } from '../components/LibraryMethods';
import { fetchFooters } from '../actions/footerActions';
import SocialMediaComp from '../components/SocialMedias';
import TkwnButton from '../components/linkButtonLong';
const isLgSc = window.innerWidth > 600
const Program = (...rest) => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPrograms());
    dispatch(fetchStartups());
    dispatch(fetchFooters());
  },[dispatch]);
  let programs = useSelector(state => state.program.programs);
  let startups = useSelector(state => state.startup.startups);
  let currentCode = useParams()?.code
  let singleProgram = programs.find(e => {return e.code === currentCode})
  let programStartups = startups?.filter(i => {return i.programCode === currentCode})
  const logoAddress = getFileLink(singleProgram?.logo)
  const startedDate = new Date(singleProgram?.startDate) < new Date()
  const endedDate = new Date(singleProgram?.endDate) > new Date()
  console.log("programStartups",programStartups);
  return (
    <>
      <div
        style={{
          width: '100%',
          minHeight: '100vh',
        }}
      >
        <div
        style={{
          width: '100%',
          padding: 'auto',
          height: '500px',
          backgroundImage: `URL(${logoAddress})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        className='d-flex justify-items-center align-items-center '
        >
        </div>
        <div
        style={{
          height: '100%',
          width:'100%',
        }}
        className='py-auto container justifyitems-center align-items-center d-flex '
        >
          <div className='col' style={{position:'relative'}}>
            <div className="d-flex justify-content-center align-items-center" style={{position:'relative'}}>
              <span className="d-flex justify-content-center align-items-center" style={{position:'absolute'}}>
                <div className='text-start' style={{position:'absolute', fontSize: '40px',fontFamily: 'JostBoldItalic', color: mainColors.white}}>{singleProgram?.name}</div>
                <DividerSvg type="red" />
              </span>
            </div>
          </div>
        </div>
        <Container className='my-3 pt-5'>
          <div style={{color:mainColors.blue, fontFamily:'Roboto',f1ontSize: '24px'}} className='p-wrap text-center'>{singleProgram?.description}</div>
        </Container>
        { (singleProgram?.title1 && singleProgram?.title1 !== '') ? (
          <Container className='my-3 py-5'>
            <div style={{color:mainColors.blue, fontFamily:'JostItalicBold', fontSize: '28px'}} className='d-flex justify-content-lg-between justify-content-md-between justify-content-sm-center justify-content-xs-center ps-lg-5 ps-md-5 flex-wrap'>
              <div style={{fontFamily:'JostBoldItalic',color:mainColors.blue, fontSize: '28px'}}>{singleProgram?.title1}</div>
              <div className={ (isLgSc ? '' : 'col-12 ') + ' px-5 py-2'} style={{fontSize:'28px', fontFamily:'Roboto',...(btn.btnRed),color:mainColors.white}}>Duration: {singleProgram.duration || '0 Days'}</div>
            </div>
            <div style={{border: `2px solid ${mainColors.blue}`, fontSize: '17px',color:mainColors.blue, fontFamily:'Roboto'}} className='p-4'>
              <div style={{color:mainColors.blue}}>
                <div className="text-start p-wrap" dangerouslySetInnerHTML={{ __html:singleProgram?.body1}}></div>
              </div>
            </div>
          </Container>
          ) : ''
        }
        { (singleProgram?.title2 && singleProgram?.title2 !== '') ? (
          <Container className='my-3 py-5'>
            <div style={{color:mainColors.blue, fontFamily:'JostItalicBold', fontSize: '28px'}} className='d-flex justify-content-between px-5'>
              <div style={{fontFamily:'JostBoldItalic',color:mainColors.blue}}>{singleProgram?.title2}</div>
            </div>
            <div style={{border: `2px solid ${mainColors.blue}`, fontSize: '17px',color:mainColors.blue, fontFamily:'Roboto'}} className='p-4'>
              <div style={{color:mainColors.blue}}>
                <div className="text-start p-wrap" dangerouslySetInnerHTML={{ __html:singleProgram?.body2}}></div>
              </div>
            </div>
          </Container>
          ) : ''
        }
        { (singleProgram?.title3 && singleProgram?.title3 !== '') ? (

          <Container fluid={true} style={{backgroundColor: mainColors.blue}}>
            <Container className='my-3 py-5'>
              <div style={{color:mainColors.white, fontFamily:'JostItalicBold', fontSize: '28px'}} className='d-flex justify-content-between px-5'>
                <div style={{fontFamily:'JostBoldItalic',color:mainColors.white}}>{singleProgram?.title3}</div>
              </div>
              <div style={{fontSize: '17px',color:mainColors.blue, fontFamily:'Roboto'}} className='p-4'>
                <div style={{color:mainColors.white}}>
                  <div className="text-start p-wrap" dangerouslySetInnerHTML={{ __html:singleProgram?.body3}}></div>
                </div>
              </div>
            </Container>
          </Container>
          ) : ''
        }
        <Container>
          {(startedDate && endedDate) ? (
            (singleProgram?.refLink && singleProgram?.refLink !== '') ? (
              <div className="col-12 py-3">
                <TkwnButton text={'Apply Now'} color={mainColors.red} toUrl={singleProgram?.refLink} />
              </div>
            ) : (<></>)
          ) : (
            (endedDate === false) ? (
              <div className="col-12 py-3">
                <span className={(isLgSc ? "" : "flex-wrap") + ' d-flex justify-content-between align-items-center'}>
                  <DividerSvg type="expiredDuration" />
                </span>
              </div>
            ) : (
              <div className="col-12 py-3">
                <span style={{color: mainColors.blue}} className={(isLgSc ? "" : "flex-wrap") + ' d-flex justify-content-between align-items-center'}>The application will be available soon!</span>
              </div>
            )
          )}
        </Container>
        <Container fluid={true} className='pt-5 px-0'>
          <StartupSection title={'smallSquareParticipatedStartups'} startups={programStartups}/>
        </Container>
      </div>
      </>
    );
  }
  const mapStateToProps = state => {
    return ({
      programs: state.program.programs,
      startups: state.startup.startups,
      footers: state.footer.footers,
    })
  };
  export default connect(mapStateToProps,null)(Program);