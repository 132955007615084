import { FETCH_STARTUPS } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = 'startups';

export const fetchStartups = () => async dispatch => {
  try {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/`);
      dispatch({
        type: FETCH_STARTUPS,
        payload: {
          startups: response.data.data.sort((a,b) => {return a["id"] - b["id"] })
        }
      })
  } catch(err) {
    console.log(err);
  }
};

export const addStartup = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/create`,payload)
    dispatch(fetchStartups());
  }catch(err) {
    console.log(err)
  }
};

export const editStartup = ( payload, code ) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/update`,payload)
      dispatch(fetchStartups());
  }catch(err) {
      console.log(err)
    }
};

export const deleteImage = (payload) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/delete`,payload)
    dispatch(fetchStartups());
}catch(err) {
    console.log(err)
  }
}

export const deleteStartups = (payload, id) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/delete`, payload);
      dispatch(fetchStartups(id));
  }catch(err) {
    }
};