import { FETCH_MENTORS } from '../actions/types';
const initialState = {
  mentors: []
};

export const MentorReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MENTORS:
      return {
        ...state,
        mentors: action.payload.mentors,
      };
    default:
      return {...state};
  }
}