import React, { useState } from 'react';
import { Card, Divider, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deletePrograms } from '../../../actions/programActions';
import AddEditProgram from './AddEditProgram';
import { Link, useParams } from 'react-router-dom';
import { VisibilityOutlined } from '@mui/icons-material';
import { mainColors } from '../../../values/colors';
import moment from 'moment'
const { Meta } = Card;

const ProgramCard = ({program}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const showModal = (action) => {
    setAction(action);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelConfirm = () => {
    setIsConfirmDeleteVisible(false)
  }
  const handleOkConfirm = () => {
    dispatch(deletePrograms({code:program.code}))
    setIsConfirmDeleteVisible(false);
  }
  const showModalConfirm = () => {
    setIsConfirmDeleteVisible(true);
  };

return(
  <>
  <Card
    style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
    cover={
      <>
        <div style={{backgroundColor: mainColors.light,width: '100%', height: 400}} className='fillImg' >
          <img
            src={program.logo ? `${process.env.REACT_APP_API_URL}/${program.logo}`  : 'https://icon-library.com/images/1041139-200_42853.png'}
            alt="avatar"
          />
        </div>
        <Divider style={{color: mainColors.blue, fontSize: '28px'}}></Divider>
        <div className='text-start col-12'>
          <span style={{fontSize: '22px'}} className='col'><b>Name: </b></span>
          <span style={{fontSize: '19px', height: '87px'}} className='p-elipsis col'>{program.name}</span>
        </div>
        <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description: </b></span><span style={{fontSize: '19px'}} className='p-elipsis p-wrap col'>{program.description?.substring(0,100)}</span></div>
        <div className='text-start col-12'>
          <span style={{fontSize: '22px'}} className='col'><b>Apply Link: </b></span>
          <span style={{fontSize: '19px'}} className='p-elipsis col'>{program.refLink}</span>
        </div>
        <div className='text-start col-12'>
          <span style={{fontSize: '22px'}} className='col'><b>Start Date: </b></span>
          <span style={{fontSize: '19px'}} className='p-wrap col'>{program.startDate ? moment(program.startDate).format('YYYY-MM-DD').toString() : null }</span>
        </div>
        <div className='text-start col-12'>
          <span style={{fontSize: '22px'}} className='col'><b>End Date: </b></span>
          <span style={{fontSize: '19px'}} className='p-wrap col'>{program.endDate ? moment(program.endDate).format('YYYY-MM-DD').toString() : null }</span>
        </div>
        <Modal title="Delete Image" visible={isConfirmDeleteVisible} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
          <p>Are you sure?</p>
        </Modal>
      </>
    }
    actions={[
      <DeleteOutlined onClick={()=> showModalConfirm()} key={"delete" + program.id} />,
      <EditOutlined onClick={()=>showModal('Edit')} key={"edit" + program.id} />,
      // <Link to={ "/programs/" + program.code}><VisibilityOutlined onClick={()=>showModal('Edit')} key={"edit" + program.id} /></Link>,
    ]}
  />
  { isModalVisible &&
   <AddEditProgram action={action} payload={program} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
  }
  </>
)};

export default ProgramCard;
