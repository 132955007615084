import { Link} from 'react-router-dom'
import { Row, Col, Button } from 'antd';
import {mainColors} from '../../values/colors'
import {btn} from '../../values/styles'
import {Container} from 'react-bootstrap'
import TkwnButton from '../linkButtonLong'

const TakeActionSection = ({takeActions}) => {
  console.log("takeacion: ", takeActions)
  return (
    <>
      <Container className='my-5 d-flex flex-wrap justify-content-start align-content-center text-start flex-wrap' style={{height: "614px"}}>
        <div className="text-center col-12">
          <div style={{color: mainColors.blue, fontFamily:'JostBoldItalic', fontSize: '40px'}}>Take Action</div>
          <div style={{color: mainColors.blue, fontFamily: 'RobotoLight', fontSize: "17px"}}>The Takween Accelerator is the first accelerator in Iraq, created to help growth-stage technology businesses go farther faster.</div>
        </div>
        <div className="col-12 py-3"><TkwnButton text={'Join our Investors Network'} color={mainColors.red} toUrl={takeActions?.invLink} /></div>
        <div className="col-12 py-3"><TkwnButton text={'Volunteer with Takween'} color={mainColors.red} toUrl={takeActions?.vlnLink} /></div>
        <div className="col-12 py-3"><TkwnButton text={'Become a Mentor'} color={mainColors.red} toUrl={takeActions?.mentorLink} /></div>
        <div className="col-12 py-3"><TkwnButton text={'Become a Trainer'} color={mainColors.red} toUrl={takeActions?.trainerLink} /></div>
        <div className="col-12 pt-3"><TkwnButton text={'Join the Team'} color={mainColors.red} toUrl={takeActions?.teamLink} /></div>
      </Container>
    </>
  );
};

export default TakeActionSection;