import React, { useState } from 'react';
import { CloseSquareOutlined, EditOutlined, DeleteOutlined,AppstoreAddOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteGalleries } from '../../../actions/galleryActions';
import AddEditGallery from './AddEditGallery';
import { Link, useParams } from 'react-router-dom';
import { AddOutlined, VisibilityOutlined } from '@mui/icons-material';
import GalleryImageCard from './GalleryImageCard';
import AddEditGalleryImage from './AddEditGalleryImage';
import { Card,Row,Col,Modal } from 'antd';

const { Meta } = Card;

const GalleryCard = ({gallery}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalImageVisible, setIsModalImageVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (action) => {
    setAction(action);
    setIsModalVisible(true);
  };

  const handleOkImage = () => {
    setIsModalImageVisible(false);
  };

  const handleCancelImage = () => {
    setIsModalImageVisible(false);
  };

  const showModalImage = () => {
    setIsModalImageVisible(true);
  };

  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const handleCancelConfirm = () => {
    setIsConfirmDeleteVisible(false)
  }
  const handleOkConfirm = () => {
    dispatch(deleteGalleries({code:gallery.code}))
    setIsConfirmDeleteVisible(false);
  }
  const showModalConfirm = () => {
    setIsConfirmDeleteVisible(true);
  };

  const currentGalleryAttachments = gallery?.galleries
return(
  <>
    <Card
    className='shadow'
      style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
      cover={
        <div key={"galleryCard" + gallery.code}>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Name: </b></span><span style={{fontSize: '19px'}} className='col'>{gallery.name}</span></div>
          <hr />
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Slide Images: </b></span></div>
          <Row gutter={[20,20]} className="p-2 col-12">
          {currentGalleryAttachments.map(qwer => {
            return <Col key={qwer.code} style={{borderRadius:5}} span={6}>
              <GalleryImageCard galleryImage={qwer}/>
            </Col>
          })}
            <Col
              key="addGalleryImageButtonLastCol"
              span={6}
            >
              <div
                onClick={showModalImage}
                className='rounded shadow btn clickable hoverable d-flex justify-content-center align-items-center'
                style={{height: '150px'}}
              >
                <AddOutlined style={{fontSize:24}} onClick={showModalImage} key={"addImage" + gallery.id} />
              </div>
            </Col>
          </Row>
          <Modal title="Delete Image" visible={isConfirmDeleteVisible} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
            <p>Are you sure?</p>
          </Modal>
        </div>
      }
      actions={[
        <DeleteOutlined onClick={()=> showModalConfirm()} key={"delete" + gallery.id} />,
        <EditOutlined onClick={()=>showModal('Edit')} key={"editGallery" + gallery.id} />,
      ]}
    />
    { isModalImageVisible &&
    <AddEditGalleryImage action={'Add'} payload={{galleryCode: gallery.code}} handleCancel={handleCancelImage} handleOk={handleOkImage} isModalVisible={isModalImageVisible}  />
    }
    { isModalVisible &&
    <AddEditGallery action={action} payload={gallery} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
    }
  </>
)};

export default GalleryCard;
