import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { addStartup, editStartup, fetchStartups } from '../../../actions/startupActions';
import { useParams } from 'react-router-dom';
import { Upload,Select, Button, message, InputNumber , Row, Col, Modal, Input } from 'antd';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const { Option } = Select;

const AddEditStartup = ({isModalVisible, handleOk, programs, handleCancel, action, payload}) => {
  let [name, setName] = useState('');
  let [refLink, setRefLink] = useState('');
  let [description, setDescription] = useState('');
  let [programCode, setprogramCode] = useState('');
  let [file, setFile] = useState(null);
  let [status, setStatus] = useState(false);
  let dispatch = useDispatch();
  // neccesary
  const [fileRead, setFileRead] = useState(null)
  const [loading, setloading] = useState(false)

  let {code} = useParams();

  useEffect(async () => {
     await dispatch(fetchStartups());
     if(action === 'Edit') {
       setName(payload.name);
       setRefLink(payload.refLink);
       setDescription(payload.description);
       setprogramCode(payload.programCode);
       setFileRead(getFileLink(payload.logo));
       setStatus(payload.status === 'featured' ? true : false)
     }
    },[dispatch]);


  function validate() {
    if(!name){
      alert('Please provide name!');
      return
    }
    let data = new FormData();
    data.append('name', name);
    data.append('refLink', refLink);
    data.append('description', description);
    data.append('programCode',programCode)
    data.append('status', status ? 'featured' : '');
    if(file) {
    data.append('logo', file);
    }
    if (action === 'Add') {
      dispatch(addStartup(data, code));
      message.success("Added!")
    } else {
      console.log(file)
      data.append('code', payload.code)
      dispatch(editStartup(data, code));
      message.success("Update!")
    }
    handleOk();
  }

  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFile(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFileRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFile(null);
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal title={action+' Startup'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Col span={24}>
            <Upload
              {...uploaderProps}
            >
              {fileRead ? <img src={fileRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>
            Program:
            <Select autoFocus value={programCode} defaultValue={programCode} placeholder="Choose a Program" className="col-12 my-3" onChange={(evt) => {setprogramCode(evt);}}>
              {programs?.map(e => {
                return (
                  <Option key={"ddecevce" + e.code} value={e.code}>{e.name}</Option>
                )
              })}
            </Select>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Description on Cover <Input.TextArea {...textSizeAreaOptions()} value={description} onChange={(evt) => {setDescription(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Name" value={name} onChange={(evt) => {setName(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Portfolio Link" value={refLink} onChange={(evt) => {setRefLink(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Featured in Success Stories: 
            (<small>Limited to show last 15</small>) <Checkbox className='my-3' checked={status} onChange={(evt) => {setStatus(evt.target.checked);}} placeholder="Featured in Home Page?" /></Col>
        </Row>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  startups: state.startup.startups,
});

export default connect(mapStateToProps, null)(AddEditStartup);