import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import AddEditFooter from "../../../components/admin/Footers/AddEditFooter";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import { fetchFooters } from '../../../actions/footerActions';
import FooterCard from '../../../components/admin/Footers/FooterCard';
import { Link, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { Container } from '@mui/material';
import { fetchStartups } from '../../../actions/startupActions';

const Footers = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [action, setAction] = useState(false);
    let dispatch = useDispatch();

    let { id } = useParams()

    useEffect(() => {
      dispatch(fetchFooters());
      dispatch(fetchStartups());
    },[dispatch]);

    let footers = [useSelector(state => state.footer.footers)];
    let footerSingle = useSelector(state => state.footer.footers);

    const showModal = (action) => {
      setAction(action);
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return (
      <>
        <PageHeader
          className="site-page-header shadow"
          title={'Footers'}
          extra={
            <Button key={"edit" + footerSingle.id} onClick={() => showModal('Edit')} type="primary" icon={<EditOutlined />} />
          }
          onBack={() => window.history.back()}
        />
        <Container>
          <Row gutter={[20,20]} className="my-3 col-12 row" style={{padding: 15}}>
            {footers.length ===0 && <div style={{marginTop:17}}>No footers are available</div>}
            {footers.map((footer,ind) => {
              return (
                <Col span={24} key={ind} className="pa-5">
                  <FooterCard footer={footerSingle} />
                </Col>
              );
            })}
          </Row>
          { isModalVisible &&
            <AddEditFooter action={action} payload={footerSingle} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
          }
        </Container>
      </>
    );
  }

  const mapStateToProps = state => ({
    footers: state.footer.footers,
    startups: state.startup.startups,
  });

  export default connect(mapStateToProps, null)(Footers);