import React, { useState,useEffect } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { DividerSvg } from '../components/DividerSvg'
import { mainColors } from '../values/colors';
import {btn} from '../values/styles'
import { Col, Row, Menu } from "antd";
import {Container} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { LeftOutlined,RightOutlined} from '@ant-design/icons'
import { fetchBlogs } from '../actions/blogActions.js';
import { blogCategoryOptions,parseCatName, getFileLink, groupArr } from '../components/LibraryMethods';
import moment from 'moment';
import Slider from 'react-slick';
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "blueArrows slick-prev slick-arrow" +
      (currentSlide === 0 ? " slick-disabled " : "")
    }
    style={{color: mainColors.white + "!important", }}
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  ><LeftOutlined /></button>
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "blueArrows slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled " : "")
    }
    style={{color: mainColors.white + "!important", }}
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  ><RightOutlined /></button>
);

const blogCategories = [{id: 0,name: 'All',value: 'all'}].concat(blogCategoryOptions)
const Blogs = (...rest) => {
  const [blogSs, setBlogSs] = useState('all')
  const [readyItems, setreadyItems] = useState([])
  const [readyItemSuccess, setreadyItemSuccess] = useState([])
  const [isLgSc, setisLgSc] = useState(window.innerWidth > 600)
  const [isArrowAllowed, setisArrowAllowed] = useState(true)
  const [slideCntBlogs, setslideCntBlogs] = useState(6)
  const [slideCntsuccess, setslideCntsuccess] = useState(2)
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBlogs());
  },[dispatch])
  function parseDat(datt) {
    return datt ? moment(datt).format('DD/MMM/YYYY') : ''
  }
  let blogs = useSelector(state => state.blog.blogs);
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
    setisArrowAllowed(isLgSc)
  }, [isLgSc,readyItems,readyItemSuccess,slideCntsuccess,slideCntBlogs])
  useEffect(() => {
    setreadyItems(groupArr(
      blogs?.filter(erw => {
        return (erw.category !== null && (erw.category === blogSs || (blogSs === 'all' && blogCategories.find(ee => {return ee.value === erw.category}) )))
      })?.map(e => {
        return {code: e.code,createdAt: parseDat(e.createdAt), date: parseDat(e.date),category: e.category,name: e.name, logo: getFileLink(e.logo), summary: e.summary}
      }),slideCntBlogs)
    )
    setreadyItemSuccess(groupArr(
      blogs?.filter(erw => {
        return (erw.category !== null && erw.category === 'story') // check if category is story
      })?.map(e => {
        return {code: e.code,summary: e.summary,createdAt: parseDat(e.createdAt), date: parseDat(e.date),category: e.category,name: e.name, logo: getFileLink(e.logo), description: e.description}
      }),slideCntsuccess)
    )
  },[blogs,blogSs,slideCntBlogs,slideCntsuccess])
  const slideSettings = {
    dots: true,
    arrows: isArrowAllowed,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    dotsClass: ' slick-dots customPartnersSection ',
    swipeToSlide: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft  />,
    slidesToScroll: 1,
  }
  return (
    <>
      <Container fluid="true" style={{backgroundColor: mainColors.light ,height: '162px'}} className='p-auto '>
      </Container>
      <Container className={ (isLgSc ? 'col-6 ' : '') + ' d-flex justify-content-center'}
        style={{
          width:(isLgSc ? '60% ' : '80%'),
          transform:'translateY(-50%)',
          fontSize:'28px'
        }}>
        <Menu
          className={(isLgSc ? '' : 'flex-wrap ' ) + 'justify-content-center py-3'}
          mode={'horizontal'}
          forceSubMenuRender={true}
          style={{
            fontFamily: 'JostMedium',
            minHeight: '60px',
            ...(btn.btnBlueLight),
            backgroundColor: mainColors.white,
          }}
        >
          {blogCategories.map((e,ind) => {
            return <Menu.Item className='px-5 hoveredBorderRed' style={isLgSc ? {borderLeft: ind === 0 ? '' : '2px solid  ' + mainColors.blue} : {}} key={ind}>
              <Link to="#" onClick={lkm => {setBlogSs(e.value)}} style={{color: blogSs === e.value ? mainColors.red : mainColors.blue,textDecoration: 'none'}}>
                {e.name}
              </Link>
            </Menu.Item>
          })}
        </Menu>
      </Container>
      <Container className='py-5'>
        {readyItems.length ? <Slider {...slideSettings} className=" d-flex justify-content-center">
          {readyItems?.map((el,indx1) => {
            return (
              <div className='row col-12 mx-0 px-0'
                key={indx1}
              >
                <Row
                gutter={[20,30]}
                justify="center"
                >
                {el?.map((ee,indx2) => {
                  return (<Col
                    key={indx2}
                    span={isLgSc ? 10 : 20}
                  >
                    <div className='col-12 d-flex justify-content-end'>
                      <div className="py-2 px-5" style={{...btn.btnRed}}>{parseCatName(ee.category)}</div>
                    </div>
                    <Link
                    className='col-12 p-0 d-flex'
                    to={ "/blog/" + ee.code}
                    style={{...btn.btnBlueLight,height:'188px'}}
                    >
                      <span className={(isLgSc ? 'col-5' : 'col-6' ) + " pr-2 py-0"}>
                        <span style={{
                          backgroundImage: `url(${ee.logo})`,
                          height: '100%',
                          flexShrink: '0',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center'
                        }} className="fillImg" />
                      </span>
                      <span className={(isLgSc ? 'col-7' : 'col-6' ) + ' d-flex p-3'} style={{
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                      }}>
                        <div className='text-start'
                          style={{
                            fontFamily:'RobotoItalic',
                            fontSize:'11px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: isLgSc ? '200px' : '100px',
                          }}>
                          {ee.date ? ee.date : ee.createdAt}
                        </div>
                        <div className="text-start mb-4"
                          style={{
                            fontFamily:'JostMedium',
                            fontSize:'22px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: isLgSc ? '200px' : '100px',
                          }}>
                          {ee.name}
                        </div>
                        <div>
                          <p className='p-wrap' style={{
                            fontFamily:'RobotoLight',
                            fontSize:'13px',
                            margin: 0,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: isLgSc ? '200px' : '100px',
                            }} >{ee.summary}</p>
                        </div>
                        <div to={"/blog/" + ee.code} style={{...btn.btnBlueLight, fontFamily:'JostMedium', fontSize: isLgSc ? '22px' : '17px'}} className='col-12 d-flex justify-content-between align-items-center cstm-btn-blue-outline'>
                          <span className=''>Read More</span>
                          <RightOutlined />
                        </div>
                      </span>
                    </Link>
                  </Col>)
                })}
                </Row>
              </div>
            )
          })}
        </Slider> : <></>}
      </Container>
      <Container fluid={true} className="py-5 mt-5 px-0">
        {
          readyItemSuccess.length ? <div className='col' style={{position:'relative'}}>
            <div className="d-flex justify-content-start align-items-center" style={{position:'relative'}}>
              <span className={(isLgSc ? 'justify-content-center ' : 'px-3 justify-content-start ') + "d-flex align-items-center"} style={{background: 'linear-gradient(to right, '+mainColors.red+', transparent)',position:'absolute'}}>
                <div className='text-start' style={{position:'absolute', fontSize: '40px',fontFamily: 'JostBoldItalic', color: mainColors.white}}>Success Stories</div>
                <DividerSvg type="red" />
              </span>
            </div>
          </div> : <></>
        }
      </Container>
      <Container className='py-5'>
        {readyItemSuccess.length ? <Slider {...slideSettings} className=" d-flex justify-content-center">
          {readyItemSuccess?.map((el,indx1) => {
            return (
              <div className='row col-12 mx-0 px-0'
                key={indx1}
              >
                <Row
                gutter={[20,30]}
                justify="center"
                >
                {el?.map((ee,indx2) => {
                  return (<Col
                    key={indx2}
                    span={20}
                  >
                    <div className='col-12 d-flex justify-content-end'>
                      <div className="py-2 px-5" style={{...btn.btnRed}}>{parseCatName(ee.category)}</div>
                    </div>

                    <Link
                      className='col-12 p-0 d-flex'
                      to={ "/blog/" + ee.code}
                      style={{...btn.btnBlueLight,height:'188px'}}
                    >
                      <span className="pr-2 py-0 col-6">
                        <span style={{
                          backgroundImage: `url(${ee.logo})`,
                          height: '100%',
                          flexShrink: '0',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center'
                        }} className="fillImg" />
                      </span>
                      <span className=' d-flex col-6 p-3' style={{
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                      }}>
                        <div className="mb-4" style={{ fontFamily:'JostMedium', fontSize:'22px'}}>
                          <div className='text-start' style={{fontFamily:'RobotoItalic',fontSize:'11px'}}>{ee.date ? ee.date : ee.createdAt}</div>
                          <div className='text-start' style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: isLgSc ? '200px' : '100px',
                          }}>{ee.name}</div>
                          <div>
                          <p className={isLgSc ? '' : 'p-wrap'}
                            style={{
                            fontFamily:'RobotoLight',
                            fontSize:'13px',
                            margin: 0,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: isLgSc ? '400px' : '100px',
                          }} >{ee.summary}</p>
                          </div>
                        </div>
                        <div to={"/blog/" + ee.code} style={{...btn.btnBlueLight, fontFamily:'JostMedium', fontSize:isLgSc ? '22px' : '17px'}} className='cstm-btn-blue-outline col-12 d-flex justify-content-between align-items-center'>
                          <span className=''>Read More</span>
                          <RightOutlined />
                        </div>
                      </span>
                    </Link>
                  </Col>)
                })}
                </Row>
              </div>
            )
          })}
        </Slider> : <></>}
      </Container>

      <Container
          style={{
            width:'100%',
          }}
          className='py-5 mb-5 justify-content-center align-items-center d-flex'
        >
          <Link to='/startups' className='cstm-btn-red-outline px-4 py-2' style={{...btn.btnRedLight,fontFamily:'RobotoBold', fontSize:'22px'}}>
            CHECK OUR PORFOLIO
          </Link>
        </Container>
    </>
  );
}
const mapStateToProps = state => {
  return ({
    blogs: state.blog.blogs,
  })
};
export default connect(mapStateToProps,null)(Blogs);