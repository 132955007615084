import { FETCH_TAKE_ACTIONS,FETCH_FOOTERS } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = "takeActions"

export const fetchTakeActions = () => async dispatch => {
  try {
    let firstResponse = {}
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/`);
    if (response.data.data[0]) {
      firstResponse = response.data.data.reverse()[0]
    }
    dispatch({
      type: FETCH_TAKE_ACTIONS,
      payload: {
        takeActions: firstResponse
      }
    })
  } catch(err) {
    console.log(err);
  }
};

export const editTakeAction = ( payload, code ) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/update`,payload)
      dispatch(fetchTakeActions());
  }catch(err) {
      console.log(err)
    }
};

export const deleteTakeActions = (payload, id) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/delete`, payload);
      dispatch(fetchTakeActions(id));
  }catch(err) {
    }
};