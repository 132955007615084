export const FETCH_PROGRAMS = 'FETCH_PROGRAMS';
export const FETCH_PROGRAM = 'FETCH_PROGRAM';
export const FETCH_STARTUPS = 'FETCH_STARTUPS';
export const FETCH_HOMES = 'FETCH_HOMES';
export const FETCH_ABOUTS = 'FETCH_ABOUTS';
export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_MENTORS = 'FETCH_MENTORS';
export const FETCH_TRAINERS = 'FETCH_TRAINERS';
export const FETCH_FOOTERS = 'FETCH_FOOTERS';
export const FETCH_BLOGS = 'FETCH_BLOGS';
export const FETCH_GALLERIES = 'FETCH_GALLERIES';
export const FETCH_PARTIALS = 'FETCH_PARTIALS';
export const FETCH_TAKE_ACTIONS = 'FETCH_TAKE_ACTIONS';
export const FETCH_AUTTHS = 'FETCH_AUTTHS';
export const FETCH_PARTNERS = 'FETCH_PARTNERS';
export const FETCH_TESTIMONIALS = 'FETCH_TESTIMONIALS';
export const FETCH_LAST_BLOGS = 'FETCH_LAST_BLOGS';
export const FETCH_LAST_GALLERY = 'FETCH_LAST_GALLERY';
export const FETCH_ALL_DASHBOARD = 'FETCH_ALL_DASHBOARD';