import { FETCH_TESTIMONIALS } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = 'testimonials';
export const fetchTestimonials = () => async dispatch => {
  try {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}`);
    console.log("testimonials", response)
      dispatch({
        type: FETCH_TESTIMONIALS,
        payload: {
          testimonials: response.data.data.sort((a,b) => {return b["id"] - a["id"] })
        }
      })
  } catch(err) {
    console.log(err);
  }
};

export const addTestimonial = ( payload, code ) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/create`,payload)
      dispatch(fetchTestimonials());
  }catch(err) {
      console.log(err)
    }
};

export const editTestimonial = ( payload, code ) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/update`,payload)
      dispatch(fetchTestimonials());
  }catch(err) {
      console.log(err)
    }
};

export const deleteTestimonials = (payload, id) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/delete`, payload);
      dispatch(fetchTestimonials(id));
  }catch(err) {
    }
};