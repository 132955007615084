import React, { useState } from 'react';
import { Card,Modal, Badge } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteBlogs } from '../../../actions/blogActions';
import AddEditBlog from './AddEditBlog';
import { Link, useParams } from 'react-router-dom';
import { VisibilityOutlined } from '@mui/icons-material';
import moment from 'moment'
import { mainColors } from '../../../values/colors';
import { getFileLink } from '../../LibraryMethods';
const { Meta } = Card;
function parseDat(datt) {
  return datt ? moment(datt).format('DD/MMM/YYYY') : ''
}

const BlogCard = ({blog}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();
  let createdAt = parseDat(blog?.createdAt)
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (action) => {
    setAction(action);
    setIsModalVisible(true);
  };

  const handleCancelConfirm = () => {
    setIsConfirmDeleteVisible(false)
  }

  const handleOkConfirm = () => {
    dispatch(deleteBlogs({code:blog.code}))
    setIsConfirmDeleteVisible(false);
  }

  const showModalConfirm = () => {
    setIsConfirmDeleteVisible(true);
  };

return(
  <>
    <Card
    className='shadow'
      style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
      cover={
        <>
          <div style={{backgroundColor: mainColors.light,width: '100%', height: 400}} className='fillImg' >
            <img
              src={`${getFileLink(blog?.logo)}`}
              alt="avatar"
            />
          </div>
          <div className='text-start row my-3 col-12 mx-0'>
            <span className='mx-1 px-2 text-center bg-dark square rounded py-1 p-elipsis p-wrap col text-white' style={{fontSize: '19px'}} >{blog.createdAt ? moment(blog.createdAt).format('YYYY-MM-DD').toString() : null}</span>
            <span className='mx-1 px-2 text-center bg-danger square rounded py-1 p-elipsis p-wrap col text-white' style={{fontSize: '19px'}} >{blog.category}</span>
          </div>

          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Name: </b></span><span style={{fontSize: '19px',width: 'parent'}} className='p-wrap col'>{blog.name}</span></div>
          {createdAt && <div className='text-start col-4'>
            <span style={{fontSize: '22px'}} className='col'><b>Date: </b></span>
          </div>}
          {(blog.status === 'featured') && <div className='text-start col-12'>
            <span style={{fontSize: '22px'}} className='col'><b>Status: </b></span>
            <span style={{fontSize: '19px'}} className='col'><p className=' mx-1 square rounded p-1 bg-danger text-white' style={{width: 'fit-content'}}>{blog.status}</p></span>
          </div>}
          <div className='text-start col-12'>
            <span style={{fontSize: '22px'}} className='col'><b>Summary: </b></span>
            <span style={{
              fontSize: '19px',
              height: '245px',
              display: 'flex',
              overflow: 'hidden',
              }} className='col p-wrap'>{blog.summary}</span>
          </div>
          <div className='text-start col-12'>
            <span style={{fontSize: '22px'}} className='col'><b>Description: </b></span>
            <span style={{fontSize: '19px',
              height: '245px',
              display: 'flex',
              overflow: 'hidden',
            }} className='col'>{blog.description}</span>
          </div>
          <Modal title="Delete Image" visible={isConfirmDeleteVisible} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
            <p>Are you sure?</p>
          </Modal>
        </>
      }
      actions={[
        <DeleteOutlined onClick={()=> { showModalConfirm() }} key={"delete" + blog.id} />,
        <EditOutlined onClick={()=>showModal('Edit')} key={"edit" + blog.id} />,
        // <Link to={ "/blogs/" + blog.code}><VisibilityOutlined onClick={()=>showModal('Edit')} key={"edit" + blog.id} /></Link>,
      ]}
    >
    </Card>
    { isModalVisible &&
    <AddEditBlog action={action} payload={blog} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
    }
  </>
)};

export default BlogCard;
