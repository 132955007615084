import React, { useEffect,useState } from 'react';
import { Link} from 'react-router-dom'
import { Carousel, Radio } from 'antd';
import { Card } from 'antd';
import { Row, Col, Divider } from 'antd';
import Box from '@mui/material/Box';
import { mainColors } from '../values/colors';
import { LeftOutlined,RightOutlined} from '@ant-design/icons'
import { fetchBlogs } from '../actions/blogActions'
import {btn} from '../values/styles'
import { Container } from 'react-bootstrap'
import { DividerSvg } from './DividerSvg'
import Slider from 'react-slick';
import {getFileLink} from './LibraryMethods'
const isLgSc = window.innerWidth > 600
const slideSettings = {
  dots: true,
  arrows: isLgSc,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  swipeToSlide: true,
  nextArrow: <RightOutlined />,
  prevArrow: <LeftOutlined />,
  slidesToScroll: 1,
}
const { Meta } = Card;
const contentStyle = {
  height: '160px',
  color: mainColors.white,
  lineHeight: '160px',
  textAlign: 'center',
  background: mainColors.red,
};

const SuccessStories = ({blogss}) => {
  const [isLgSc, setisLgSc] = useState(window.innerWidth > 600)
  const [isArrowAllowed, setisArrowAllowed] = useState(true)
  const [allStartupsCarsl, setreadyItems] = useState([])
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
    setisArrowAllowed(isLgSc)
  }, [isLgSc, blogss?.length])
  const [slideElem,setSlideElem] = useState(null)

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev blueArrows slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    ><LeftOutlined /></button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next blueArrows slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    ><RightOutlined /></button>
  );
  const slideSettings = {
    dots: true,
    arrows: isArrowAllowed,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    dotsClass: ' slick-dots ',
    swipeToSlide: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    slidesToScroll: 1,
  }
  useEffect(() => {
    setreadyItems(blogss?.map(e => {
        return {code: e.code,name: e.name,summary: e.summary ,position: e.position, logo: getFileLink(e.logo), description: e.description}
    }))
  },[blogss])

  if (allStartupsCarsl?.length > 0) {
    return (
      <>
        <Container fluid={true} className="mt-5 px-0">
          {
            allStartupsCarsl.length &&
            <div className='col' style={{zIndex: 1,position:'relative'}}>
              <div className="d-flex justify-content-start align-items-center" style={{position:'relative'}}>
                <span className={(isLgSc ? 'justify-content-center ' : 'px-3 justify-content-start ') + "d-flex align-items-center"} style={{background: 'linear-gradient(to right, '+mainColors.red+', transparent)',position:'absolute'}}>
                  <div className='text-start' style={{position:'absolute', fontSize: '40px',fontFamily: 'JostBoldItalic', color: mainColors.white}}>Success Stories</div>
                  <DividerSvg type="red" />
                </span>
              </div>
            </div>
          }
        </Container>
        <Container fluid className='px-0'
          style={{backgroundColor: (window.innerWidth < 600) && mainColors.light, background: (window.innerWidth > 600) && `linear-gradient(to right, ${mainColors.light} 0%, ${mainColors.light} 50%, ${mainColors.white} 50%, ${mainColors.white} 100%)` }}
        >
          <Container>
            {allStartupsCarsl.length && <Slider className='justify-content-center'
              {...slideSettings}
              style={{height: slideElem}}
              >
              {allStartupsCarsl?.map((e,indx) => {
                return (
                  <div key={indx } ref={ (divElement) => { setSlideElem(divElement) } }>
                    <div
                      className='col d-flex my-4 flex-wrap justify-content-center'
                    >
                      <div
                        className='col-lg-6 col-md-6 col-sm-12 fillImg col-xs-12 container d-flex justify-content-center align-items-center'
                        style={
                          isLgSc ? {height: '345px'} : {marginBottom: '3rem'}
                        }
                        >
                          <img
                            style={{
                              backgroundColor: 'transparent',
                            }}
                            alt="startup logo"
                            src={e.logo}
                          />
                      </div>
                      <div style={{height: 345}} className='col-lg-6 col-md-6 col-sm-12 col-xs-12 container flex-wrap text-start d-flex justify-content-start align-content-between'>
                        <div className='col-12'>
                          <div className='justify-content-start align-items-center px-2' style={{...(btn.btnBlueLight),
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',                            
                            fontFamily: 'JostRegular',fontSize:'20px' }} >{e.name}</div>
                        </div>

                        <div className='col-12'>
                          <div
                            className='scroll-y text-start p-wrap p-2'
                            style={{
                              maxHeight: isLgSc ? 235 : 200,
                              color:mainColors.blue,
                              fontFamily: 'Jost',
                              fontSize:'18px'
                            }}
                          >{e.summary}</div>
                        </div>
                        <div className='col-12'>
                          <Link to={'/blog/' + e.code} className='d-flex cstm-btn-blue-outline justify-content-between align-items-center px-2' style={{width: '180px', ...(btn.btnBlueLight),fontFamily: 'JostRegular',fontSize:'20px' }} >
                            <span >Read More</span>
                            <span className='d-flex align-items-center py-0 '><RightOutlined className='col'/></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Slider>}
            </Container>
        </Container>
      </>
    );
  } else {
    return (<></>)
  }
};

export default SuccessStories;