import { FETCH_TESTIMONIALS } from '../actions/types';
const initialState = {
  testimonials: []
};

export const TestimonialReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.payload.testimonials,
      };
    default:
      return {...state};
  }
}