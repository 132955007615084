import { Upload, Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Modal, Input, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { addGallery, editGallery, fetchGalleries } from '../../../actions/galleryActions';
import { useParams } from 'react-router-dom';
import { Select, Col, Row } from 'antd';
import moment from 'moment'
const { Option, OptGroup } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const AddEditGallery = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  let [name, setName] = useState('');
  let dispatch = useDispatch();

  let {code} = useParams();

  useEffect(async () => {
     await dispatch(fetchGalleries());
     if(action === 'Edit') {
      setName(payload.name)
     }
    },[dispatch]);

  function validate() {
    if(!name){
      alert('Please provide name!');
      return
    }
    let data = {};
    data['name'] = name;
    if(action === 'Add'){
    dispatch(addGallery(data, code));
    }else{
    data['code'] = payload.code;
    dispatch(editGallery(data, code));
    }

    handleOk();
  }

  return (
    <>
      <Modal title={action+' Gallery'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Input addonBefore="Name" className='my-3' value={name} onChange={(evt) => {setName(evt.target.value);}} placeholder="Gallery Name" />
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  galleries: state.gallery.galleries,
});

export default connect(mapStateToProps, null)(AddEditGallery);