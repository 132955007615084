import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/Jost-Black.ttf'
import './fonts/Jost-BlackItalic.ttf'
import './fonts/Jost-Bold.ttf'
import './fonts/Jost-BoldItalic.ttf'
import './fonts/Jost-ExtraBold.ttf'
import './fonts/Jost-ExtraBoldItalic.ttf'
import './fonts/Jost-ExtraLight.ttf'
import './fonts/Jost-ExtraLightItalic.ttf'
import './fonts/Jost-Italic-VariableFont_wght.ttf'
import './fonts/Jost-Italic.ttf'
import './fonts/Jost-Light.ttf'
import './fonts/Jost-LightItalic.ttf'
import './fonts/Jost-Medium.ttf'
import './fonts/Jost-MediumItalic.ttf'
import './fonts/Jost-Regular.ttf'
import './fonts/Jost-SemiBold.ttf'
import './fonts/Jost-SemiBoldItalic.ttf'
import './fonts/Jost-Thin.ttf'
import './fonts/Jost-ThinItalic.ttf'
import './fonts/Jost-VariableFont_wght.ttf'
import App from './App';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);
