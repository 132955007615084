import React, { useState } from 'react';
import { Card } from 'antd';
import { mainColors } from '../../../values/colors';

const { Meta } = Card;

const FooterCard = ({footer}) => {

return(
  <>
    <Card
      className='rounded shadow'
      cover={
        <>
          <div key="tesrljnr" className='text-start p-3 col-12'>
            <span className='fillImg' style={{height:'238px',backgroundColor: mainColors.light, width: '100%'}}>
              <img
                src={footer.logo ? `${process.env.REACT_APP_API_URL}/${footer.logo}`  : 'https://icon-library.com/images/1041139-200_42853.png'}
              />
            </span>
            <div style={{fontSize: '28px'}} className='my-2'>Address: <span style={{fontSize: '17px'}}>{footer.address}</span></div>
            <div style={{fontSize: '28px'}} className='my-2'>Address 2: <span style={{fontSize: '17px'}}>{footer.address2}</span></div>
            <div style={{fontSize: '28px'}} className='my-2'>Contact Us: <span style={{fontSize: '17px'}}>{footer.contactUs}</span></div>
            <div style={{fontSize: '28px'}} className='my-2'>Email: <span style={{fontSize: '17px'}}>{footer.email}</span></div>
            <div style={{fontSize: '28px'}} className='my-2'>Facebook Link: <span style={{fontSize: '17px'}}>{footer.facebookLink}</span></div>
            <div style={{fontSize: '28px'}} className='my-2'>Instagram Link: <span style={{fontSize: '17px'}}>{footer.instagramLink}</span></div>
            <div style={{fontSize: '28px'}} className='my-2'>Linkedin Link: <span style={{fontSize: '17px'}}>{footer.linkedinLink}</span></div>
            <div style={{fontSize: '28px'}} className='my-2'>Twitter Link: <span style={{fontSize: '17px'}}>{footer.twitterLink}</span></div>
            <div style={{fontSize: '28px'}} className='my-2'>Phone: <span style={{fontSize: '17px'}}>{footer.phone}</span></div>
          </div>        
        </>
      }
    >
    </Card>
  </>
)};

export default FooterCard;
