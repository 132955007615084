import { useEffect, useState } from 'react';
import { Card } from 'antd';
import Slider from 'react-slick'
import { getFileLink } from '../LibraryMethods';
import ReactPlayer from 'react-player'
const LastGallerySection = ({lastGallery}) => {
  const [isLgSc, setisLgSc] = useState(true)
  const [galleryImages, setreadyItems] = useState([])
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
  },[isLgSc])
  const slideSettings = {
    dots: true,
    arrows: false,
    swipeToSlide: true,
    slidesToScroll: 1,
    slidesToShow: 1,
  };
  console.log("gallery: ",lastGallery)
  useEffect(() => {
    setreadyItems(lastGallery?.map(e => {
      return {code: e.code,status: e.status, logo: e.logo}
    }))
  },[lastGallery])
  return (
    <>
      {galleryImages.length && <Slider className='d-flex lastGallery justify-content-center' {...slideSettings}>
        {galleryImages?.map((e,indx) => {
          return (
            <Card
            bordered={false}
            className='HomeGallery p-0 align-items-center col-12 d-flex justify-content-center flex-wrap'
            style={{maxWidth:'594px', maxHeight:'calc(594px - 64px)'}}
            key={"gallerySection__" + indx}
            cover={
              <div className={e.status === 'youtube' ? 'd-flex justify-contents-center align-items-center col-12' : "fillImg"}
                style={{width: '100%',height: 'calc(594px - 64px)'}}
              >
                {e?.status === "youtube" ? <ReactPlayer
                    className=""
                    url={e.logo}
                    controls
                    playbackRate = {2}
                    width='100%'
                  /> : <img
                    alt="example"
                    src={getFileLink(e.logo)}
                  />
                }
              </div>
            }
            >
            </Card>          
          )
        })
      }
      </Slider>}
    </>
  );
};

export default LastGallerySection;