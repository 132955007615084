import React, { useState, useEffect } from 'react';
import { Button,Col,Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddEditMentor from "../../../components/admin/Mentors/AddEditMentor";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import { fetchMentors } from '../../../actions/teamActions';
import MentorCard from '../../../components/admin/Mentors/MentorCard';
import { Link, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { Container } from '@mui/material';

const Mentors = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [action, setAction] = useState(false);
    let dispatch = useDispatch();

    let { id } = useParams()

    useEffect(() => {
      dispatch(fetchMentors(id));
    },[dispatch]);

    let mentors = useSelector(state => state.mentor.mentors);

    const showModal = (action) => {
      setAction(action);
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return (
      <>
        <PageHeader
          className="site-page-header shadow"
          title={'Mentors ' + '(' + mentors.length + ')'}
          extra={[
            <Button key="buttonAdd" onClick={() => showModal('Add')} type="primary" icon={<PlusOutlined />} />
          ]}
          onBack={() => window.history.back()}
        />
        <Container>
          <Row gutter={[20,20]} className="my-3 col-12 row" style={{padding: 15}}>
            {mentors.length ===0 && <div style={{marginTop:17}}>No mentors are available</div>}
            {mentors.map((mentor,ind) => {
              return (
                <Col span={12} className="pa-5" key={ind}>
                  <MentorCard mentor={mentor} />
                </Col>
              );
            })}
          </Row>
          { isModalVisible &&
            <AddEditMentor action={action} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}/>
          }
        </Container>
      </>
    );
  }

  const mapStateToProps = state => ({
    mentors: state.mentor.mentors,
  });

  export default connect(mapStateToProps, null)(Mentors);