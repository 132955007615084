import React, { useState,useRef,useEffect } from 'react';
import Slider from "react-slick";
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchStartups } from '../actions/startupActions';
import StartupSection from '../components/home/StartupSection'
import {Container} from 'react-bootstrap'
import { mainColors } from '../values/colors';
import {btn,countingNumStyle, ImgBg, imgBg} from '../values/styles'
import SocialMedias from '../components/SocialMedias'
import { fetchGalleries } from '../actions/galleryActions.js';
import { Menu, Dropdown, Card } from 'antd';
import { LeftOutlined,RightOutlined} from '@ant-design/icons'
import { Row, Col, Button } from 'antd';
import * as moment from 'moment'
import {Svg} from '../components/SvgViewer'
import { DividerSvg } from '../components/DividerSvg'
import ReactPlayer from 'react-player';
import {getFileLink} from '../components/LibraryMethods'
const { Meta } = Card;
const isLgSc = window.innerWidth > 600

const {SubMenu} = Menu
const DemoBox = props => <p className={`height-${props.value}`}>{props.children}</p>;
const Gallery = (...rest) => {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev customGallerySlideArrows slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    ><LeftOutlined /></button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next customGallerySlideArrows slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    ><RightOutlined /></button>
  );
  
  const slideSettings = {
    dots: true,
    arrows: isLgSc,
    infinite: true,
    speed: 500,
    autoplay: false,
    swipeToSlide: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  const MediaViewr = ({url}) => {
    console.log(url)
    if (url.toString().toLowerCase().includes('svg')){
      return <span
        className="fillImg col-12"
        style={{
          backgroundColor: mainColors.blue,
        }}
          alt="Image"
        >
          <Svg url={url}/>
        </span>
    } else {
      return <span
        className="fillImg col-12"
        style={{
          backgroundColor: mainColors.blue,
        }}
        alt="Image"
        >
        <img
          className="col-12"
          style={{
            backgroundColor: mainColors.blue,
            height:'auto'
          }}
          alt="example"
          src={url}
        />
        </span>
    }
  }
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGalleries());
  },[dispatch])
  function parseDat(datt) {
    return datt ? moment(datt).format('DD/MMM/YYYY') : ''
  }
  let galleries = useSelector(state => state.gallery.galleries);
  console.log("galleries", galleries)
  let allProgramsCarsl = []
  if (galleries){
    allProgramsCarsl = galleries.map(e => {return {code: e.code,name: e.name, galleries: e.galleries} }).filter(er => {return er.galleries[0] })
  }

  return (
    <>
      <Container fluid={true} style={{backgroundColor: mainColors.light ,height:'206px'}} className=" px-0 col d-flex justify-content-start align-items-center">
      <div style={{position:'relative'}}>
        <div style={{position:'relative'}}>
          <span className={(isLgSc ? 'justify-content-center ' : 'justify-content-start ') + "d-flex align-items-center"} style={{background: 'linear-gradient(to right, '+mainColors.red+', transparent)',position:'absolute'}}>
            <div className={(isLgSc ? "" : "px-3 " )+ ' text-start'} style={{position:'absolute', fontSize: '40px',fontFamily: 'JostBoldItalic', color: mainColors.white}}>Gallery</div>
            <DividerSvg type="red" />
          </span>
        </div>
      </div>
      </Container>
      <Container fluid className='px-0 d-flex justify-content-center'>
        <Row
        justify="center"
				align="middle"
				className='col-12'
        >
          {allProgramsCarsl?.map((ee,indexArr) => {
            return (
              <Col span={24} className='d-flex justify-content-center py-3' key={"ew_" + indexArr}
                style={{
                  background: `linear-gradient(to bottom, ${indexArr % 2 == 0 ? mainColors.light : mainColors.white} 0%, ${indexArr % 2 == 0 ? mainColors.light : mainColors.white} 50%, ${indexArr % 2 == 0 ? mainColors.white : mainColors.light } 50%, ${indexArr % 2 == 0 ? mainColors.white : mainColors.light} 100%)` 
                }}
              >
                <Slider {...slideSettings} className="justify-content-center col-8">
                  {ee?.galleries?.map((e,indx2) => {
										return(
										<div key={"ewlwjn" + indx2} className="customGallerySlide justify-content-center"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: isLgSc ? '594px' : 'auto',height: isLgSc ? '488px' : '350px', backgroundColor:mainColors.blue}}>
                      <div className="fillImg" style={{width:  isLgSc ? '594px' : '100%',border:'2px solid '+ mainColors.blue,borderColor:mainColors.blue,  backgroundColor:mainColors.white }}>
                      {e.status === "youtube" ? <ReactPlayer
                          url={e.logo}
                          controls
                          playbackRate = {2}
                          height={isLgSc ? '408px' : '100%'}
                          width='100%'
                          style={{borderRadius:10}}
                        /> : (e.logo ? 
                          <img
                          height={isLgSc ? '408px' : '350'}
                          style={{maxWidth:isLgSc ? '594px' : '100%', maxHeight: isLgSc ? '408px' : '350px'}}
                          alt="example"
                          src={getFileLink(e.logo)}
                          />
                        : <></>
                        )}
                      </div>
                      <div className='text-start' style={{padding: '24px 28px',height: '80px',width: isLgSc ? '594px' : '100%',fontFamily:'JostMedium', fontSize:'22px',backgroundColor: mainColors.blue,color:mainColors.white}}>
                        {ee.name}
                      </div>
										</div>	
										)
									})}
              </Slider>
            </Col>
            )
          })}
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => {
  return ({
    galleries: state.gallery.galleries,
  })
};

export default connect(mapStateToProps,null)(Gallery);