import { FETCH_ABOUTS } from '../actions/types';
const initialState = {
  abouts: {}
};

export const AboutReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ABOUTS:
      return {
        ...state,
        abouts: action.payload.abouts,
      };
    default:
      return {...state};
  }
}