import React, { useState } from 'react';
import { Badge, Card, Modal } from 'antd';
import {CloseCircleFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteGalleryImages } from '../../../actions/galleryActions';
import AddEditGalleryImage from './AddEditGalleryImage';
import { Link, useParams } from 'react-router-dom';
import { VisibilityOutlined } from '@mui/icons-material';
import { mainColors } from '../../../values/colors';
import ReactPlayer from 'react-player';
import { getFileLink } from '../../LibraryMethods';

const { Meta } = Card;

const GalleryImageCard = ({galleryImage}) => {
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();
console.log("galleryImage: ",galleryImage)
  const handleOk = () => {
    dispatch(deleteGalleryImages({code:galleryImage.code}, code))
    setIsConfirmDeleteVisible(false);
  };

  const handleCancel = () => {
    setIsConfirmDeleteVisible(false);
  };

  const showModal = () => {
    setIsConfirmDeleteVisible(true);
  };

return(
  <>
    <Badge
      className='shadow d-flex justify-content-center align-items-center'
      style={{ borderRadius:101, width: '100%'}}
      count={
      <CloseCircleFilled style={{fontSize:24 ,color: mainColors.red}} onClick={showModal} key={"delete" + galleryImage.id} />
    }>
      <Modal title="Delete Image" visible={isConfirmDeleteVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure?</p>
      </Modal>
      <div
        style={{borderRadius:10,height: '150px' }}
      >
        {galleryImage.status === "youtube" ?
          <ReactPlayer
            url={galleryImage.logo}
            controls
            playbackRate = {2}
            height='150px'
            width='auto'
            style={{borderRadius:10}}
          />
        :
        <span className='fillImg' style={{height:'238px',width:'inherit'}}>
          <img
            alt="galleryImage"
            src={getFileLink(galleryImage.logo)}
          />
        </span>
        }
      </div>
    </Badge>
  </>
)};

export default GalleryImageCard;
