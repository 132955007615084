import { FETCH_PROGRAMS } from '../actions/types';
const initialState = {
  programs: []
};

export const ProgramReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROGRAMS:
      return {
        ...state,
        programs: action.payload.programs,
      };
    default:
      return {...state};
  }
}