import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { addFooter, editFooter, fetchFooters } from '../../../actions/footerActions';
import { useParams } from 'react-router-dom';
import { Select, Upload, Button, message, InputNumber , Row, Col, Modal, Input } from 'antd';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';


const { Option, OptGroup } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const AddEditFooter = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  const [address, setaddress] = useState('')
  const [address2, setaddress2] = useState('')
  const [contactUs, setcontactUs] = useState('')
  const [email, setemail] = useState('')
  const [facebookLink, setfacebookLink] = useState('')
  const [instagramLink, setinstagramLink] = useState('')
  const [linkedinLink, setlinkedinLink] = useState('')
  const [twitterLink, settwitterLink] = useState('')
  const [phone, setphone] = useState('')
  const [file, setFile] = useState(null);
  const [fileRead, setFileRead] = useState(null)
  const [loading, setloading] = useState(false)
  const dispatch = useDispatch();

  useEffect(async () => {
    await dispatch(fetchFooters());
    setaddress(payload.address)
    setaddress2(payload.address2)
    setcontactUs(payload.contactUs)
    setemail(payload.email)
    setloading(true)
    setFileRead(getFileLink(payload.logo))
    setloading(false)
    setfacebookLink(payload.facebookLink)
    setinstagramLink(payload.instagramLink)
    setlinkedinLink(payload.linkedinLink)
    settwitterLink(payload.twitterLink)
    setphone(payload.phone)
  },[dispatch]);

  function validate() {
    let data = new FormData();
    data.append('address', address)
    data.append('address2', address2)
    data.append('contactUs', contactUs)
    data.append('email', email)
    data.append('facebookLink', facebookLink)
    data.append('instagramLink', instagramLink)
    data.append('linkedinLink', linkedinLink)
    data.append('twitterLink', twitterLink)
    data.append('phone', phone)

    if(file) {
    data.append('logo', file);
    }
    console.log(file)
    data.append('code', payload.code)
    dispatch(editFooter(data));

    handleOk();
  }


  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFile(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFileRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFile(null);
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <>
      <Modal title={action+' Footer'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Col span={24}>
            <Upload
              {...uploaderProps}
            >
              {fileRead ? <img src={fileRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Col>
        </Row>
       <Col lg={24} md={24} sm={24} xs={24} className='my-3'> <Input addonBefore="address" value={address} onChange={(evt) => {setaddress(evt.target.value);}} placeholder="address" /></Col>
       <Col lg={24} md={24} sm={24} xs={24} className='my-3'> <Input addonBefore="address2" value={address2} onChange={(evt) => {setaddress2(evt.target.value);}} placeholder="address2" /></Col>
       <Col lg={24} md={24} sm={24} xs={24} className='my-3'> <Input addonBefore="contactUs" value={contactUs} onChange={(evt) => {setcontactUs(evt.target.value);}} placeholder="contactUs" /></Col>
       <Col lg={24} md={24} sm={24} xs={24} className='my-3'> <Input addonBefore="email" value={email} onChange={(evt) => {setemail(evt.target.value);}} placeholder="email" /></Col>
       <Col lg={24} md={24} sm={24} xs={24} className='my-3'> <Input addonBefore="facebookLink" value={facebookLink} onChange={(evt) => {setfacebookLink(evt.target.value);}} placeholder="facebookLink" /></Col>
       <Col lg={24} md={24} sm={24} xs={24} className='my-3'> <Input addonBefore="instagramLink" value={instagramLink} onChange={(evt) => {setinstagramLink(evt.target.value);}} placeholder="instagramLink" /></Col>
       <Col lg={24} md={24} sm={24} xs={24} className='my-3'> <Input addonBefore="linkedinLink" value={linkedinLink} onChange={(evt) => {setlinkedinLink(evt.target.value);}} placeholder="linkedinLink" /></Col>
       <Col lg={24} md={24} sm={24} xs={24} className='my-3'> <Input addonBefore="twitterLink" value={twitterLink} onChange={(evt) => {settwitterLink(evt.target.value);}} placeholder="twitterLink" /></Col>
       <Col lg={24} md={24} sm={24} xs={24} className='my-3'> <Input addonBefore="phone" value={phone} onChange={(evt) => {setphone(evt.target.value);}} placeholder="phone" /></Col>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  footers: state.footer.footers,
});

export default connect(mapStateToProps, null)(AddEditFooter);