import React, { useState, useEffect } from 'react';
import { Button,Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddEditBlog from "../../../components/admin/Blogs/AddEditBlog";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import BlogCard from '../../../components/admin/Blogs/BlogCard';
import { Link, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { fetchBlogs } from '../../../actions/blogActions';
import { Container } from 'react-bootstrap';
import FilterDropDown from '../../../components/admin/FilterDropDown';
const Blogs = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [action, setAction] = useState(false);
    const [filtersReady, setfiltersReady] = useState([]);
    const [filterReady, setfilterReady] = useState([])
    const [filter, setfilter] = useState(null);

    let dispatch = useDispatch();

    let { id } = useParams()

    useEffect(() => {
      dispatch(fetchBlogs());
    },[dispatch]);

    let blogs = useSelector(state => state.blog.blogs);
    useEffect(() => {
      setfilterReady(blogs)
    }, [blogs])

    const showModal = (action) => {
      setAction(action);
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };
    useEffect(() => {
      setfiltersReady(blogs?.filter(erw => {
        if (filter) return (erw.code === filter)
        return true
      }))
    },[blogs,filter])  

    return (
      <>
        <PageHeader
          className="site-page-header shadow"
          title={'Blogs ' + '(' + blogs.length + ')'}
          extra={
            <div key="dlkejnflejnfe">
              {<FilterDropDown payload={filterReady} handleFilter={(evt) => setfilter(evt.key)} />}
              <Button key="buttonAdd" onClick={() => showModal('Add')} type="primary" icon={<PlusOutlined />} />
            </div>
          }
          onBack={() => window.history.back()}
        />
        <Container fluid>
          <div className="my-3 col-12 row" style={{padding: 15}}>
          {filtersReady.length ===0 && <div style={{marginTop:17}}>No Blogs are available</div>}
            {filtersReady.map((blog) => {
              return (
                <Col span={6} key={blog.code} className="pa-5">
                  <BlogCard blogs={blogs} blog={blog} />
                </Col>
              );
            })}
          </div>
          { isModalVisible &&
            <AddEditBlog blogs={blogs} action={action} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}/>
          }
        </Container>
      </>
    );
  }

  const mapStateToProps = state => ({
    blogs: state.blog.blogs,
  });

  export default connect(mapStateToProps, null)(Blogs);