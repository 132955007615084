import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { addTeam, editTeam, fetchTeams } from '../../../actions/teamActions';
import { useParams } from 'react-router-dom';
import { Upload,Select, Button, message, InputNumber , Row, Col, Modal, Input } from 'antd';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';

const { Option } = Select;



const AddEditTeam = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  let [name, setName] = useState('');
  let [refLink, setRefLink] = useState('');
  let [position, setPosition] = useState('');
  let [file, setFile] = useState(null);
  let dispatch = useDispatch();
  // neccesary
  const [fileRead, setFileRead] = useState(null)
  const [loading, setloading] = useState(false)

  let {code} = useParams();

  useEffect(async () => {
     await dispatch(fetchTeams());
     if(action === 'Edit') {
       setName(payload.name);
       setFileRead(getFileLink(payload.logo))
       setRefLink(payload.refLink);
       setPosition(payload.position);
     }
    },[dispatch]);


  function validate() {
    if(!name){
      alert('Please provide name!');
      return
    }
    let data = new FormData();
    data.append('name', name);
    data.append('position', position);
    data.append('refLink', refLink);
    if(file) {
    data.append('logo', file);
    }
    if(action === 'Add'){
    dispatch(addTeam(data, code));
    }else{
      console.log(file)
    data.append('code', payload.code)
    dispatch(editTeam(data, code));
    }
    message.success("Update!")
    handleOk();
  }

  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFile(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFileRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFile(null);
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal title={action+' Team'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Col span={24}>
            <Upload
              {...uploaderProps}
            >
              {fileRead ? <img src={fileRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Name" value={name} onChange={(evt) => {setName(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Position" value={position} onChange={(evt) => {setPosition(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Portfolio Link" value={refLink} onChange={(evt) => {setRefLink(evt.target.value);}} /></Col>
        </Row>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  teams: state.team.teams,
});

export default connect(mapStateToProps, null)(AddEditTeam);