import React from 'react';
import { Card, Divider } from 'antd';
import { getFileLink } from '../../LibraryMethods';
import { mainColors } from '../../../values/colors';

const PartialCard = ({partial}) => {

return(
  <>
    <Card
      className='rounded p-3 shadow'
      cover={
        <div className='col-12 text-start'>
          <Divider>NavBar</Divider>
          <h4>Brand Logo</h4>
          <div style={{backgroundColor: mainColors.light,width: '100%', maxHeight: 400}} className='fillImg' >
            <img
              src={`${getFileLink(partial?.brandLogo)}`}
              alt="Brand Logo"
            />
          </div>
          <Divider>Startups Page</Divider>
          <h4>Cover</h4>
          <div style={{backgroundColor: mainColors.light,width: '100%', maxHeight: 400}} className='fillImg' >
            <img
              src={`${getFileLink(partial?.startupLogo)}`}
              alt="Startup Logo"
            />
          </div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description on Cover: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'>{partial.startupDescription}</span></div>
        </div>
      }
    >
    </Card>
  </>
)};

export default PartialCard;
