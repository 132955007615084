import React, { useEffect } from 'react';

export class DividerSvg extends React.Component {
  render() {
    if (this.props.type === "red") {
      return (
        <>
        <svg id="Group_261" data-name="Group 261" xmlns="http://www.w3.org/2000/svg" width="764.177" height="68.436" viewBox="0 0 764.177 68.436">
          <path id="Path_85" data-name="Path 85" d="M764.177.138c-9.8,22.057-18.895,43.067-28.7,63.937-.945,1.885-6.967,4.118-10.746,4.118C690.488,68.542,36.373,68.4,0,68.4c1.535-3.979,2.6-7.329,4.015-10.61,7.322-16.961,14.88-33.853,22.2-50.815C27.87,3.069,29.169-.141,38.616,0,72.863.347,727.1.138,764.177.138Z" transform="translate(0 0.006)" fill="#c81436"/>
        </svg>
          <span>
            {this.props.children}
          </span>
        </>
      )
    }
    if (this.props.type === "white") {
      return (
        <>
        <svg id="Group_261" data-name="Group 261" xmlns="http://www.w3.org/2000/svg" width="764.177" height="68.436" viewBox="0 0 764.177 68.436">
          <path id="Path_85" data-name="Path 85" d="M764.177.138c-9.8,22.057-18.895,43.067-28.7,63.937-.945,1.885-6.967,4.118-10.746,4.118C690.488,68.542,36.373,68.4,0,68.4c1.535-3.979,2.6-7.329,4.015-10.61,7.322-16.961,14.88-33.853,22.2-50.815C27.87,3.069,29.169-.141,38.616,0,72.863.347,727.1.138,764.177.138Z" transform="translate(0 0.006)" fill="#c81436"/>
        </svg>
          <span>
            {this.props.children}
          </span>
        </>
      )
    }
    if (this.props.type === "smallSquareParticipatedStartups") {
      return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="186.275" height="129.238" viewBox="0 0 186.275 129.238">
            <path id="Path_90" data-name="Path 90" d="M363.975.234C351.313,41.93,339.566,81.646,326.9,121.1c-1.22,3.563-9,7.785-13.883,7.785-44.09.66-88.332.4-135.32.4,1.983-7.521,3.356-13.855,5.187-20.056,9.459-31.932,19.223-64,28.834-95.927C213.857,5.908,215.535-.162,227.739.1,271.829.63,315.919.234,363.975.234Z" transform="translate(-177.7 -0.094)" fill="#c81436"/>
            <text id="Featured_Startups" data-name="Featured
          Startups" transform="translate(37.893 55.332)" fill="#fff" fontSize="28" fontFamily="Jost-BoldItalic, Jost" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">Participate</tspan><tspan x="0" y="29">Startups</tspan></text>
          </svg>
          <span>
            {this.props.children}
          </span>
        </>
      )
    }
    if (this.props.type === "smallSquare") {
      return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="186.275" height="129.238" viewBox="0 0 186.275 129.238">
            <path id="Path_90" data-name="Path 90" d="M363.975.234C351.313,41.93,339.566,81.646,326.9,121.1c-1.22,3.563-9,7.785-13.883,7.785-44.09.66-88.332.4-135.32.4,1.983-7.521,3.356-13.855,5.187-20.056,9.459-31.932,19.223-64,28.834-95.927C213.857,5.908,215.535-.162,227.739.1,271.829.63,315.919.234,363.975.234Z" transform="translate(-177.7 -0.094)" fill="#c81436"/>
            <text id="Featured_Startups" data-name="Featured
          Startups" transform="translate(37.893 55.332)" fill="#fff" fontSize="28" fontFamily="Jost-BoldItalic, Jost" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">Featured</tspan><tspan x="0" y="29">Startups</tspan></text>
          </svg>
          <span>
            {this.props.children}
          </span>
        </>
      )
    }
    if (this.props.type === "expiredDuration") {
      return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="627.018" height="43.816" viewBox="0 0 627.018 43.816">
            <g id="Group_245" data-name="Group 245" transform="translate(-0.279 -21.567)">
              <path id="Path_85" data-name="Path 85" d="M627.3,21.7c-8.163,22.057-6.3,18.447-14.461,39.317-.787,1.885-5.8,4.118-8.95,4.118-28.523.349-573.313.209-603.607.209,1.279-3.979,2.164-7.329,3.344-10.61,6.1-16.961,2.954-9.233,9.052-26.195,1.377-3.909,2.459-7.12,10.327-6.98C51.525,21.914,596.414,21.7,627.3,21.7Z" transform="translate(0 0.006)" fill="#fff"/>
            </g>
            <text id="_The_application_now_is_closed_Follow_our_social_media_for_the_next_cohort_dates." data-name="*The application now is closed! Follow our social media for the next cohort dates." transform="translate(47.992 26.433)" fill="#c81436" fontSize="15" fontFamily="Jost" fontWeight="500"><tspan x="0" y="0">*The application now is closed! Follow our social media for the next cohort dates.</tspan></text>
          </svg>
        </>
      )
    }
    if (this.props.type === "successFeatures") {
      return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="186.275" height="129.238" viewBox="0 0 186.275 129.238">
            <path id="Path_90" data-name="Path 90" d="M363.975.234C351.313,41.93,339.566,81.646,326.9,121.1c-1.22,3.563-9,7.785-13.883,7.785-44.09.66-88.332.4-135.32.4,1.983-7.521,3.356-13.855,5.187-20.056,9.459-31.932,19.223-64,28.834-95.927C213.857,5.908,215.535-.162,227.739.1,271.829.63,315.919.234,363.975.234Z" transform="translate(-177.7 -0.094)" fill="#c81436"/>
            <text id="Success_Stories" data-name="Success
            Stories" transform="translate(37.893 55.332)" fill="#fff" fontSize="28" fontFamily="Jost-BoldItalic, Jost" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">Success</tspan><tspan x="0" y="29">Stories</tspan></text>
          </svg>
        </>
      )
    }

    if (this.props.type === "aboutTakween") {
      return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="255" height="58" viewBox="0 0 255 58">
            <text id="About_Takween" data-name="About Takween" transform="translate(0 43)" fill="#fff" font-size="40" font-family="Jost-BoldItalic, Jost" font-weight="700" font-style="italic"><tspan x="0" y="0">About Takween</tspan></text>
          </svg>
        </>
      )
    }

    return (
      <>
      <svg id="Group_261" data-name="Group 261" xmlns="http://www.w3.org/2000/svg" width="764.177" height="68.436" viewBox="0 0 764.177 68.436">
        <path id="Path_85" data-name="Path 85" d="M764.177.138c-9.8,22.057-18.895,43.067-28.7,63.937-.945,1.885-6.967,4.118-10.746,4.118C690.488,68.542,36.373,68.4,0,68.4c1.535-3.979,2.6-7.329,4.015-10.61,7.322-16.961,14.88-33.853,22.2-50.815C27.87,3.069,29.169-.141,38.616,0,72.863.347,727.1.138,764.177.138Z" transform="translate(0 0.006)" fill="#c81436"/>
      </svg>
        <span>
          {this.props.children}
        </span>
      </>
    )
  }
}
