import React, { useState, useEffect } from 'react';
import { Button, Col,Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddEditProgram from "../../../components/admin/Programs/AddEditProgram";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import ProgramCard from '../../../components/admin/Programs/ProgramCard';
import { Link, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { fetchPrograms } from '../../../actions/programActions';
import { Container } from 'react-bootstrap';

const Programs = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [action, setAction] = useState(false);
    let dispatch = useDispatch();

    let { id } = useParams()

    useEffect(() => {
      dispatch(fetchPrograms());
    },[dispatch]);

    let programs = useSelector(state => state.program.programs);

    const showModal = (action) => {
      setAction(action);
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return (
      <>
        <PageHeader
          className="site-page-header shadow"
          title={'Programs ' + '(' + programs.length + ')'}
          extra={[
            <Button key="buttonAdd" onClick={() => showModal('Add')} type="primary" icon={<PlusOutlined />} />
          ]}
          onBack={() => window.history.back()}
        />
        <Container fluid>
          <Row gutter={[20,20]} className="my-3 col-12 row" style={{padding: 15}}>
            {programs.length ===0 && <div style={{marginTop:17}}>No programs are available</div>}
            {programs.map((program,ind) => {
              return (
                <Col span={6} key={ind} className="col-lg-3 col-md-3 col-sm-3 pa-5 my-2">
                  <ProgramCard programs={programs} program={program} />
                </Col>
              );
            })}
          </Row>
          { isModalVisible &&
            <AddEditProgram programs={programs} action={action} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}/>
          }
        </Container>
      </>
    );
  }

  const mapStateToProps = state => ({
    programs: state.program.programs,
  });

  export default connect(mapStateToProps, null)(Programs);