import React, { useState,useRef,useEffect } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchStartups } from '../actions/startupActions';
import StartupSection from '../components/home/StartupSection'
import {Container} from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import { DividerSvg } from '../components/DividerSvg'
import Box from '@mui/material/Box';
import { mainColors } from '../values/colors';
import {btn,countingNumStyle} from '../values/styles'
import humanizeDuration from 'humanize-duration'
import SocialMedias from '../components/SocialMedias'
import { CaretDownFilled, RightOutlined } from '@ant-design/icons'
import { fetchPrograms } from '../actions/programActions';
import { fetchPartials } from '../actions/partialActions';
import MenuItem from 'antd/lib/menu/MenuItem';
import { Menu, Dropdown, Card } from 'antd';
import { Row, Col, Button } from 'antd';
import SuccessStories from '../components/successStory';
import { fetchBlogs } from '../actions/blogActions';
import { getFileLink } from '../components/LibraryMethods';
const { Meta } = Card;
const isLgSc = window.innerWidth > 600
const {SubMenu} = Menu
const Startup = (...rest) => {
  const [programCode,setprcode] = useState('');
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartials());
    dispatch(fetchStartups());
    dispatch(fetchPrograms());
    dispatch(fetchBlogs());
  },[dispatch])

  let startups = useSelector(state => state.startup.startups);
  let programs = useSelector(state => state.program.programs);
  let blogs = useSelector(state => state.blog.blogs);
  let partials = useSelector(state => state.partial.partials);
  let logoAddress = getFileLink(partials?.startupLogo)
  const redyPrm = (programCode.length !== 0) ? startups.filter(we => {return we.programCode === programCode}) : startups
  const allProgramsCarsl = redyPrm.map(e => {
    return {code: e.code,name: e.name, logo: getFileLink(e.logo),refLink: e.refLink, description: e.description}
  })
  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 'auto',
          height: '400px',
          backgroundImage: `URL(${logoAddress})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        className='d-flex  justify-items-center align-items-center '
      >
        <div
        style={{
          height: '100%',
          width:'100%',
        }}
        className='py-auto container justifyitems-center align-items-center d-flex '
        >
          <div className='col' style={{position:'relative'}}>
            <div className="d-flex justify-content-start align-items-center" style={{position:'relative'}}>
              <h1 className='text-start' style={{fontFamily: 'JostBoldItalic', fontSize: '50px',color: mainColors.white}}>{partials?.startupDescription?.toUpperCase()}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='col' style={{position:'relative'}}>
        <div className={(isLgSc ? 'justify-content-center' : 'justify-content-start ') + " d-flex align-items-center"} style={{position:'relative'}}>
          <span className={(isLgSc ? 'justify-content-center' : 'justify-content-start ') + " d-flex align-items-center"} style={{background: isLgSc ? 'transparent' : 'linear-gradient(to right, '+mainColors.red+', transparent)',position:'absolute'}}>
            <div className={isLgSc ? 'text-start' : 'px-3'} style={{position:'absolute', fontSize: '40px',fontFamily: 'JostBoldItalic', color: mainColors.white}}>Our Portfolio</div>
            <DividerSvg type="red" />
          </span>
        </div>
      </div>
      <Container className='d-flex justify-start py-5'>
        <Dropdown
          type='input'
          size="large"
          overlay={
            <>
            <Menu className='p-2' style={{border: btn.btnBlueLight.border,fontFamily: btn.btnBlueLight.fontFamily,backgroundColor: mainColors.white}}>
              <Menu.Item className='cstm-btn-red-outline hoveredBorderRed' key="all0">
                <Link className='cstm-btn-color-red-to-white' to={"#"} onClick={lkm => {setprcode("")}} style={{color: programCode.length === 0 ? mainColors.red : mainColors.red,textDecoration: 'none'}}>
                  All
                </Link>
              </Menu.Item>
              {programs.map((e,indx) => {
                return <Menu.Item className='cstm-btn-red-outline hoveredBorderRed' key={indx}>
                  <Link className='cstm-btn-color-red-to-white' to={"#"} onClick={lkm => {setprcode(e.code)}} style={{color: programCode === e.code ? mainColors.red : mainColors.red,textDecoration: 'none'}}>
                    {e.name}
                  </Link>
                </Menu.Item>
              })}
            </Menu>
            </>
          }
          >
            <Link
              to={"#"}
              style={{
                color: mainColors.red,
                ...btn.btnBlueLight
              }}
              className="cstm-btn-blue-outline d-flex py-3 px-5 justify-content-between align-items-center ant-dropdown-link"
            >
              Sort by Program <CaretDownFilled style={{color: mainColors.red}}/>
            </Link>
        </Dropdown>
      </Container>

      <Container className='py-5'>
        <Row
          className='d-flex justify-content-center'
          gutter={[40, 40]}
        >
          {allProgramsCarsl?.map((ee,indx) => {
            return (
              <Col
                key={indx}
                span={isLgSc ? 4 : 12}
              >
                <a
                  target={'_blank'}
                  className="d-flex justify-content-center align-items-center"
                  style={{widht:'100%', height: '100%'}}
                  href={ee?.refLink}
                  rel="noreferrer"
                >
                  <img className="col-12 " style={{maxWidth:'162px',maxHeight:'162px'}} alt={ee.logo} src={ee.logo} />
                </a>
              </Col>
            )
          })}
          {allProgramsCarsl.length === 0 
            && <Col span={24}>
              <div style={{color: mainColors.blue}}>No Startup in this Program!</div>
            </Col>
          }
        </Row>
      </Container>
      <Container fluid className='px-0'>
        <SuccessStories blogss={blogs} />
      </Container>
    </>
  );
}
const mapStateToProps = state => {
  return ({
    partials: state.partial.partials,
    startups: state.startup.startups,
    programs: state.program.programs,
    blogs: state.blog.blogs,
  })
};
export default connect(mapStateToProps,null)(Startup);