import React, { useState, useEffect } from 'react';
import { Button, Col,Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddEditTeam from "../../../components/admin/Teams/AddEditTeam";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import { fetchTeams } from '../../../actions/teamActions';
import TeamCard from '../../../components/admin/Teams/TeamCard';
import { Link, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { Container } from 'react-bootstrap';
const Teams = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [action, setAction] = useState(false);
    let dispatch = useDispatch();

    let { id } = useParams()

    useEffect(() => {
      dispatch(fetchTeams(id));
    },[dispatch]);

    let teams = useSelector(state => state.team.teams);

    const showModal = (action) => {
      setAction(action);
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return (
      <>
        <PageHeader
          className="site-page-header shadow"
          title={'Teams ' + '(' + teams.length + ')'}
          extra={[
            <Button key="buttonAdd" onClick={() => showModal('Add')} type="primary" icon={<PlusOutlined />} />
          ]}
          onBack={() => window.history.back()}
        />
        <Container fluid={24}>
        <Row gutter={[20,20]} className="my-3 col-12 row">
            {teams.length ===0 && <div style={{marginTop:17}}>No teams are available</div>}
            {teams.map((team,ind) => {
              return (
                  <Col span={6} key={ind} className="pa-5">
                    <TeamCard team={team} />
                  </Col>
              );
            })}
          </Row>
          { isModalVisible &&
            <AddEditTeam action={action} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}/>
          }
        </Container>
      </>
    );
  }

  const mapStateToProps = state => ({
    teams: state.team.teams,
  });

  export default connect(mapStateToProps, null)(Teams);