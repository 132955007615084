import { Link} from 'react-router-dom'
import { Card } from 'antd';
import { Row, Col, Button } from 'antd';
import Box from '@mui/material/Box';
import {mainColors} from '../../values/colors'
import {btn} from '../../values/styles'
import {Container} from 'react-bootstrap'
import Slider from "react-slick";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getFileLink } from '../LibraryMethods';
import { useEffect, useState } from 'react';

const { Meta } = Card;

const ProgramSection = ({programs}) => {
  const [isLgSc, setisLgSc] = useState(window.innerWidth > 600)
  const [slideCnt, setSlideCnt] = useState(isLgSc)
  const [isArrowAllowed, setisArrowAllowed] = useState(true)
  const [readyPrograms, setreadyItems] = useState([])
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
    setSlideCnt(() => {
      if (isLgSc) {
        if (programs?.length && programs?.length > 2) {
          return 3
        } else {
          return programs?.length
        }
      }
      return 1
    }
  )
    setisArrowAllowed(isLgSc)
  }, [isLgSc, programs?.length])
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev blueArrows  slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    ><LeftOutlined /></button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next blueArrows slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    ><RightOutlined /></button>
  );
  const slideSettings = {
    dots: true,
    arrows: isArrowAllowed,
    infinite: true,
    speed: 500,
    autoplay: true,
    swipeToSlide: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    slidesToScroll: 1,
    slidesToShow: slideCnt,
  };
  useEffect(() => {
    setreadyItems(programs?.map(e => {
      return {code: e.code,name: e.name, logo: getFileLink(e.logo), description: e.description}
    }))
  },[programs])
  return (
    <>
      <Container className="my-5">
        <h1 style={{color: mainColors.blue,fontSize: '40px', fontFamily:'JostBoldItalic'}}>OUR PROGRAMS</h1>
      </Container>
      
      {readyPrograms.length && <Slider className='justify-content-center' {...slideSettings}>
        {readyPrograms?.map((ee,indx) => {
          return (
          <div
            className='d-flex justify-content-center flex-wrap p-4'
            key={"programSection__" + indx}
          >
            <Card
              width={250}
              style={{width: '250px',border: '1px solid #015076', borderColor:mainColors.blue}}
              hoverable
              bordered={false}
              cover={
                <img alt="avatar" src={ee?.logo} style={{width: '250px',maxWidth: 250,height: 162,shrink:100}} />
              }

              >
              <Meta title={<h1 className="py-3 justify-content-center"
              style={{color:mainColors.blue, fontFamily: 'JostMedium',fontSize:'22px'}} >
                {ee.name?.toUpperCase()}
              </h1>}
              description={
                <>
                  <Link
                    to={ '/programs/' + ee.code}
                    className="cstm-btn-blue-outline d-flex justify-content-between align-items-center"
                    style={{...(btn.btnBlueLight),fontFamily: 'RobotoMedium',fontSize:'12px', padding: '13px 14.5px'}}
                  >
                    Learn More
                    <RightOutlined />
                  </Link>
                </>
              }>
              </Meta>
            </Card>
          </div>
          )
        })
        }
      </Slider>}
    </>
  );
};

export default ProgramSection;