import React, {useState,useRef,useEffect } from 'react';
import Slider from "react-slick";
import { useDispatch, connect, useSelector } from "react-redux";
import {Container} from 'react-bootstrap'
import { mainColors } from '../../values/colors';
import {btn,countingNumStyle, ImgBg, imgBg} from '../../values/styles'
import SocialMedias from '../../components/SocialMedias'
import { fetchTakeActions } from '../../actions/takeActionActions.js';
import { Menu, Dropdown, Card,Carousel } from 'antd';
import { Row, Col, Button } from 'antd';
import { Link, useParams } from 'react-router-dom';
import * as moment from 'moment'
import {Svg} from '../../components/SvgViewer'
import { DividerSvg } from '../../components/DividerSvg'
import TkwnButton from '../../components/linkButtonLong'
import { getFileLink } from '../LibraryMethods';
const { Meta } = Card;


const {SubMenu} = Menu
const DemoBox = props => <p className={`height-${props.value}`}>{props.children}</p>;
const TakeActionHomePart = (...rest) => {
  const [isLgSc, setisLgSc] = useState(true)
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
  }, [isLgSc])
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1
	};

	const MediaViewr = ({url}) => {
    console.log(url)
    if (url.toString().toLowerCase().includes('svg')){
      return <span
        className="col-12"
        style={{
          backgroundColor: mainColors.blue,
        }}
          alt="Image"
        >
          <Svg url={url}/>
        </span>
    } else {
      return <span
        className="fillImg col-12"
        style={{
          backgroundColor: mainColors.blue,
        }}
        alt="Image"
        >
        <img
        className="col-12"
        style={{
          backgroundColor: mainColors.blue,
          height:'auto'
        }}
        alt="example"
        src={url}
        />
        </span>
    }
  }
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTakeActions());
  },[dispatch])
  let takeActions = useSelector(state => state.takeAction.takeActions);
  console.log("takeActions", takeActions)
  function parseDat(datt) {
    return datt ? moment(datt).format('DD/MMM/YYYY') : ''
  }
  if (takeActions){
    takeActions.invLogo = getFileLink(takeActions.invLogo)
    takeActions.vlnLogo = getFileLink(takeActions.vlnLogo)
    takeActions.tknLogo = getFileLink(takeActions.tknLogo)
  }

  return (
    <>
      <Container fluid="true" style={{height:'162px'}} className='p-auto '>
          <Container className='d-flex justify-flex-start align-items-end' style={{height:'122px'}}>
          <div style={{color: mainColors.blue, fontFamily:'JostBoldItalic', fontSize:'41px'}}>
            Take Aciton
          </div>
        </Container>
        <div className="position-relative">
          <div className="position-absolute" style={{transform:'translate(-25%, 0px)'}}>
            <DividerSvg type="red" />
          </div>
        </div>
      </Container>
			{/* <div style={{}} className='position-absolute col-12'>
      </div> */}
      <Container fluid={true} className='d-flex px-0 flex-wrap justify-content-center '>
        <div
          style={{
            width: '100%',
            padding: 'auto',
            height: '650px',
            backgroundImage: `URL(${takeActions.invLogo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          className='d-flex flex-wrap col-6 justify-content-start align-content-center '
        >
          <Container className='p-3 text-start' style={{fontFamily:'JostBold', fontSize:'22px',color:mainColors.white}}>
            <div className='col-12'><div className={(isLgSc ? "col-6 " : "col-12 ") + ' pb-2 pt-3 p-wrap'} style={{color: mainColors.white,fontFamily: 'JostBoldItalic', fontSize: '28px'}}>{takeActions.tknName}</div></div>
            <div className='col-12'><div className={(isLgSc ? "col-6 " : "col-12 ") + ' pb-2 pt-3 p-wrap'} style={{color: mainColors.white,fontFamily: 'RobotoLight', fontSize: '17px'}}>{takeActions.tknDiscription}</div></div>
            <div>
              <Link to={{pathname: takeActions.invLink}} style={{...btn.btnWhiteLight,fontFamily: 'RobotoMedium', fontSize:'16px'}} className="cstm-btn-white-outline px-5 py-3">Join Us {'>'}</Link>
            </div>
          </Container>
        </div>
      </Container>
    </>
  );
}

const mapStateToProps = state => {
  return ({
    takeActions: state.takeAction.takeActions,
  })
};

export default connect(mapStateToProps,null)(TakeActionHomePart);
