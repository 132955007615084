import React, { useState,useRef, useEffect } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchAbouts } from '../actions/aboutActions';
import { fetchPartials } from '../actions/partialActions';
import {Container} from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import { DividerSvg } from '../components/DividerSvg'
import Box from '@mui/material/Box';
import { mainColors } from '../values/colors';
import {btn,countingNumStyle} from '../values/styles';
import { LeftOutlined,RightOutlined} from '@ant-design/icons';
import { Carousel, Radio, Card } from 'antd';
import humanizeDuration from 'humanize-duration'
import {Svg} from '../components/SvgViewer'
import Slider from 'react-slick';
import { getFileLink } from '../components/LibraryMethods';
import * as Scroll from 'react-scroll';

const isLgSc = window.innerWidth > 600
console.log("window.innerWidth: ",window.innerWidth ,isLgSc , " :window.innerWidth")
const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-prev slick-arrow" +
      (currentSlide === 0 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  ><LeftOutlined /></button>
);
let Events    = Scroll.Events;
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <button
    {...props}
    className={
      "slick-next slick-arrow" +
      (currentSlide === slideCount - 1 ? " slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  ><RightOutlined /></button>
);
const slideSettings = {
  dots: true,
  arrows: (Number(window.innerWidth) > 600),
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  dotsClass: ' slick-dots customAboutSection ',
  swipeToSlide: true,
  nextArrow: <SlickArrowRight />,
  prevArrow: <SlickArrowLeft />,
  slidesToScroll: 1,
}

const styleWithBorderResponsive = window.innerWidth > 600 ? {borderRight: `2px solid ${mainColors.white}`,backgroundColor: mainColors.blue} : {padding: 'auto',width: '100%'}
const About = (...rest) => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAbouts());
    dispatch(fetchPartials());
  },[dispatch]);

  let abouts = useSelector(state => state.about.abouts);
  let partials = useSelector(state => state.partial.partials);
//   let currentCode = useParams()?.code
  const logoAddress = getFileLink(abouts?.logo)
  const teamLogo = getFileLink(partials?.teamLogo)
  let allAboutCarsl = []
  if (abouts['aboutImages']?.length) {
    allAboutCarsl = abouts['aboutImages']?.map(e => {
      return {code: e.code,name: e.name,position: e.position, logo: getFileLink(e.logo), description: e.description}
    })
  }
  return (
    <>
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
      }}
    >
      <div
      style={{
        width: '100%',
        padding: 'auto',
        height: '400px',
        backgroundImage: `URL(${logoAddress})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      className='d-flex justify-items-center align-items-center '
      >
        <div
        style={{
          height: '100%',
          width:'100%',
          color: mainColors.blue,
          fontFamily: 'JostBoldItalic'
        }}
        className='py-4 container justifyitems-start align-items-end d-flex '
        >
        </div>
      </div>
      <div className='col' style={{position:'relative'}}>
        <div className="d-flex justify-content-start align-items-center" style={{position:'relative'}}>
          <span className={(isLgSc ? 'justify-content-center ' : 'px-1 justify-content-start ') + " d-flex align-items-center"} style={{background: 'linear-gradient(to right, '+mainColors.red+', transparent)',position:'absolute'}}>
            <div className={(isLgSc ? "" : "px-3 ") + ' text-start'} style={{position:'absolute', fontSize: '40px',fontFamily: 'JostBoldItalic', color: mainColors.white}}>About Takween</div>
            <DividerSvg type="red" />
          </span>
        </div>
      </div>
      <Container className='d-flex justify-items-center align-items-center py-5' fluid style={{color:mainColors.white,backgroundColor: mainColors.blue}}>
        <Container>
          {(allAboutCarsl.length && abouts) && <Slider {...slideSettings} className="justify-content-center align-content-center">
                {allAboutCarsl?.map((e,indx) => {
                  return (
                    <div key={indx}
                      style={isLgSc ? {height: '415px!important'} : {}}
                    >
                      <div
                        style={{marginBottom: 5}}
                        className={(isLgSc ? '' : 'flex-wrap ') + 'd-flex my-4 justify-content-center'}
                      >
                        <div
                          className={`${(isLgSc) ? 'col-6 ' : 'col-12 pb-3 '} container d-flex justify-content-end align-item-center`}
                          style={styleWithBorderResponsive}
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <span className="fillImg">
                              <img src={e.logo} />
                            </span>
                          </div>
                        </div>
                        <div className={`${(isLgSc) ? 'col-6 ' : 'col-12 '} items-direction-top container d-flex justify-content-start align-items-center px-3`}>
                            <div className={`${isLgSc ? 'border-left px-2 ' : ''} text-start p-wrap`} style={{fontFamily: 'Roboto',fontSize:'18px' }}>{abouts.description}</div>
                        </div>
                      </div>
                    </div>
                  )
                })
                }
            </Slider>}
        </Container>
      </Container>
      <div style={{backgroundColor: mainColors.white}} className='d-flex justify-content-end col-12 mb-5'>
        <div style={{transform:'translate(20%, 120%)'}}>
          <DividerSvg type="red" />
        </div>
      </div>
      <Container fluid className='px-0 mt-5'>
        <div>
          <Box
            className={`${(isLgSc) ? ' ' : 'flex-wrap '} d-flex mb-0 px-0 justify-content-center`}
            sx={{
              bgcolor: mainColors.blue,
              color: mainColors.white,
              borderRadius: 1,
            }}
          >
            <Box
              className={`${(isLgSc) ? 'col-6 ' : 'col-12 '} 'col-6 d-flex justify-content-stretch align-item-stretch`}
              style={{
                height:'528px',
                backgroundColor: mainColors.blue,
                backgroundImage: `URL(${teamLogo})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                padding: 0
              }}
            >
            </Box>
            <Box className={`${(isLgSc) ? 'col-6 ' : 'col-12 '} p-5 text-start d-flex justify-content-start align-items-center`}
              >
              <div>
                <h1 className='px-3 py-2' style={{color:mainColors.white,fontFamily: 'JostBoldItalic',border:0 }}>{partials.teamName}</h1>
                <h3 className='px-3 py-2' style={{color:mainColors.white,border:0,fontFamily: 'Roboto',fontSize:'20px' }}>{partials.teamDescription}</h3>
                <div className='px-3 py-2'>
                  <Link className='cstm-btn-white-outline p-3' to="/teams" style={{...(btn.btnWhiteLight), fontFamily: 'RobotoMedium' }}>Meat the Team <RightOutlined className='ps-3' /></Link>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      </Container>
    </div>
    </>
  );
}
const mapStateToProps = state => {
  return ({
    abouts: state.about.abouts,
    partials: state.partial.partials,
  })
};
export default connect(mapStateToProps,null)(About);