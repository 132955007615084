import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom'
import { Carousel, Radio } from 'antd';
import { Card } from 'antd';
import { Row, Col, Divider } from 'antd';
import Box from '@mui/material/Box';
import { mainColors } from '../../values/colors';
import { LeftOutlined,RightOutlined} from '@ant-design/icons'
import {btn} from '../../values/styles'
import { Container } from 'react-bootstrap'
import { DividerSvg } from '../DividerSvg'
import Slider from 'react-slick';
import { getFileLink, groupArr } from '../LibraryMethods';

const FetchPartners = ({partners, ourPartnerName, ourPartnerDescription}) => {
  const [isLgSc, setisLgSc] = useState(window.innerWidth > 600)
  const [slideCnt, setSlideCnt] = useState(isLgSc)
  const [isArrowAllowed, setisArrowAllowed] = useState(true)
  const [allPartnersCarsl, setreadyItems] = useState([])
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
    setSlideCnt(() => {
      if (isLgSc) {
        if (partners?.length && partners?.length > 2) {
          return 3
        } else {
          return partners?.length
        }
      }
      return 1
    }
  )
    setisArrowAllowed(isLgSc)
  }, [isLgSc, partners?.length])
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "whiteArrows slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled " : "")
      }
      style={{color: mainColors.white + "!important", }}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    ><LeftOutlined /></button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "whiteArrows slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled " : "")
      }
      style={{color: mainColors.white + "!important", }}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    ><RightOutlined /></button>
  );
  const slideSettings = {
    dots: true,
    arrows: isArrowAllowed,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    dotsClass: ' slick-dots customPartnersSection ',
    swipeToSlide: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft  />,
    slidesToScroll: 1,
  }
  useEffect(() => {
    setreadyItems(groupArr(partners?.map(e => {
      return {code: e.code, logo: getFileLink(e.logo)}
    }),9))
  },[partners])
  return (
    <>
      <Container className={(isLgSc) ? 'py-5' : ''}>
        {allPartnersCarsl.length && <Slider {...slideSettings} className=" d-flex justify-content-center">
            {allPartnersCarsl?.map((e,indx) => {
              return (
                <div className='row' style={(isLgSc) ? {height: 270} : {} } key={indx}>
                  <div
                    className='col-sm-12 col-xs-12 d-flex row flex-wrap my-4 justify-content-center'
                    style={(isLgSc) ? {height: 270,marginBottom: 10} :{marginBottom: 10}}
                  >
                    <div className='col-lg-6 py-2 col-md-6 col-sm-12 col-xs-12 container justify-content-center d-flex'
                      style={{
                        display: 'flex!important',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}                    
                    >
                      <div className="col-12 text-start" style={{color: mainColors.white,fontFamily: 'JostBoldItalic',fontSize:'40px' }}>{ourPartnerName}</div>
                      <div className='px-2 col-12 text-start' style={{color: mainColors.white,fontFamily: 'Jost',fontSize:'18px' }}>{ourPartnerDescription}</div>
                    </div>
                    <div className='col-lg-6 py-2 col-md-6 col-sm-12 col-xs-12 container d-flex justify-content-center align-items-center'>
                      <Row gutter={[28,28]} style={(isLgSc) ? {height: 270} : {}} className="col-12">
                        {e.map(el => {
                          return (
                        <Col
                        span={8}
                        key={el.code}
                        >
                          <div
                          style={{
                            height: '70px',
                          }}
                        className="fillImg">
                        <img
                          alt="example"
                          src={el.logo}
                        />
                        </div>
                        </Col>
                          )
                        })}
                      </Row>
                    </div>
                  </div>
                </div>
              )
            })
            }
        </Slider>}
      </Container>
    </>
  );
};

export default FetchPartners;