import { FETCH_GALLERIES, FETCH_LAST_GALLERY } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = 'galleries';

export const fetchGalleries = () => async dispatch => {
  try {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/`);
      dispatch({
        type: FETCH_GALLERIES,
        payload: {
          galleries: response.data.data
        }
      })
  } catch(err) {
    console.log(err);
  }
};

export const addGallery = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/create`,payload)
    dispatch(fetchGalleries());
  }catch(err) {
    console.log(err)
  }
};

export const fetchLastGallery = () => async dispatch => {
  try {
    let firstResponse = []
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/lastGallery`);
    console.log("lastgalleries", response)
    firstResponse = response.data.data?.galleries
    dispatch({
      type: FETCH_LAST_GALLERY,
      payload: {
        lastGallery: firstResponse
      }
    })
  } catch(err) {
    console.log(err);
  }
}

export const editGallery = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/update`,payload)
    dispatch(fetchGalleries());
  }catch(err) {
    console.log(err)
  }
};

export const deleteGalleries = (payload, id) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/delete`, payload);
      dispatch(fetchGalleries(id));
    }catch(err) {
  }
};

export const addGalleryVideos = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/createVideos`,payload)
    dispatch(fetchGalleries());
  }catch(err) {
    console.log(err)
  }
};

export const addGalleryImages = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/createImages`,payload)
    dispatch(fetchGalleries());
  }catch(err) {
    console.log(err)
  }
};
export const deleteGalleryImages = (payload, id) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/deleteImages`, payload);
    dispatch(fetchGalleries(id));
  }catch(err) {
  }
};
