import React, { useState } from 'react';
import { Badge, Card, Modal } from 'antd';
import {CloseCircleFilled, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deletePartners } from '../../../actions/partnerActions';
import { Link, useParams } from 'react-router-dom';
import { VisibilityOutlined } from '@mui/icons-material';
import { mainColors } from '../../../values/colors';
import { getFileLink } from '../../LibraryMethods';

const { Meta } = Card;

const PartnerCard = ({partner}) => {
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();

  const handleOk = () => {
    dispatch(deletePartners({code:partner.code}, code))
    setIsConfirmDeleteVisible(false);
  };

  const handleCancel = () => {
    setIsConfirmDeleteVisible(false);
  };

  const showModal = () => {
    setIsConfirmDeleteVisible(true);
  };

return(
  <>
    <Badge
      className='shadow d-flex justify-content-center align-items-center'
      style={{ borderRadius:5, width: '100%'}}
      count={
      <CloseCircleFilled style={{fontSize:24 ,color: mainColors.red}} onClick={showModal} key={"delete" + partner.id} />
    }>
      <Modal title="Delete " visible={isConfirmDeleteVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure?</p>
      </Modal>
      <div
        style={{height: '150px' }}
      >
        <span className='fillImg' style={{height:'238px',width:'inherit'}}>
          <img
            alt="partner"
            src={getFileLink(partner.logo)}
          />
        </span>
      </div>
    </Badge>
  </>
)};

export default PartnerCard;
