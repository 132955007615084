import { FETCH_PROGRAMS } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = 'programs';

export const fetchPrograms = () => async dispatch => {
  try {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/`);
      dispatch({
        type: FETCH_PROGRAMS,
        payload: {
          programs: response.data.data.sort((a,b) => {return b["id"] - a["id"] })
        }
      })
  } catch(err) {
    console.log(err);
  }
};

export const addProgram = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/create`,payload)
    dispatch(fetchPrograms());
  }catch(err) {
    console.log(err)
  }
};

export const editProgram = ( payload, code ) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/update`,payload)
      dispatch(fetchPrograms());
  }catch(err) {
      console.log(err)
    }
};

export const deletePrograms = (payload, id) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/delete`, payload);
      dispatch(fetchPrograms(id));
  }catch(err) {
    }
};