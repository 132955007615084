import { FETCH_FOOTERS } from '../actions/types';
const initialState = {
  footers: {}
};

export const FooterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FOOTERS:
      return {
        ...state,
        footers: action.payload.footers,
      };
    default:
      return {...state};
  }
}