import { FETCH_TEAMS } from '../actions/types';
const initialState = {
  teams: []
};

export const TeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEAMS:
      return {
        ...state,
        teams: action.payload.teams,
      };
    default:
      return {...state};
  }
}