import React, { useEffect, useRef } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchPrograms } from '../actions/programActions';
import { fetchFooters } from '../actions/footerActions';
import { Link, useParams } from 'react-router-dom';
import { Navbar, Container } from 'react-bootstrap'
import { MoreOutlined,CaretDownFilled} from '@ant-design/icons'
import { Menu,Row,Layout, Col} from 'antd';
import {mainColors} from '../values/colors'
import {btn} from '../values/styles'
import SocialMediaComp from '../components/SocialMedias'
import { fetchPartials } from '../actions/partialActions';
import { getFileLink } from '../components/LibraryMethods';
import { fetchAbouts } from '../actions/aboutActions';
import { animateScroll as scroll } from "react-scroll";
// import { ScrollTo } from "react-scroll-to";
const isLgSc = window.innerWidth > 600
const { Footer } = Layout;
const { SubMenu } = Menu
const HomeLayout = ({component: Component,pageName, ...rest}) => {
  let dispatch = useDispatch();
  const currentCode = useParams()?.code
  let programs = useSelector(state => state.program.programs);
  let footers = useSelector(state => state.footer.footers);
  let partials = useSelector(state => state.partial.partials)
  const scrollEvent = useRef()
  useEffect(() => {
    scrollEvent.current.scrollIntoView({behavior: 'smooth'});
  },[window.location.href])
  useEffect(() => {
    dispatch(fetchPrograms());
    dispatch(fetchFooters());
    dispatch(fetchPartials());
    dispatch(fetchAbouts());
  },[dispatch]);
  const logoAddressmain = partials?.brandLogo ? getFileLink(partials?.brandLogo) : '../../../takweenLogo.svg'
  const footerLogo = footers?.logo ? getFileLink(footers?.logo) : '../../../takweenWhiteLogo.svg'
  return(
    <span key="dknedoenfoeinfoein" ref={scrollEvent} >
      <Navbar bg="white" 
        style={{
          boxShadow: '0px 0px 15px ' + mainColors.grey,
          height: '150px',
        }}
      >
        <Container>
          <Navbar.Brand id="RouterNavLink" style={{height: '50px', marginRight: 0,minWidth:'168px'}}>
            <div id="RouterNavLink" className="logo" style={{height: '50px', maxHeight: '100%',maxWidth:'168px',color: mainColors.red, textDecoration: 'none'}}>
              <div style={{height: '50px'}} className='fillImg logo'>
                <img alt="" style={{height: '50px'}} src={ logoAddressmain} />
              </div>
            </div>
          </Navbar.Brand>
          <Menu
            expandIcon={<span><CaretDownFilled classNames="col-xs-auto d-block" style={{ color: mainColors.red} } /></span>}
            overflowedIndicator={<MoreOutlined style={{ fontSize: 18,color: mainColors.red}} />}
            theme="white"
            className="col d-flex flex-wrap justify-content-end hoveredBorderRed"
            mode="horizontal"
            defaultSelectedKeys={[pageName]}
            style={{width: 'calc(100% -168px)', border:0, fontSize: 18}}
          >
            <Menu.Item key="home">
              <Link to="/" style={{color: pageName.split('/')[0] === 'home' ? mainColors.red : mainColors.blue,textDecoration: 'none'}}>
                Home
              </Link>
            </Menu.Item>
            <SubMenu
              key="abouts"
              className='hoveredBorderRed'
              style={{color: pageName.split('/')[0] === 'about' ? mainColors.red : mainColors.blue, textDecoration: 'none'}}
              title={
                <>
                  <span
                    style={{color: pageName.split('/')[0] === 'about' ? mainColors.red : mainColors.blue}}
                    className="d-flex justify-content-between align-items-center"
                  >
                    About
                    <CaretDownFilled className="d-none d-md-block d-lg-block d-xl-block" style={{color: mainColors.red}} />
                  </span>
                </>
              }
              
            >
              <Menu.Item  className='hoveredBorderRed' key="aboutTakween">
                <Link className='cstm-btn-red-outline-border-bottom' to="/about" style={{color: mainColors.red,textDecoration: 'none'}}>
                  About Takween
                </Link>
              </Menu.Item>
              <Menu.Item  className='hoveredBorderRed' key="teams">
                <Link className='cstm-btn-red-outline-border-bottom' to="/teams" style={{color: mainColors.red,textDecoration: 'none'}}>
                  Meet the Team
                </Link>
              </Menu.Item>
              <Menu.Item  className='hoveredBorderRed' key="trainers">
                <Link className='cstm-btn-red-outline-border-bottom' to="/trainers" style={{color: mainColors.red,textDecoration: 'none'}}>
                  Trainers
                </Link>
              </Menu.Item>
              <Menu.Item  className='hoveredBorderRed' key="mentors">
                <Link className='cstm-btn-red-outline-border-bottom' to="/mentors" style={{color: mainColors.red,textDecoration: 'none'}}>
                  Mentors
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="/programs"
              className='hoveredBorderRed'
              style={{color: pageName.split('/')[0] === 'program' ? mainColors.red : mainColors.blue, textDecoration: 'none'}}
              title={
                <>
                  <span
                    style={{color: pageName.split('/')[0] === 'program' ? mainColors.red : mainColors.blue}}
                    className="d-flex justify-content-between align-items-center"
                  >
                    Programs
                    <CaretDownFilled className="d-none d-md-block d-lg-block d-xl-block" style={{color: mainColors.red}} />
                  </span>
                </>
              }
            >
              {programs.map((e,indxprog) => {
                return <Menu.Item  className='hoveredBorderRed' key={indxprog}>
                  <Link className={`cstm-btn-red-outline-border-bottom ${currentCode === e.code && 'cstm-btn-red-outline-border-bottom-active'}`} to={"/programs/" + e.code} style={{color: mainColors.red,textDecoration: 'none'}}>
                    {e?.name}
                  </Link>
                </Menu.Item>
              })}
            </SubMenu>
            <Menu.Item key="startup">
              <Link to="/startups" style={{color: pageName.split('/')[0] === 'startup' ? mainColors.red : mainColors.blue,textDecoration: 'none'}}>
                Startups
              </Link>
            </Menu.Item>
            <Menu.Item key="blogs" title="Blogs"><Link to="/blogs" style={{color: pageName.split('/')[0] === 'blogs' ? mainColors.red : mainColors.blue,textDecoration: 'none'}}>Blog</Link></Menu.Item>
            <Menu.Item key="gallery" title="Gallery"><Link to="/gallery" style={{color: pageName.split('/')[0] === 'gallery' ? mainColors.red : mainColors.blue,textDecoration: 'none'}}>Gallery</Link></Menu.Item>
            <Menu.Item key="takeAction" title="Take Action"><Link to="/takeAction" style={{color: pageName.split('/')[0] === 'takeAction' ? mainColors.red : mainColors.blue,textDecoration: 'none'}}>Take Action</Link></Menu.Item>
            <Menu.Item key="contactUs" title="Contact Us" className='noborderonhover'>
              <a
                style={{
                  ...btn.btnRedLight,
                  fontsize: '13px',
                  width:'auto',
                }}
                className="header-contactus-btn py-2 px-2"
                target={'_blank'}
                href={footers?.contactUs}
                rel="noreferrer"
              >
                CONTACT US
              </a>
            </Menu.Item>
          </Menu>
        </Container>
      </Navbar>
        <div key="homelayoutmaindiv1245">
          <Component key="homelayoutmaindiv12454342" {...rest} />
        </div>
      <Footer className="col-12 align-items-end d-flex px-auto" style={{paddingBottom: '75px',paddingTop: '75px',background:mainColors.red,fontFamily:'Roboto',fontsize:'20px', color:mainColors.white, height:'auto'}}>
        <Container>
          <Row gutter={[48,48]} className="d-flex row align-items-start px-auto" style={{paddingBottom: '75px'}}>
            <Col style={{height:'100%'}} className='text-start' span={isLgSc ? 6 : 24}>
              <div className="col-12 mb-5 d-flex justify-content-between">
                <div style={{height: '53px', maxWidth: '100%'}} className='fillImg'>
                  <img alt="" src={ footerLogo } />
                </div>
              </div>

              <div className="text-start col-12">{ footers?.address && footers?.address !== '' ? footers.address : 'Sulaimani – Kirkuk Main Road, Raparin.'}</div>
              <div className="text-start col-12 mb-3">{ footers?.address2 && footers?.address2 !== '' ? footers.address2 : 'Sulaimani, KRG-Iraq.'}</div>
              {footers && <div className="text-start col-12 mb-3"><SocialMediaComp socialMedias={footers} /></div>}
            </Col>
            <Col style={{height:'100%'}} className='text-start' span={isLgSc ? 6 : 24}>
              <div className="col-12 mb-3">
                  <span>Site Map</span>
              </div>
              <div className={(isLgSc ? "border-start " : " row ") + ' col-12'}>
                <div className={(isLgSc ? 'col-12 px-3 ' : 'col-3 ') + " text-start"}><Link style={{fontFamily:'Roboto',fontsize:'20px', color:'white',textDecorationLine: 'none'}} to="/">Home</Link></div>
                <div className={(isLgSc ? 'col-12 px-3 ' : 'col-3 ') + " text-start"}><Link style={{fontFamily:'Roboto',fontsize:'20px', color:'white',textDecorationLine: 'none'}} to="/about">About</Link></div>
                <div className={(isLgSc ? 'col-12 px-3 ' : 'col-3 ') + " text-start"}><Link style={{fontFamily:'Roboto',fontsize:'20px', color:'white',textDecorationLine: 'none'}} to="/startups">Startups</Link></div>
                <div className={(isLgSc ? 'col-12 px-3 ' : 'col-3 ') + " text-start"}><Link style={{fontFamily:'Roboto',fontsize:'20px', color:'white',textDecorationLine: 'none'}} to="/blogs">Blog</Link></div>
                <div className={(isLgSc ? 'col-12 px-3 ' : 'col-3 ') + " text-start"}><Link style={{fontFamily:'Roboto',fontsize:'20px', color:'white',textDecorationLine: 'none'}} to="/gallery">Gallery</Link></div>
              </div>
            </Col>
            <Col style={{height:'100%'}} className='text-start' span={isLgSc ? 6 : 24}>
              <div className="mb-3 col-12">
                <div>Programs</div>
              </div>
              <div className={(isLgSc ? "border-start " : "row ")+ ' col-12'}>
                {programs?.slice(0, 6)?.map((er,indfootprog) => {
                  return (
                    <div key={indfootprog} className={(isLgSc ? "col-12 px-3 " : "col-3") + " text-start mb-1"}><Link style={{fontFamily:'Roboto',fontsize:'20px',color: 'white', textDecorationLine: 'none'}} to={"/programs/" + er?.code}>{er?.name}</Link></div>
                  )
                })
                }
              </div>
            </Col>
            <Col style={{height:'100%'}} className='text-start' span={isLgSc ? 6 : 24}>
              <div className="col-12 mb-3">
                <div>Take Action
                </div>
              </div>
              <div className={(isLgSc ? "border-start " : "row ") + ' col-12'}>
                <div className={(isLgSc ? 'col-12 px-3 ' : 'col-4 ') + ' text-start mb-1'}><Link to="/takeAction" style={{fontFamily:'Roboto',fontsize:'20px',textDecorationLine: 'none',color:'white'}}>Volunteer with Takween</Link></div>
                <div className={(isLgSc ? 'col-12 px-3 ' : 'col-4 ') + ' text-start mb-1'}><Link to="/takeAction" style={{fontFamily:'Roboto',fontsize:'20px',textDecorationLine: 'none',color:'white'}}>Become a Mentor</Link></div>
                <div className={(isLgSc ? 'col-12 px-3 ' : 'col-4 ') + ' text-start mb-1'}><Link to="/takeAction" style={{fontFamily:'Roboto',fontsize:'20px',textDecorationLine: 'none',color:'white'}}>Join the Team</Link></div>
              </div>
            </Col>
          </Row>

          <div className={(isLgSc ? '' : 'row ') + ' d-flex py-3 justify-content-between border-top'}>
            <span className={isLgSc ? '' : 'col'}><a target={'_blank'} href={footers?.contactUs} rel="noreferrer" className='cstm-btn-white-outline p-2' style={btn.btnWhiteLight}>Contact Us</a></span>
            <span className={isLgSc ? '' : 'col'}>© {new Date().getFullYear()}, Takween Accelerator, All Rights Reserved</span>
          </div>
        </Container>
      </Footer>
    </span>
  );
}
const mapStateToProps = state => {
  return ({
  programs: state.program.programs,
  footers: state.footer.footers,
  blogs: state.blog.blogs,
  partials: state.partial.partials,
})};

export default connect(mapStateToProps,null)(HomeLayout);