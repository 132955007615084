import { FETCH_PARTIALS } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = "partials"

export const fetchPartials = () => async dispatch => {
  try {
    let firstResponse = {}
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/`);
    if (response.data.data[0]) {
      firstResponse = response.data.data[0]
    }
    dispatch({
      type: FETCH_PARTIALS,
      payload: {
        partials: firstResponse
      }
    })
  } catch(err) {
    console.log(err);
  }
};

export const editPartial = ( payload ) => async dispatch => {
  try {
    await axiosConn.post(`/${modelName}/update`,payload)
    dispatch(fetchPartials());
  } catch(err) {
    console.log(err)
  }
};
