import { FETCH_FOOTERS } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = 'footers';

export const fetchFooters = () => async dispatch => {
  try {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/`);
      dispatch({
        type: FETCH_FOOTERS,
        payload: {
          footers: response.data.data[0]
        }
      })
  } catch(err) {
    console.log(err);
  }
};

export const editFooter = ( payload ) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/update`,payload)
      dispatch(fetchFooters());
  }catch(err) {
      console.log(err)
    }
};
