import React, { useState,useRef, useEffect } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { loginAuth } from '../../actions/admin/adminAuthActions';
import {Container} from 'react-bootstrap'
import { mainColors } from '../../values/colors';
import { EyeInvisibleOutlined, EyeTwoTone, CopyOutlined } from '@ant-design/icons';

import {
  message,
  Form,
  Select,
  InputNumber,
  Switch,
  Radio,
  Input,
  Slider,
  Button,
  Upload,
  Rate,
  Checkbox,
  Row,
  Col,
} from 'antd';
import { Redirect } from 'react-router-dom';

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 6 },
};

const LoginPage = () => {
  let dispatch = useDispatch();
  const [mee, setMee] = useState(localStorage.getItem('myUser'))
  const [loading, setLoading] = useState(false)
  const formProps = {
    onFinish: (values) => {
      const newVals = {
        "username": values.username,
        "password": values.password,
      }
      setLoading(true)
      dispatch(loginAuth(values))
      .then(() => {
        setLoading(false)
        message.success('Logged in!')
      })
      .catch(err => {
        setLoading(false)
        message.error('Wrong Credentials')
      })
      console.log("myuser: ", localStorage.getItem('myUser'))
    },
    onFinishFailed: () => {
      console.log('failed finished login form: ');
    },
  }
  console.log(mee)
  if (localStorage.getItem('token')) {
    return <Redirect to="/admin" />
  }
  return (
    <>
    <Container className='bg-light rounded shadow p-3' style={{fontFamily: 'Jost', fontSize:'24px'}}>
      <Form
        name="main_form"
        {...formProps}
      >
        <h2
        className='my-5'
          style={{
            textAlign: "center",
            fontFamily: "JostBold",
          }}
        >
          Login
        </h2>
        <Form.Item
          {...formItemLayout}
          name="username"
          style={{ marginBottom: "20px" }}
          label="Username"
        >
          <Input
            placeholder="Please input your username"
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="password"
          style={{ marginBottom: "20px" }}
          label="Password"
        >

          <Input.Password
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            placeholder="Please input your password"
          />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button loading={loading} variant="contained" type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </Container>
    </>
  );
}
export default LoginPage;
