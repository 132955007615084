import { FETCH_AUTTHS } from '../types'
import axiosConn from '../../axiosConn';
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchHomes } from '../homeActions';
const modelName = "auth"

export const loginAuth = (payload) => async dispatch => {
    console.log("login: ",payload)
    const finval = {
      username: payload['username'],
      password: payload['password'],

    }
    let response = await axiosConn.post(`/${modelName}/signin`, finval);
    console.log("login: ",response)
    let userRes = {}
    if (response && response.data && response.data.success) {
      userRes = response.data.data.user
      localStorage.setItem('token', response.data.data.accessToken);
      console.log("worked: ",userRes)
      dispatch({
        type: FETCH_AUTTHS,
        payload: {
          user: userRes,
        }
      })
      dispatch(fetchHomes)
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
};

export const editProfile = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/auth/editProfile`,payload)
    dispatch(userAuth());
  }catch(err) {
    console.log(err)
  }
};

export const logoutAuth = () => async dispatch => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
}

export const userAuth = () => async dispatch => {
  let userRes = {}
  try {
    let response = await axiosConn.get(`/${modelName}/me`)
    
    if (response && response.data ) {
      localStorage.setItem('user', response.data)
      dispatch({
        type: FETCH_AUTTHS,
        payload: {
          user: response.data,
        }
      })
    }
  } catch(err) {
    dispatch({
      type: FETCH_AUTTHS,
      payload: {
        user: userRes,
      }
    })
    dispatch(logoutAuth())
  }
  console.log("sssss",localStorage.getItem('user'))
}
