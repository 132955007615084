import { FETCH_TEAMS } from './types'
import { FETCH_MENTORS } from './types'
import { FETCH_TRAINERS } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = 'teams';

  export const fetchTeams = () => async dispatch => {
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/teams`);
        dispatch({
          type: FETCH_TEAMS,
          payload: {
            teams: response.data.data.sort((a,b) => {return a["id"] - b["id"] })
          }
        })
    } catch(err) {
      console.log(err);
    }
  };

  export const fetchMentors = () => async dispatch => {
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/mentors`);
        dispatch({
          type: FETCH_MENTORS,
          payload: {
            mentors: response.data.data.sort((a,b) => {return a["id"] - b["id"] })
          }
        })
    } catch(err) {
      console.log(err);
    }
  };

  export const fetchTrainers = () => async dispatch => {
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/trainers`);
        dispatch({
          type: FETCH_TRAINERS,
          payload: {
            trainers: response.data.data.sort((a,b) => {return a["id"] - b["id"] })
          }
        })
    } catch(err) {
      console.log(err);
    }
  };

  export const addTeam = ( payload, code ) => async dispatch => {
    try{
        await axiosConn.post(`/${modelName}/createTeam`,payload)
        dispatch(fetchTeams());
    }catch(err) {
        console.log(err)
     }
  };

  export const addMentor = ( payload, code ) => async dispatch => {
    try{
        await axiosConn.post(`/${modelName}/createMentor`,payload)
        dispatch(fetchMentors());
    }catch(err) {
        console.log(err)
     }
  };

  export const addTrainer = ( payload, code ) => async dispatch => {
    try{
        await axiosConn.post(`/${modelName}/createTrainer`,payload)
        dispatch(fetchTrainers());
    }catch(err) {
        console.log(err)
     }
  };

  export const editTeam = ( payload, code ) => async dispatch => {
    try{
        await axiosConn.post(`/${modelName}/update`,payload)
        dispatch(fetchTeams());
        dispatch(fetchTrainers());
        dispatch(fetchMentors());
    }catch(err) {
        console.log(err)
     }
  };

  export const deleteImage = (payload) => async dispatch => {
    try{
      await axiosConn.post(`/${modelName}/delete`,payload)
      dispatch(fetchTeams());
      dispatch(fetchTrainers());
      dispatch(fetchMentors());
  }catch(err) {
      console.log(err)
   }
  }

  export const deleteTeams = (payload, id) => async dispatch => {
    try{
       await axiosConn.post(`/${modelName}/delete`, payload);
       dispatch(fetchTeams());
       dispatch(fetchMentors());
       dispatch(fetchTrainers());
    }catch(err) {
     }
  };