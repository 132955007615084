import { FETCH_STARTUPS } from '../actions/types';
const initialState = {
  startups: []
};

export const StartupReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STARTUPS:
      return {
        ...state,
        startups: action.payload.startups,
      };
    default:
      return {...state};
  }
}