import React, { useState,useRef, useEffect } from 'react';
import { Avatar, Popover, Card } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { mainColors } from '../../values/colors';
import { getFileLink } from '../LibraryMethods';

const {Meta} = Card

const ProfilePop = ({userParams}) => {
  return (
    <Meta
    avatar={<Avatar icon={<UserOutlined />} src={getFileLink(userParams?.avatar)} />}
    title={
      <div style={{color: mainColors.white}}>
          {userParams?.name}
      </div>
    }
    description={
      <div style={{color: mainColors.white}}>
        {'@' + userParams?.username}
      </div>
    }
    />
  )
}
export default ProfilePop;