import React, { useState } from 'react';
import { Card,Row,Col,Input, Divider } from 'antd';
import { CloseSquareOutlined, EditOutlined, DeleteOutlined,AppstoreAddOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import AddEditTakeAction from './AddEditTakeAction';
import { Link, useParams } from 'react-router-dom';
import { AddOutlined, VisibilityOutlined } from '@mui/icons-material';
import { mainColors } from '../../../values/colors';
import { getFileLink } from '../../LibraryMethods';

const { Meta } = Card;

const TakeAction = ({takeAction}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (action) => {
    setAction(action);
    setIsModalVisible(true);
  };
return(
  <>
    <Card
    className='shadow'
      style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
      cover={
        <div key={"rakeActionCard" + takeAction.code}>
          <Divider>Investor Section</Divider>
          <span className='fillImg' style={{height:'238px',width:'inherit'}}>
            <img
              src={getFileLink(takeAction.invLogo)}
            />
          </span>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Title: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.invName}</p></span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description on Cover: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.invDiscription}</p></span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Link: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.invLink}</p></span></div>
          <Divider>Volunteer Section</Divider>
          <span className='fillImg' style={{height:'238px',width:'inherit'}}>
            <img
              src={getFileLink(takeAction.vlnLogo)}
            />
          </span>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Title: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.vlnName}</p></span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description on Cover: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.vlnDiscription}</p></span></div>
            <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Link: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.vlnLink}</p></span></div>
          <Divider>Last Section</Divider>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Title: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.tknName}</p></span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description on Cover: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.tknDiscription}</p></span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Link: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.tknLink}</p></span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Mentor Link: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.mentorLink}</p></span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Trainer Link: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.trainerLink}</p></span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Team Link: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{takeAction.teamLink}</p></span></div>
        </div>
      }
    >
    </Card>
    { isModalVisible &&
    <AddEditTakeAction action={action} payload={takeAction} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
    }
  </>
)};

export default TakeAction;
