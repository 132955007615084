import React, { useState } from 'react';
import { Card, Divider, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteStartups } from '../../../actions/startupActions';
import AddEditStartup from './AddEditStartup';
import { Link, useParams } from 'react-router-dom';
import { VisibilityOutlined } from '@mui/icons-material';
import { mainColors } from '../../../values/colors';

const { Meta } = Card;

const StartupCard = ({startup, programs}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const showModal = (action) => {
    setAction(action);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelConfirm = () => {
    setIsConfirmDeleteVisible(false)
  }
  const handleOkConfirm = () => {
    dispatch(deleteStartups({code:startup.code}))
    setIsConfirmDeleteVisible(false);
  }
  const showModalConfirm = () => {
    setIsConfirmDeleteVisible(true);
  };
return(
  <>
  <Card
    style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
    cover={
      <>
        <div style={{backgroundColor: mainColors.light,width: '100%', height: 400}} className='fillImg' >
          <img
            src={startup.logo ? `${process.env.REACT_APP_API_URL}/${startup.logo}`  : 'https://icon-library.com/images/1041139-200_42853.png'}
            alt="avatar"
          />
        </div>
        {startup.status === 'featured' ? <span style={{fontSize: '19px'}} className='bg-danger square rounded p-1 p-elipsis p-wrap col text-white'>{startup.status}</span> : <></>}
        <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Program: </b></span><span style={{fontSize: '19px'}} className='col'>{programs.find(e => e.code === startup.programCode)?.name}</span></div>
        <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Name: </b></span><span style={{fontSize: '19px'}} className='col'>{startup.name}</span></div>
        <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description: </b></span><span style={{fontSize: '19px'}} className='p-elipsis p-wrap col'>{startup.description?.substring(0,100)}</span></div>
        <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Link: </b></span><span style={{fontSize: '19px'}} className='p-elipsis p-wrap col'>{startup.refLink}</span></div>
        <Modal title="Delete Image" visible={isConfirmDeleteVisible} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
          <p>Are you sure?</p>
        </Modal>
      </>
    }
    actions={[
      <DeleteOutlined onClick={()=> showModalConfirm()} key={"delete" + startup.id} />,
      <EditOutlined onClick={()=>showModal('Edit')} key={"edit" + startup.id} />,
      // <Link to={ "/startups/" + startup.code}><VisibilityOutlined onClick={()=>showModal('Edit')} key={"edit" + startup.id} /></Link>,
    ]}
  />
  { isModalVisible &&
    <AddEditStartup action={action} payload={startup} programs={programs} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
  }
  </>
)};

export default StartupCard;
