export const mainColors = {
  blue: '#015076',
  blueLight: '#d2f4ff',
  red: '#c81436',
  redLight: '#FFAFBE',
  orange: '#fea832',
  yellow: '#fedb41',
  dark: '#015076',
  grey: '#989898',
  light: '#f2f2f2',
  white: '#ffffff',
}
