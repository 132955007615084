import React, { useState,useRef, useEffect } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchBlogs } from '../actions/blogActions';
import {Container} from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom';
import { DividerSvg } from '../components/DividerSvg'
import Box from '@mui/material/Box';
import { mainColors } from '../values/colors';
import {btn,countingNumStyle} from '../values/styles';
import { RightOutlined} from '@ant-design/icons';
import { Col } from 'antd';
import humanizeDuration from 'humanize-duration'
import SocialMedias from '../components/SocialMedias'
import {Svg} from '../components/SvgViewer'
import * as moment from 'moment'
import { Row, Divider } from 'antd';
import { getFileLink } from '../components/LibraryMethods';

const DemoBox = props => <p className={`height-${props.value}`}>{props.children}</p>;
const Blog = (...rest) => {
  console.log("params from app.js: ",useParams())
  const [isLgSc, setisLgSc] = useState(window.innerWidth > 600)
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
  }, [isLgSc])
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBlogs());
  },[dispatch])

  let blogs = useSelector(state => state.blog.blogs)
  let codpar = useParams()?.code
  let blog = blogs.find(we => we.code === codpar )
  console.log("i have selected  : " ,blog, codpar,blogs)
  function parseDat(datt) {
    return datt ? moment(datt).format('DD/MMM/YYYY') : ''
  }

  let finBlog = {}
  if (blog) finBlog = {code: blog.code,summary: blog.summary, name: blog.name,date: parseDat(blog.date ? blog.date : blog.createdAt ),position: blog.position, logo: getFileLink(blog.logo), description: blog.description}
  const MediaViewr = ({url}) => {
    console.log(url)
    if (url.toString().toLowerCase().includes('svg')){
      return <span
        className="col-12"
        style={{
          backgroundColor: mainColors.blue,
        }}
          alt="Image"
        >
          <Svg url={url}/>
        </span>
    } else {
      return <span
        className="col-12"
        style={{
          backgroundColor: mainColors.blue,
        }}
        alt="Image"
        >
        <img
        className="col-12"
        style={{
          backgroundColor: mainColors.blue,
          height:'auto'
        }}
        alt="example"
        src={url}
        />
        </span>
    }
  }
  return (
    <>

      <Container fluid={true} style={{backgroundColor: mainColors.light ,height:'162px'}} className=" px-0 col d-flex justify-content-start align-items-center">
      </Container>
      <div style={{position:'relative'}}>
        <div style={{position:'relative'}}>
          <span
            className={(isLgSc ? 'justify-content-center ' : 'justify-content-start ') + "d-flex align-items-center"}
            style={{background: 'linear-gradient(to right, '+mainColors.red+', transparent)', transform: 'translateY(-50%)', zIndex: '1', position:'absolute'}}
          >
            <div className={isLgSc ? 'text-start' : 'px-3'} style={{position:'absolute', fontSize: '40px',fontFamily: 'JostBoldItalic', color: mainColors.white}}>Blog</div>
            <DividerSvg type="red" />
          </span>
        </div>
      </div>
      <Container fluid={!isLgSc}>
        <Row >
          <Col span={isLgSc ? 12 : 24} className='text-start py-3' style={{paddingRight:isLgSc ? '39px' : ''}} order={isLgSc ? 1 : 2}>
            <div style={{backgroundColor: mainColors.light}} className='col-12' style={{height:'80px'}}></div>
            <div className='pt-4 pb-2' style={{fontFamily:'RobotoItalic',fontSize:'19px', color:mainColors.blue}}>{finBlog?.date}</div>
            <div className='pb-4' style={{fontSize: '34px' ,fontFamily:'JostMedium', color:mainColors.blue}}>{finBlog.name}</div>
            <div className='text-start p-wrap' style={{fontFamily:'Roboto', fontSize:'24px',color:mainColors.blue}}>
              {finBlog.summary}
            </div>
          </Col>
          <Col style={{paddingLeft: isLgSc ? '39px' : '', backgroundColor: 'transparent'}} span={isLgSc ? 12 : 24} order={isLgSc ? 2 : 1}>
            <DemoBox value={180} className="col-6 p-0 " style={{height: '460px',backgroundColor:mainColors.blue,backgroundImage: finBlog.logo}}>
              {finBlog.logo ? 
                <MediaViewr url={finBlog.logo} />
                :
                ''
              }
            </DemoBox>
          </Col>
          <Col span={24} className='py-3' order={3}>
            <div className='p-wrap text-start' style={{fontFamily:'Roboto', fontSize:'24px',color:mainColors.blue}}>
              {finBlog.description}
            </div>
          </Col>
        </Row>
        <Container
          style={{
            width:'100%',
          }}
          className='py-5 mb-5 justify-content-center align-items-center d-flex'
        >
          <Link to={'/blogs'} className='px-4 py-2 cstm-btn-red-outline' style={{...btn.btnRedLight,fontFamily:'RobotoBold', fontSize:'22px'}}>
            Back to Blog
          </Link>
        </Container>
      </Container>
    </>
  );
}
const mapStateToProps = state => {
  return ({
    blogs: state.blog.blogs,
  })
};
export default connect(mapStateToProps,null)(Blog);
