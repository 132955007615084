import React, {useState,useRef,useEffect } from 'react';
import Slider from "react-slick";
import { useDispatch, connect, useSelector } from "react-redux";
import { RightOutlined} from '@ant-design/icons';
import { fetchStartups } from '../actions/startupActions';
import StartupSection from '../components/home/StartupSection'
import {Container} from 'react-bootstrap'
import { mainColors } from '../values/colors';
import {btn,countingNumStyle, ImgBg, imgBg} from '../values/styles'
import SocialMedias from '../components/SocialMedias'
import { fetchTakeActions } from '../actions/takeActionActions.js';
import { Menu, Dropdown, Card,Carousel } from 'antd';
import { Row, Col, Button } from 'antd';
import { Link, useParams } from 'react-router-dom';
import * as moment from 'moment'
import {Svg} from '../components/SvgViewer'
import { DividerSvg } from '../components/DividerSvg'
import TkwnButton from '../components/linkButtonLong'
import { getFileLink } from '../components/LibraryMethods';
const { Meta } = Card;
const isLgSc = window.innerWidth > 600

const {SubMenu} = Menu
const DemoBox = props => <p className={`height-${props.value}`}>{props.children}</p>;
const TakeAction = (...rest) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1
	};

	const MediaViewr = ({url}) => {
    console.log(url)
    if (url.toString().toLowerCase().includes('svg')){
      return <span
        className="col-12"
        style={{
          backgroundColor: mainColors.blue,
        }}
          alt="Image"
        >
          <Svg url={url}/>
        </span>
    } else {
      return <span
        className="fillImg col-12"
        style={{
          backgroundColor: mainColors.blue,
        }}
        alt="Image"
        >
        <img
        className="col-12"
        style={{
          backgroundColor: mainColors.blue,
          height:'auto'
        }}
        alt="example"
        src={url}
        />
        </span>
    }
  }
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTakeActions());
  },[dispatch])
  let takeActions = useSelector(state => state.takeAction.takeActions);
  console.log("takeActions", takeActions)
  function parseDat(datt) {
    return datt ? moment(datt).format('DD/MMM/YYYY') : ''
  }

  if (takeActions){
    takeActions.invLogo = getFileLink(takeActions.invLogo)
    takeActions.vlnLogo = getFileLink(takeActions.vlnLogo)
  }

  return (
    <>
      <Container fluid="true" style={{backgroundColor: mainColors.light ,height:'162px'}} className='p-auto '>
        <Container className='d-flex justify-flex-start align-items-end' style={{height:'122px'}}>
          <div style={{color: mainColors.blue, fontFamily:'JostBoldItalic', fontSize:'41px'}}>
            Take Action
          </div>
        </Container>
      </Container>
      <div className='col' style={{position:'relative'}}>
        <div className="d-flex justify-content-start align-items-center" style={{position:'relative'}}>
          <span className="d-flex justify-content-center align-items-center" style={{background: 'linear-gradient(to right, '+mainColors.red+', transparent)',position:'absolute'}}>
            <DividerSvg type="red" />
          </span>
        </div>
      </div>
      <Container fluid={true} className='d-flex px-0 flex-wrap justify-content-center '>
        <div
          style={{
            width: '100%',
            padding: 'auto',
            minHeight: '650px',
            backgroundImage: `URL(${takeActions.invLogo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          className={(isLgSc ? 'col-6 ' : '' ) + ' d-flex flex-wrap justify-content-start align-content-center '}
        >
          <Container className='p-3 text-start' style={{fontFamily:'JostBold', fontSize:'22px',color:mainColors.white}}>
            <div className='col-12'><div className='col-6 pb-2 pt-3' style={{color: mainColors.white,fontFamily: 'JostBoldItalic', fontSize: '28px'}}>{takeActions.invName}</div></div>
            <div className='col-12'><div className={(isLgSc ? 'col-6' : '') + ' pb-2 pb-3 p-wrap'} style={{color: mainColors.white,fontFamily: 'RobotoLight', fontSize: '17px'}}>{takeActions.invDiscription}</div></div>
            <div>
              <a target={"_blank"} rel="noreferrer" href={takeActions.invLink} className="px-4 cstm-btn-white-outline py-2" style={{...btn.btnWhiteLight,fontFamily: 'RobotoMedium', fontSize:'16px'}}>Join Us {'>'}</a>
            </div>
          </Container>
        </div>
      </Container>
      <div className="col-12 px-0 position-relative d-flex justify-content-end">
        <div className="position-absolute" style={{transform:'translate(25%, -50%)'}}>
          <DividerSvg type="red" />
        </div>
        <div className="position-absolute" style={{transform:'translate(-31%, -50%)'}}>
          <DividerSvg type="red" />
        </div>
      </div>
      <Container fluid={true} className='px-0 d-flex flex-wrap justify-content-center'>
        <div
          style={{
            padding: 'auto',
            minHeight: '650px',
            backgroundImage: `URL(${takeActions.vlnLogo})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
          className={(isLgSc ? 'col-6' : '') + ' py-5 d-flex flex-wrap justify-items-center align-items-center '}
        >
        </div>
          <Container
            className={(isLgSc ? 'col-6' : '') + ' d-flex flex-wrap px-5 align-content-center text-start'}
            style={{backgroundColor:mainColors.blue,fontFamily:'JostBold', fontSize:'22px',color:mainColors.white}}
          >
            <div className='col-12 pb-2 pt-3' style={{color: mainColors.white,fontFamily: 'JostBoldItalic', fontSize: '28px'}}>{takeActions.vlnName}</div>
            <div className='col-10 pb-2 pb-3 p-wrap' style={{color: mainColors.white,fontFamily: 'RobotoLight', fontSize: '17px'}}>{takeActions.vlnDiscription}</div>
            <div className='col-12'>
              <a target={"_blank"} rel="noreferrer" href={takeActions.vlnLink} className="px-4 py-2 cstm-btn-white-outline" style={{...btn.btnWhiteLight,fontFamily: 'RobotoMedium', fontSize:'16px'}}>Join Us {'>'}</a>
            </div>
        </Container>
      </Container>
      <Container fluid={true}
        style={{background: `linear-gradient(to right ,${mainColors.red} ${isLgSc ? '50%' : '100%'}, ${mainColors.white} ${(isLgSc ? '50%' : '0%') })`,fontFamily:'JostBold', fontSize:'22px',color:mainColors.white}}
        className='px-0 py-5 flex-wrap'>
        <Container className='d-flex flex-wrap justify-content-start align-content-center text-start flex-wrap' style={{minHeight: "614px"}}>
          <div className='col-12'><div className={(isLgSc ? 'col-6' : '') + ' pb-2 pt-3'} style={{color: mainColors.white,fontFamily: 'JostBoldItalic', fontSize: '28px'}}>{takeActions.tknName}</div></div>
          <div className='col-12'><div className={(isLgSc ? 'col-6' : '') + ' p-wrap pb-2 pb-3'} style={{color: mainColors.white,fontFamily: 'RobotoLight', fontSize: '17px'}}>{takeActions.tknDiscription}</div></div>
          <div className="col-12 py-3"><TkwnButton text={'Become a mentor'} color={mainColors.red} toUrl={takeActions.mentorLink} /></div>
          <div className="col-12 py-3"><TkwnButton text={'Become a trainer'} color={mainColors.red} toUrl={takeActions.trainerLink} /></div>
          <div className="col-12 pt-3"><TkwnButton text={'Join the Team'} color={mainColors.red} toUrl={takeActions.teamLink} /></div>
        </Container>
      </Container>
      <Container fluid={true} className='py-5 my-5'></Container>
    </>
  );
}

const mapStateToProps = state => {
  return ({
    takeActions: state.takeAction.takeActions,
  })
};

export default connect(mapStateToProps,null)(TakeAction);