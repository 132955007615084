import { Upload, Button, message, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { Modal, Input,Row,Col, InputNumber } from 'antd';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { editTakeAction, fetchTakeActions } from '../../../actions/takeActionActions';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';

const { Option, OptGroup } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const AddEditTakeAction = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  let [invDiscription, setInvDiscription] = useState('');
  let [invLogo, setInvLogo] = useState(null);
  let [invName, setInvName] = useState('');
  let [invLink, setInvLink] = useState('');
  let [vlnDiscription, setVlnDiscription] = useState('');
  let [vlnLogo, setVlnLogo] = useState(null);
  let [vlnName, setVlnName] = useState('');
  let [vlnLink, setVlnLink] = useState('');
  let [tknDiscription, setTknDiscription] = useState('');
  let [tknName, setTknName] = useState('');
  let [tknLink, setTknLink] = useState('');
  let [trainerLink, setTrainerLink] = useState('');
  let [mentorLink, setMentorLink] = useState('');
  let [teamLink, setTeamLink] = useState('');
  let [fileinv, setFileinv] = useState(null);
  let [fileinvRead, setFileinvRead] = useState(null);
  let [filevln, setFilevln] = useState(null);
  let [filevlnRead, setFilevlnRead] = useState(null);
  let [loading, setloading] = useState(null);
  let dispatch = useDispatch();

  let {code} = useParams();

  useEffect(async () => {
     await dispatch(fetchTakeActions());
      setInvDiscription(payload.invDiscription);
      setInvLogo(payload.invLogo);
      setInvName(payload.invName);
      setInvLink(payload.invLink);
      setVlnDiscription(payload.vlnDiscription);
      setVlnLogo(payload.vlnLogo);
      setFileinvRead(getFileLink(payload.invLogo))
      setFilevlnRead(getFileLink(payload.vlnLogo))
      setVlnName(payload.vlnName);
      setVlnLink(payload.vlnLink);
      setTknDiscription(payload.tknDiscription);
      setTknName(payload.tknName);
      setTknLink(payload.tknLink);
      setTrainerLink(payload.trainerLink);
      setMentorLink(payload.mentorLink);
      setTeamLink(payload.teamLink);
    },[dispatch]);

  function validate() {
    let data = new FormData();
    data.append('invDiscription', invDiscription);
    if (invLogo) data.append('invLogo', invLogo);
    data.append('invName', invName);
    data.append('invLink', invLink);
    data.append('vlnDiscription', vlnDiscription);
    if (vlnLogo) data.append('vlnLogo', vlnLogo);
    data.append('vlnName', vlnName);
    data.append('vlnLink', vlnLink);
    data.append('tknDiscription', tknDiscription);
    data.append('tknName', tknName);
    data.append('tknLink', tknLink);
    data.append('trainerLink', trainerLink);
    data.append('mentorLink', mentorLink);
    data.append('teamLink', teamLink);

    if(fileinv) {
      data.append('invLogo', fileinv);
    }
    if(filevln) {
      data.append('vlnLogo', filevln);
    }
    data.append('code', payload.code)
    dispatch(editTakeAction(data, code));

    handleOk();
  }


  const invprops = {
    beforeUpload: file => {
      if (!file.type.includes('image')) {
        message.error(`${file.name} is not image file`);
      }else{
          setInvLogo(file);
      }
      return file.type.includes('image') ? true : Upload.LIST_IGNORE;
    },
    onChange: info => {
        if(info.fileList.length === 0)
        setInvLogo(null);
      },
  };
  const vlnprops = {
    beforeUpload: file => {
      if (!file.type.includes('image')) {
        message.error(`${file.name} is not image file`);
      }else{
          setVlnLogo(file);
      }
      return file.type.includes('image') ? true : Upload.LIST_IGNORE;
    },
    onChange: info => {
        if(info.fileList.length === 0)
        setVlnLogo(null);
      },
  };

  const invuploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFileinv(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFileinvRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFileinv(null);
        setloading(false)
      }
    },
  };
  const vlnuploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFilevln(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFilevlnRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFilevln(null);
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );


  return (
    <>
      <Modal title={action+' TakeAction'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Divider>Investor Section</Divider>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Title on Cover" value={invName} onChange={(evt) => {setInvName(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Description on Cover <Input.TextArea {...textSizeAreaOptions()} value={invDiscription} onChange={(evt) => {setInvDiscription(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Join Us Link" value={invLink} onChange={(evt) => {setInvLink(evt.target.value);}} /></Col>
          <Col span={24}>
            <Upload
              {...invuploaderProps}
            >
              {fileinvRead ? <img src={fileinvRead} alt="avatar" style={{ height: '128px' }} /> : uploadButton}
            </Upload>
          </Col>
          <Divider>Volunteer Section</Divider>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Title on Cover" value={vlnName} onChange={(evt) => {setVlnName(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Description on Cover <Input.TextArea {...textSizeAreaOptions()} value={vlnDiscription} onChange={(evt) => {setVlnDiscription(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Join Us Link" value={vlnLink} onChange={(evt) => {setVlnLink(evt.target.value);}} /></Col>
          <Col span={24}>
            <Upload
              {...vlnuploaderProps}
            >
              {filevlnRead ? <img src={filevlnRead} alt="avatar" style={{ height: '128px' }} /> : uploadButton}
            </Upload>
          </Col>
          <Divider>Last Section</Divider>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Title" value={tknName} onChange={(evt) => {setTknName(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Description <Input.TextArea {...textSizeAreaOptions()} value={tknDiscription} onChange={(evt) => {setTknDiscription(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Join Us Link" value={tknLink} onChange={(evt) => {setTknLink(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Team Link" value={teamLink} onChange={(evt) => {setTeamLink(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Mentor Link" value={mentorLink} onChange={(evt) => {setMentorLink(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Trainer Link" value={trainerLink} onChange={(evt) => {setTrainerLink(evt.target.value);}} /></Col>
        </Row>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  takeActions: state.takeAction.takeActions,
});

export default connect(mapStateToProps, null)(AddEditTakeAction);