import { FETCH_AUTTHS } from '../actions/types';
const initialState = {
  user: {},
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AUTTHS:
      return {
        ...state,
        user: action.payload.user,
      };
    default:
    return {...state};
  }
}
