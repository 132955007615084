import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddEditTrainer from "../../../components/admin/Trainers/AddEditTrainer";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import { fetchTrainers } from '../../../actions/teamActions';
import TrainerCard from '../../../components/admin/Trainers/TrainerCard';
import { Link, useParams } from 'react-router-dom';
import { PageHeader,Col,Row } from 'antd';
import { Container } from '@mui/material';

const Trainers = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [action, setAction] = useState(false);
    let dispatch = useDispatch();

    let { id } = useParams()

    useEffect(() => {
      dispatch(fetchTrainers(id));
    },[dispatch]);

    let trainers = useSelector(state => state.trainer.trainers);

    const showModal = (action) => {
      setAction(action);
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return (
      <>
        <PageHeader
          className="site-page-header shadow"
          title={'Trainers ' + '(' + trainers.length + ')'}
          extra={[
            <Button key="buttonAdd" onClick={() => showModal('Add')} type="primary" icon={<PlusOutlined />} />
          ]}
          onBack={() => window.history.back()}
        />
        <Container>
          <Row gutter={[20,20]} className="my-3 col-12 row" style={{padding: 15}}>
            {trainers.length ===0 && <div style={{marginTop:17}}>No trainers are available</div>}
            {trainers.map((trainer,ind) => {
              return (
                  <Col span={12} key={ind} className="pa-5" >
                    <TrainerCard trainer={trainer} />
                  </Col>
              );
            })}
          </Row>
          { isModalVisible &&
            <AddEditTrainer action={action} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}/>
          }
        </Container>
      </>
    );
  }

  const mapStateToProps = state => ({
    trainers: state.trainer.trainers,
  });

  export default connect(mapStateToProps, null)(Trainers);