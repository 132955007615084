import React, { useState, useEffect, useRef } from 'react';
import { Button,Row,Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddEditStartup from "../../../components/admin/Startups/AddEditStartup";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import { fetchStartups } from '../../../actions/startupActions';
import StartupCard from '../../../components/admin/Startups/StartupCard';
import { Link, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { Container } from 'react-bootstrap';
import { fetchPrograms } from '../../../actions/programActions';
import FilterDropDown from '../../../components/admin/FilterDropDown';
import { groupArr } from '../../../components/LibraryMethods';

const Startups = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [startupsReady, setstartupsReady] = useState([]);
    const [filterReady, setfilterReady] = useState([])
    const [filter, setfilter] = useState(null);
    const [action, setAction] = useState(false);
    let dispatch = useDispatch();

    let { id } = useParams()

    useEffect(() => {
      dispatch(fetchStartups());
      dispatch(fetchPrograms());
    },[dispatch]);
    let startups = useSelector(state => state.startup.startups);
    let programs = useSelector(state => state.program.programs);
    const scrollEvent = useRef()
    useEffect(() => {
      setfilterReady(programs)
    }, [programs])
    const showModal = (action) => {
      setAction(action);
      setIsModalVisible(true);
    };

    const handleOk = () => {
      scrollEvent.current.scrollIntoView({behavior: 'smooth'});
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };
    useEffect(() => {
      setstartupsReady(startups?.filter(erw => {
        if (filter) return (erw.programCode === filter)
        return true
      }))
    },[startups,filter])  
    return (
      <>
        <PageHeader
          className="site-page-header shadow"
          title={'Startups ' + '(' + startups.length + ')'}
          extra={
            <div key="dlkejnflejnfe">
            {<FilterDropDown payload={filterReady} handleFilter={(evt) => setfilter(evt.key)} />}
            <Button key="buttonAdd" onClick={() => showModal('Add')} type="primary" icon={<PlusOutlined />} />
            </div>
          }
          onBack={() => window.history.back()}
        />
        <Container fluid>
          <Row gutter={[20,20]} className="my-3 col-12" style={{padding: 15}}>
            {startupsReady.length ===0 && <div style={{marginTop:17}}>No startups are available</div>}
            {startupsReady.map((startup) => {
              return (
                  <Col span={6} key={startup.code} className="pa-5">
                    <StartupCard programs={programs} startup={startup} />
                  </Col>
              );
            })}
          </Row>
          { isModalVisible &&
            <AddEditStartup programs={programs} action={action} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}/>
          }
        </Container>
        <div ref={scrollEvent}/>
      </>
    );
  }

  const mapStateToProps = state => ({
    startups: state.startup.startups,
    programs: state.program.programs,
  });

  export default connect(mapStateToProps, null)(Startups);