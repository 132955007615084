import { mainColors } from "./colors"
export const btn = {
  btnRed: {
    padding: '10p auto',
    backgroundColor: mainColors.red,
    border: 0,
    color: mainColors.white,
  },
  btnRedLight: {
    padding: '5px',
    fontFamily: 'RobotoMedium',
    border: `2px solid ${mainColors.red}`,
    backgroundColor: 'transparent',
    textDecorationLine: 'none',
    color:mainColors.red,
    "&:hover": {
      backgroundColor: mainColors.red,
      color: mainColors.white
    },
  },
  btnBlue: {
    padding: '5px',
    fontFamily: 'RobotoMedium',
    border: `2px solid ${mainColors.blue}`,
    backgroundColor: mainColors.blue,
    textDecorationLine: 'none',
    color:mainColors.white,
  },
  btnBlueLight: {
    padding: '5px',
    fontFamily: 'RobotoMedium',
    border: `2px solid ${mainColors.blue}`,
    backgroundColor: 'transparent',
    textDecorationLine: 'none',
    color:mainColors.blue,
  },
  btnWhite: {
    padding: '5px',
    fontFamily: 'RobotoMedium',
    border: `2px solid ${mainColors.white}`,
    backgroundColor: mainColors.white,
    textDecorationLine: 'none',
    color:mainColors.dark,
  },
  btnWhiteLight: {
    padding: '5px',
    fontFamily: 'RobotoMedium',
    border: `2px solid ${mainColors.white}`,
    backgroundColor: 'transparent',
    textDecorationLine: 'none',
    color:mainColors.white,
  },
  btnDark: {
    padding: '5px',
    fontFamily: 'RobotoMedium',
    border: `2px solid ${mainColors.dark}`,
    backgroundColor: mainColors.dark,
    textDecorationLine: 'none',
    color:mainColors.white,
  },
  btnDarkLight: {
    padding: '5px',
    fontFamily: 'RobotoMedium',
    border: `2px solid ${mainColors.dark}`,
    backgroundColor: 'transparent',
    textDecorationLine: 'none',
    color:mainColors.dark,
  },
}

export const countingNumStyle = {
  fontFamily: 'JostItalic',
  fontSize: '30px',
  color: mainColors.white,
  display: 'flex',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'start'
}
export const ImgBg = ({imgUtl, className,...rest}) => {
  return <div className={className} style={{
    backgroundImage: `url(${imgUtl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  }}
  ></div>
}