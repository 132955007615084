import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddEditPartner from "../../../components/admin/Partners/AddEditPartner";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import { fetchPartners } from '../../../actions/partnerActions';
import PartnerCard from '../../../components/admin/Partners/PartnerCard';
import { Link, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { Container } from '@mui/material';
import { AddOutlined, VisibilityOutlined } from '@mui/icons-material';

const Partners = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();

  let { id } = useParams()

  useEffect(() => {
    dispatch(fetchPartners());
  },[dispatch]);

  let partners = useSelector(state => state.partner.partners);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);

  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  return (
    <>
      <PageHeader
        className="site-page-header shadow"
        title={'Partners'}
        onBack={() => window.history.back()}
      />
      <Container>
        <Row gutter={[20,20]} className="my-3 col-12 row" style={{padding: 15}}>
          <Col span={24}  className="pa-5">
            <Card
                className='shadow'
                style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
                cover={
                  <>
                    <Row gutter={[20,20]} className="p-2 col-12">
                    {partners.length === 0 && <div className='col-12' style={{marginTop:17}}>No partners are available</div>}
                    {partners.map(qwer => {
                      return <Col key={qwer.code} span={6}>
                        <PartnerCard partner={qwer}/>
                      </Col>
                    })}
                      <Col
                        key="addImagePartnerButtonLastCol"
                        span={6}
                      >
                        <div
                          onClick={showModal}
                          className='rounded shadow btn clickable hoverable d-flex justify-content-center align-items-center'
                          style={{height: '150px'}}
                        >
                          <AddOutlined style={{fontSize:24}} onClick={showModal} key={"addImage_398434983"} />
                        </div>
                      </Col>
                    </Row>
                  </>
                }
              >
              </Card>
          </Col>
        </Row>
        { isModalVisible &&
          <AddEditPartner action={action} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}/>
        }
      </Container>
    </>
    );
  }

  const mapStateToProps = state => ({
    partners: state.partner.partners,
  });

  export default connect(mapStateToProps, null)(Partners);