import { FETCH_PARTIALS } from '../actions/types';
const initialState = {
  partials: {},
};

export const PartialReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTIALS:
      return {
        ...state,
        partials: action.payload.partials,
      };
    default:
    return {...state};
  }
}