import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { Link, Redirect,useParams } from 'react-router-dom';
import ProfilePop from '../../components/admin/ProfilePop';
import EditProfile from '../../components/admin/Auth/EditProfile';
import { Layout, Menu } from 'antd';
import { logoutAuth, userAuth } from '../../actions/admin/adminAuthActions';
import { HomeOutlined } from '@mui/icons-material';
const { SubMenu } = Menu;
const { Content, Sider } = Layout;

const AdminLayout = ({component: Component,pageName, ...rest}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  let dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (localStorage.getItem('token') || window.location.href.includes('login'))
    dispatch(userAuth())
  },[dispatch]);
  
  let user = useSelector(state => state.auth.user);
  if ((!user || !localStorage.getItem('token')) && !window.location.href.includes('login')) {
    return <Redirect to="/admin/login" />
  }
const noDecor = {style:{textDecorationLine: 'none'}}
  return(
    <Layout>
      <Layout>
        {(localStorage.getItem('token') && user?.name) ?
          <Sider
            style={{
              overflow: 'hidden',
              height: '100%',
              position: 'fixed',
              borderRight: 0,
              left: 0,
              top: 0,
              bottom: 0,
            }}
            width={200}
            className="shadow site-layout-background"
          >
            <Menu theme="dark" style={{justifyContent: 'flex-end'}} mode='vertical'>
              <Menu.Item style={{height: 'fit-content'}} key="profile">
                <ProfilePop userParams={user} />
              </Menu.Item>
              <Menu.Item className='text-white' key="EditProfile" onClick={() => {showModal()}}>Edit Profile</Menu.Item>
              <Menu.Item className='text-white' key="logout" onClick={e => {localStorage.removeItem('user'); localStorage.removeItem('token'); dispatch(logoutAuth());dispatch(userAuth()); return <Redirect to="/admin/login"/>}}>Logout</Menu.Item>
              <Menu.Item className='text-white' key="PreviewWebsite"><Link target={'_blank'} to={"/"} className="btn btn-primary" style={{textDecorationLine: 'none'}}>Preview Website</Link></Menu.Item>
              { isModalVisible &&
                <EditProfile handleCancel={handleCancel} payload={user} handleOk={handleOk} isModalVisible={isModalVisible}/>
              }

            </Menu>
            <Menu
            mode="inline"
            defaultSelectedKeys={[pageName]}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%', borderRight: 0 }}
            >
              <SubMenu key="homeSetting" icon={<HomeOutlined />} title="Home">
                <Menu.Item key="homeMain"><Link {...noDecor} to="home">Main</Link></Menu.Item>
                <Menu.Item key="partners"><Link {...noDecor} to="partners">Partner Images</Link></Menu.Item>
                <Menu.Item key="testimonials"><Link {...noDecor} to="testimonials">Testimonials</Link></Menu.Item>
              </SubMenu>
              <SubMenu key="aboutSetting" title="About">
                <Menu.Item key="aboutMain"><Link {...noDecor} to="about">Main</Link></Menu.Item>
                <Menu.Item key="aboutTeamSection"><Link {...noDecor} to="aboutTeamSection">Teams Section</Link></Menu.Item>
              </SubMenu>
              <Menu.Item key="teams"><Link {...noDecor} to="teams">Teams</Link></Menu.Item>
              <Menu.Item key="mentors"><Link {...noDecor} to="mentors">Mentors</Link></Menu.Item>
              <Menu.Item key="trainers"><Link {...noDecor} to="trainers">Trainers</Link></Menu.Item>
              <Menu.Item key="startups"><Link {...noDecor} to="startups">Startups</Link></Menu.Item>
              <Menu.Item key="programs"><Link {...noDecor} to="programs">Programs</Link></Menu.Item>
              <Menu.Item key="blogs"><Link {...noDecor} to="blogs">Blogs</Link></Menu.Item>
              <Menu.Item key="gallery"><Link {...noDecor} to="gallery">Gallery</Link></Menu.Item>
              <Menu.Item key="takeActions"><Link {...noDecor} to="takeActions">Take Actions</Link></Menu.Item>
            <Menu.Item key="footer"><Link {...noDecor} to="footer">Footer</Link></Menu.Item>
              <Menu.Item key="partial"><Link {...noDecor} to="partial">Branding</Link></Menu.Item>
            </Menu>
          </Sider>
          :
          ''
        }
        <Layout className='site-layout' style={{ marginLeft: (user?.name) ? 200 : 0, padding: '0' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: '100vh',
            }}
          >
            <Component {...rest} />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
const mapStateToProps = state => {
  return ({
  user: state.auth.user,
})};

export default connect(mapStateToProps,null)(AdminLayout);