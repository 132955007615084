import React, { useState } from 'react';
import { Card,Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteTestimonials } from '../../../actions/testimonialActions';
import AddEditTestimonial from './AddEditTestimonial';
import { Link, useParams } from 'react-router-dom';
import { VisibilityOutlined } from '@mui/icons-material';
import { getFileLink } from '../../LibraryMethods';
import { mainColors } from '../../../values/colors';

const { Meta } = Card;

const TestimonialCard = ({testimonial, startups}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (action) => {
    setAction(action);
    setIsModalVisible(true);
  };

  const handleCancelConfirm = () => {
    setIsConfirmDeleteVisible(false)
  }
  const handleOkConfirm = () => {
    dispatch(deleteTestimonials({code:testimonial.code}))
    setIsConfirmDeleteVisible(false);
  }
  const showModalConfirm = () => {
    setIsConfirmDeleteVisible(true);
  };

return(
  <>
    <Card
      className='shadow'
      style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
      cover={
        <>
          <div style={{backgroundColor: mainColors.light,width: '100%', maxHeight: 400}} className='fillImg' >
            <img
              src={`${getFileLink(testimonial?.logo)}`}
              alt="avatar"
            />
          </div>
          <hr/>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Name: </b></span><span style={{fontSize: '19px'}} className='col'>{testimonial.name}</span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Position: </b></span><span style={{fontSize: '19px'}} className='col'>{testimonial.position}</span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description: </b></span><span style={{fontSize: '19px'}} className='col p-elipsis'>{testimonial.description?.substring(0,100)}</span></div>
          <hr/>
          <div className='text-start col-12'>
            <span style={{fontSize: '22px'}} className='col'><b>Startup: </b></span>
            <span style={{fontSize: '19px'}} className='col p-wrap'>
              <span className='fillImg' style={{height:'138px',width:'inherit'}}>
                {testimonial.avatar ? <img src={`${process.env.REACT_APP_API_URL}/${testimonial.avatar}`} />  : ''}
              </span>
            </span>
          </div>
          <Modal title="Delete Image" visible={isConfirmDeleteVisible} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
            <p>Are you sure?</p>
          </Modal>
        </>
      
      }
      actions={[
        <DeleteOutlined onClick={()=> { showModalConfirm() }} key={"delete" + testimonial.id} />,
        <EditOutlined onClick={()=>showModal('Edit')} key={"edit" + testimonial.id} />,
        // <Link to={ "/testimonials/" + testimonial.code}><VisibilityOutlined onClick={()=>showModal('Edit')} key={"edit" + testimonial.id} /></Link>,
      ]}
    >
    </Card>
    { isModalVisible &&
    <AddEditTestimonial action={action} payload={testimonial} startups={startups} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
    }
  </>
)};

export default TestimonialCard;
