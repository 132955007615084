import React, { useEffect, useState } from 'react';
import { Link} from 'react-router-dom'
import { Button } from 'antd';
import { RightOutlined} from '@ant-design/icons';
import { btn } from '../values/styles';
import { mainColors } from '../values/colors';

const TkwnButton = ({toUrl,color, text}) => {
  const [isLgSc, setisLgSc] = useState(window.innerWidth > 600)
  const [toLink ,setToLink] = useState("");
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
    setToLink(toUrl)
  }, [toUrl, isLgSc])
  return (
    <>
      <a
        className="cdtm-btn-white-outline d-flex align-items-center justify-content-between p-0 col"
        style={{height: '73px',...btn.btnDarkLight, backgroundColor: mainColors.white,color: mainColors.dark, fontFamily: 'Jost' , fontSize: isLgSc ? '28px': '22px'}}
        href={toLink}
        target={'_blank'}
        rel="noreferrer"
      >
        <span className='text-start px-4 d-flex align-items-centercol-9'>{text}</span>
        <span className='cstm-btn-red-on-blue d-flex align-items-center col-3' style={{backgroundColor: color, height:'100%'}}>
          <RightOutlined className='col p-0 ' style={{fontSize: isLgSc ? '28px': '22px' ,color: (color === 'white' || color === mainColors.white) ? 'black' : 'white'}}/>
        </span>
      </a>
    </>
  );
};

export default TkwnButton;