import React, { useState,useRef,useEffect } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchTeams } from '../actions/teamActions';
import {Container} from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { DividerSvg } from '../components/DividerSvg'
import Box from '@mui/material/Box';
import { mainColors } from '../values/colors';
import {btn,countingNumStyle} from '../values/styles'
import SocialMedias from '../components/SocialMedias'
import { CaretDownFilled, RightOutlined } from '@ant-design/icons'
import MenuItem from 'antd/lib/menu/MenuItem';
import { Menu, Dropdown, Card } from 'antd';
import { Row, Col, Button } from 'antd';
import TkwnButton from '../components/linkButtonLong'
import { fetchPartials } from '../actions/partialActions';
import TakeActionSection from '../components/Teams/TakeActionSection';
import { fetchTakeActions } from '../actions/takeActionActions';
import { getFileLink } from '../components/LibraryMethods';
const { Meta } = Card;
const isLgSc = window.innerWidth > 600
const {SubMenu} = Menu
const Team = (...rest) => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTeams());
    dispatch(fetchPartials());
    dispatch(fetchTakeActions());
  },[dispatch])
  
  let teams = useSelector(state => state.team.teams);
  let partials = useSelector(state => state.partial.partials);
  let takeActions = useSelector(state => state.takeAction.takeActions);

  //.find(e => {return e.code === currentCode})
  const teamLogo = getFileLink(partials?.teamLogo)
  const allProgramsCarsl = teams.map(e => {
    return {code: e.code,name: e.name, logo: getFileLink(e.logo), refLink: e.refLink, position: e.position}
  })
  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 'auto',
          height: '400px',
          backgroundImage: `URL(${teamLogo})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        className='d-flex  justify-items-center align-items-center '
      >
        <div
        style={{
          height: '100%',
          width:'100%',
        }}
        className='py-auto container justifyitems-center align-items-center d-flex '
        >
        </div>
      </div>
      <div className='col' style={{position:'relative'}}>
        <div className="d-flex justify-content-start align-items-center" style={{position:'relative'}}>
          <span className={(isLgSc ? 'justify-content-center ' : 'px-1 justify-content-start ') + " d-flex align-items-center"} style={{background: 'linear-gradient(to right, '+mainColors.red+', transparent)',position:'relative'}}>
            <div className={(isLgSc ? "" : "px-3 " ) +'text-start'} style={{position:'absolute', fontSize: '40px',fontFamily: 'JostBoldItalic', color: mainColors.white}}>Meet The Team</div>
            <DividerSvg type="red" />
          </span>
        </div>
      </div>
      <Container className='py-5'>
        <Row
          className='d-flex justify-content-center mb-5'
          gutter={[40, 40]}
        >
          {allProgramsCarsl.map((ee,indx) => {
            return (
              <Col
                span={isLgSc ? 8 : 24}
                key={indx}
                className="d-flex justify-content-center"
              >
                <a
                  style={{textDecoration: 'none', width: 252}}
                  href={ee.refLink}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Card
                    style={{backgroundColor: mainColors.blue,border: '2px solid #015076', borderColor:'#015076', width: 252}}
                    className='shadow'
                    cover={<span style={{height: 250, width:'250px'}} className="bg-white fillImg"><img alt="example" src={ee.logo} /></span>}
                  >
                    <Meta title={
                      <>
                        <h5 className="d-flex justify-content-start" style={{color:mainColors.white, fontFamily:'Jost'}} > {ee.name?.toUpperCase()}</h5>
                        <div style={{color: mainColors.white}} className='text-start'>{ee.position}</div>
                      </>
                    }>
                    </Meta>
                  </Card>
                </a>
              </Col>
            )
          })}
        </Row>
        <TakeActionSection takeActions={takeActions} />
      </Container>
    </>
  );
}
const mapStateToProps = state => {
  return ({
    teams: state.team.teams,
    partials: state.partial.partials,
    takeActions: state.takeAction.takeActions,
  })
};
export default connect(mapStateToProps,null)(Team);