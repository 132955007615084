import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { editPartial, fetchPartials } from '../../../actions/partialActions';
import { useParams } from 'react-router-dom';
import { Divider, Upload, message, Row, Col, Modal, Input } from 'antd';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { LoadingOutlined,PlusOutlined } from '@ant-design/icons';

const AddEditPartial = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  const [brandLogo, setbrandLogo] = useState(null)
  const [brandLogoRead, setbrandLogoRead] = useState(null)
  const [startupLogo, setstartupLogo] = useState(null)
  const [startupLogoRead, setstartupLogoRead] = useState(null)
  const [startupDescription, setstartupDescription] = useState('')
  let [loading, setloading] = useState(null);
  let dispatch = useDispatch();

  let {code} = useParams();
  
  useEffect(() => {
    async function runFunc() {
      await dispatch(fetchPartials());
    }
    runFunc()
    setstartupDescription(payload.startupDescription);
    setstartupLogoRead(getFileLink(payload.startupLogo))
    setbrandLogoRead(getFileLink(payload.brandLogo))
  },[dispatch,payload]);

  function validate() {
    let data = new FormData();
    data.append('startupDescription', startupDescription)
    if (brandLogo) data.append('brandLogo', brandLogo)
    if (startupLogo) data.append('startupLogo', startupLogo)
    data.append('code', payload.code)
    dispatch(editPartial(data, code));
    handleOk();
    message.success('Updated!')
  }

  const branduploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setbrandLogo(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setbrandLogoRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setbrandLogo(null);
        setloading(false)
      }
    },
  };

  const startupuploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setstartupLogo(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setstartupLogoRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setstartupLogo(null);
        setloading(false)
      }
    },
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );


  return (
    <>
      <Modal title={action+' Partial'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Divider>Navbar</Divider>

          <Col span={24}>
            Brand Logo
            <Upload
              {...branduploaderProps}
            >
              {brandLogoRead ? <img src={brandLogoRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Col>
          <Divider>Startups Page</Divider>

          <Col span={24}>
            <Upload
              {...startupuploaderProps}
            >
              {startupLogoRead ? <img src={startupLogoRead} alt="Cover" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
            <Col lg={24} md={24} sm={24} xs={24} className=''>Description on Cover <Input.TextArea {...textSizeAreaOptions()} value={startupDescription} onChange={(evt) => {setstartupDescription(evt.target.value);}} /></Col>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  partials: state.partial.partials,
});

export default connect(mapStateToProps, null)(AddEditPartial);