import { FETCH_HOMES,FETCH_FOOTERS, FETCH_ALL_DASHBOARD } from '../actions/types';
const initialState = {
  homes: {},
  footers: {},
  homeDashboard: {
    homes: [],
    partners: [],
    abouts: {aboutImages: []},
    programs: [],
    startups: [],
    lastGallery: [],
    recentBlogs: [],
    takeActions: {},
    testimonials: [],
    partials: [],
  }
};

export const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOMES:
      return {
        ...state,
        homes: action.payload.homes,
      };
    case FETCH_FOOTERS:
      return {
        ...state,
        footers: action.payload.footers,
      };
    case FETCH_ALL_DASHBOARD:
      return {
        ...state,
        homeDashboard: action.payload.homeDashboard,
      };
    default:
    return {...state};
  }
}