import React, { useEffect } from 'react';
import {Instagram, LinkedIn, Twitter, Facebook} from '@mui/icons-material'
import { mainColors } from '../values/colors';
import { Link} from 'react-router-dom'

const SocialMediaComp = ({socialMedias}) => {
  const socialMediasReady = socialMedias && {
    instagramLink: socialMedias?.instagramLink,
    facebookLink: socialMedias?.facebookLink,
    linkedinLink: socialMedias?.linkedinLink,
    twitterLink: socialMedias?.twitterLink,
  }

  let fbLink = null
  let instLink = null
  let twitLink = null
  let lnkLink = null
  if (socialMediasReady) {
    if (socialMediasReady.instagramLink) {
      instLink = socialMediasReady.instagramLink
    }
    if (socialMediasReady.facebookLink) {
      fbLink = socialMediasReady.facebookLink
    }
    if (socialMediasReady.linkedinLink) {
      lnkLink = socialMediasReady.linkedinLink
    }
    if (socialMediasReady.twitterLink) {
      twitLink = socialMediasReady.twitterLink
    }
  }
  return (
    <>
    {fbLink && <a className="btn-social-media mx-2" rel="noreferrer" target={'_blank'} href={fbLink}><Facebook style={{color: mainColors.white, fontSize: '30px'}}/></a>}
    {instLink && <a className="btn-social-media mx-2" rel="noreferrer" target={'_blank'} href={instLink}><Instagram style={{color: mainColors.white, fontSize: '30px'}}/></a>}
    {twitLink && <a className="btn-social-media mx-2" rel="noreferrer" target={'_blank'} href={twitLink}><Twitter style={{color: mainColors.white, fontSize: '30px'}}/></a>}
    {lnkLink && <a className="btn-social-media mx-2" rel="noreferrer" target={'_blank'} href={lnkLink}><LinkedIn style={{color: mainColors.white, fontSize: '30px'}}/></a>}
    </>
  );
};
 export default SocialMediaComp;
