import { FETCH_PARTNERS } from '../actions/types';
const initialState = {
  partners: []
};

export const PartnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTNERS:
      return {
        ...state,
        partners: action.payload.partners,
      };
    default:
      return {...state};
  }
}