export function groupArr(data, n) {
  var group = [];
  for (var i = 0, j = 0; i < data.length; i++) {
      if (i >= n && i % n === 0)
          j++;
      group[j] = group[j] || [];
      group[j].push(data[i])
  }
  return group;
}

export function parseCatName(category) {
  if (category === 'new') return 'News'
  if (category === 'story') return 'Story'
  if (category === 'program') return 'Program Updates'
  if (category === 'event') return 'Event'
  return category
}

export const blogCategoryOptions = [
  {id: 1, value: 'story', name: 'Stories'},
  {id: 2, value: 'program', name: 'Program Updates'},
  {id: 3, value: 'new', name: 'News'},
  {id: 4, value: 'event', name: 'Events'},
]

export const textSizeAreaOptions = (minmaxrow) => {
  if (Array.isArray(minmaxrow) && minmaxrow.length > 1) {
    return {
      autoSize: { minRows: minmaxrow[0], maxRows: minmaxrow[1] }
    }
  } else {
    return {
      autoSize: { minRows: 2, maxRows: 6 }
    }
  }
}

export function getBase64(img, callback) {
  // console.log("file: ", img)
  if (img) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  } else callback(null)
}

export const isJpgOrPng = (type) => {
  return (type === 'image/jpeg' || type === 'image/jpg' || type === 'image/JPEG' || type === 'image/JPG' || type === 'image/png')
}

export const checkSize = (content, allowed) => ( Number(content) / 1024 / 1024 < allowed)

export const getFileLink = (uploadsSlashKeyDotFileType) => {
  if (typeof uploadsSlashKeyDotFileType === 'string' && uploadsSlashKeyDotFileType !== '') {
    // console.log("uploadsSlashKeyDotFileType: " , uploadsSlashKeyDotFileType, " ------- ",uploadsSlashKeyDotFileType.includes(process.env.REACT_APP_API_URL))
    if (uploadsSlashKeyDotFileType.includes(process.env.REACT_APP_API_URL)) return uploadsSlashKeyDotFileType
    let middle = "/uploads/"
    if (uploadsSlashKeyDotFileType.includes('uploads')) {
      middle = "/"
    }
    return `${process.env.REACT_APP_API_URL}${middle}${uploadsSlashKeyDotFileType}`
  }
  // return 'https://icon-library.com/images/1041139-200_42853.png'
  return 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fvignette.wikia.nocookie.net%2Flogopedia%2Fimages%2F5%2F59%2FEmpty.png%2Frevision%2Flatest%3Fcb%3D20100412122939&f=1&nofb=1'
}