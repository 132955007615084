import React, { useState,useRef,useEffect } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchMentors } from '../actions/teamActions';
import {Container} from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { DividerSvg } from '../components/DividerSvg'
import Box from '@mui/material/Box';
import { mainColors } from '../values/colors';
import {btn,countingNumStyle} from '../values/styles'
import SocialMedias from '../components/SocialMedias'
import { CaretDownFilled, RightOutlined } from '@ant-design/icons'
import MenuItem from 'antd/lib/menu/MenuItem';
import { Menu, Dropdown, Card } from 'antd';
import { Row, Col, Button } from 'antd';
import { fetchTakeActions } from '../actions/takeActionActions';
import TakeActionSection from '../components/Mentors/TakeActionSection';
import { getFileLink } from '../components/LibraryMethods';
const { Meta } = Card;
const isLgSc = window.innerWidth > 600
const {SubMenu} = Menu
const Mentor = (...rest) => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMentors());
    dispatch(fetchTakeActions());
  },[dispatch])
  
  let mentors = useSelector(state => state.mentor.mentors);
  let takeActions = useSelector(state => state.takeAction.takeActions);

  //.find(e => {return e.code === currentCode})
  const logoAddress = (Array.isArray(mentors) && mentors[0]?.logo) ? getFileLink(mentors[0]?.logo) : null
  const allProgramsCarsl = mentors.map(e => {
    return {code: e.code,name: e.name, logo: getFileLink(e.logo), refLink: e.refLink, description: e.description}
  })
  return (
    <div  className='pt-5' style={{background: 'linear-gradient(to right, ' + mainColors.light + ' 50%, white 50%)'}}>
      <Container className='col-12 pt-5 px-0 d-flex flex-wrap justify-content-end'>
        <div className={isLgSc ? 'col-6 text-end' : 'text-start'} style={{fontSize: '41px',color: mainColors.blue, fontFamily: 'JostBoldItalic', position: 'relative'}}>OUR MENTORS</div>
      </Container>
      <div style={{transform: "translate(30px, 0%)"}} className='d-flex align-items-center flex-wrap justify-content-end'>
        <DividerSvg  />
      </div>
      <Container className='py-5'>
        <Row
          className='d-flex justify-content-center'
          gutter={[40, 40]}
        >
          {allProgramsCarsl.map((ee,indx) => {
            return (
              <Col
                className='justify-content-center d-flex'
                span={isLgSc ? 8 : 24}
                key={indx}
              >
                <a
                  style={{textDecoration: 'none'}}
                  href={ee.refLink}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Card
                    style={{backgroundColor: mainColors.blue,width:251}}
                    className=''
                    hoverable
                    cover={<span style={{height: 250, width: 250}} className="bg-white fillImg"><img alt="example" src={ee.logo} /></span>}
                  >
                    <Meta title={<h5 className="d-flex justify-content-start" style={{color:mainColors.white, fontFamily:'Jost'}} > {ee.name?.toUpperCase()}</h5>}
                      description={
                      <>
                        <div
                          to={ '/mentors'}
                          className="d-flex justify-content-between align-items-center"
                          style={{fontFamily: 'Jost',color:mainColors.white}}
                        >
                          <div style={{color: mainColors.white}} className='text-start'>{ee.position}</div>
                        </div>
                      </>
                      }>
                    </Meta>
                  </Card>
                </a>
              </Col>
            )
          })}
        </Row>
      </Container>
        <TakeActionSection takeActions={takeActions} />
    </div>
  );
}
const mapStateToProps = state => {
  return ({
    mentors: state.team.mentors,
    takeActions: state.takeAction.takeActions,
  })
};
export default connect(mapStateToProps,null)(Mentor);