import { Upload, Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Modal, Input,Row,Col, InputNumber } from 'antd';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { addTestimonial, editTestimonial, fetchTestimonials } from '../../../actions/testimonialActions';
import { useParams } from 'react-router-dom';
import { Select } from 'antd';
import { checkSize, getFileLink,getBase64, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';

const { Option, OptGroup } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const AddEditTestimonial = ({isModalVisible, handleOk, handleCancel, action,startups, payload}) => {
  let [name, setName] = useState('');
  let [position, setPosition] = useState('');
  let [description, setDescription] = useState('');
  let [startupCode, setstartupCode] = useState(null);
  let [file, setFile] = useState(null);
  // neccesary
  const [fileRead, setFileRead] = useState(null)
  const [loading, setloading] = useState(false)
  let dispatch = useDispatch();

  let {code} = useParams();

  useEffect(async () => {
    await dispatch(fetchTestimonials());
    if(action === 'Edit') {
      setName(payload.name);
      setstartupCode(payload.startupCode)
      setPosition(payload.position);
      setloading(true)
      setFileRead(getFileLink(payload.logo))
      setloading(false)
      setDescription(payload.description);
    }
  },[dispatch]);

  function validate() {
    if(!name){
      message.error('Please provide name!');
      return
    }
    let data = new FormData();
    data.append('name', name);
    data.append('description', description);
    if (startupCode) data.append('startupCode', startupCode);
    data.append('position', position);
    if(file) {
      data.append('logo', file);
    }
    if(action === 'Add'){
      dispatch(addTestimonial(data, code));
    }else{
      console.log(file)
      data.append('code', payload.code)
      dispatch(editTestimonial(data, code));
    }

    handleOk();
  }

  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFile(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFileRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFile(null);
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal title={action+' Testimonial'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Col span={24}>
            <Upload
              {...uploaderProps}
            >
              {fileRead ? <img src={fileRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Col>
        </Row>
        <Col lg={24} md={24} sm={24} xs={24} className=''>
          Choose a Startup:
          <Select autoFocus value={startupCode} defaultValue={startupCode} placeholder="Choose a Startup" className="col-12 my-3" onChange={strtp => setstartupCode(strtp)}>
            {startups?.map(e => {
              return (
                <Option key={"ddecevce" + e.code} value={e.code}>{e.name}</Option>
              )
            })}
          </Select>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className=''>Name <Input className='my-3' value={name} onChange={(evt) => {setName(evt.target.value);}} placeholder="Name" /></Col>
        <Col lg={24} md={24} sm={24} xs={24} className=''>Position <Input className='my-3' value={position} onChange={(evt) => {setPosition(evt.target.value);}} placeholder="Position" /></Col>
        <Col lg={24} md={24} sm={24} xs={24} className=''>Description <Input.TextArea {...textSizeAreaOptions()} className='my-3' value={description} onChange={(evt) => {setDescription(evt.target.value);}} /></Col>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  testimonials: state.testimonial.testimonials,
});

export default connect(mapStateToProps, null)(AddEditTestimonial);