import { FETCH_BLOGS, FETCH_LAST_BLOGS } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = "blogs"

export const fetchBlogs = () => async dispatch => {
  try {
    let firstResponse = []
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}`);
    console.log(response)
    if (response.data.data) {
      firstResponse = response.data.data
    }
    dispatch({
      type: FETCH_BLOGS,
      payload: {
        blogs: firstResponse.sort((a,b) => {return b["id"] - a["id"] })
      }
    })
  } catch(err) {
    console.log(err);
  }
};

export const fetchLastBlogs = () => async dispatch => {
  try {
    let firstResponse = []
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/lastBlogs`);
    console.log("last blogs in actions", response)
    dispatch({
      type: FETCH_LAST_BLOGS,
      payload: {
        lastBlogs: response.data.data.sort((a,b) => {return b["id"] - a["id"] })
      }
    })
  } catch(err) {
    console.log(err);
  }
}

export const addBlog = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/create`,payload)
    dispatch(fetchBlogs());
  }catch(err) {
    console.log(err)
  }
};

export const editBlog = ( payload, code ) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/update`,payload)
      dispatch(fetchBlogs());
  }catch(err) {
      console.log(err)
    }
};

export const deleteBlogs = (payload, id) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/delete`, payload);
      dispatch(fetchBlogs(id));
  }catch(err) {
    }
};