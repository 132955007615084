import React, { useState, useEffect } from 'react';
import { useDispatch, connect, useSelector } from "react-redux";
import { fetchHomesDashboard } from '../actions/homeActions';
import ProgramSection from '../components/home/ProgramSection'
import StartupSection from '../components/home/StartupSection'
import PartnerSection from '../components/home/PartnerSection'
import LastBlogsSection from '../components/home/LastBlogsSection'
import {Container} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { DividerSvg } from '../components/DividerSvg'
import { mainColors } from '../values/colors';
import {btn,countingNumStyle} from '../values/styles'
import TakeActionSection from '../components/home/TakeActionSection';
import LastGallerySection from '../components/home/LastGallerySection';
import TestimonialsSection from '../components/home/TestimonialSection';
import { getFileLink } from '../components/LibraryMethods';
const Home = (...rest) => {
  const [isLgSc, setisLgSc] = useState(true)
  const [isSmSc, setisSmSc] = useState(true)
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
    setisSmSc(window.innerWidth < 601)
  },[isLgSc])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHomesDashboard())
  },[dispatch])
  const homeDashboard = useSelector(state => state.home.homeDashboard);
  const homes = homeDashboard?.homes;
  const partners = homeDashboard?.partners;
  const abouts = homeDashboard?.abouts[0];
  const programs = homeDashboard?.programs;
  const startups = homeDashboard?.startups?.filter(e => {return e['status'] === 'featured'});
  const lastGallery = homeDashboard?.lastGallery;
  const recentBlogs = homeDashboard?.recentBlogs;
  const takeActions = homeDashboard?.takeActions;
  const testimonials = homeDashboard?.testimonials;
  const logoAddress = getFileLink(homes?.logo)
  console.log("hpmepage, blogs recent: ", recentBlogs)
  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
      }}
      key="homepagemaindiv1244"
    >
      <div
        style={{
          width: '100%',
          padding: 'auto',
          height: '650px',
          backgroundImage: `URL(${logoAddress})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        className='d-flex justify-items-center align-items-center '
      >
        <div
          style={{
            height: '100%',
            width:'100%',
          }}
          className='py-auto container justifyitems-start align-items-center d-flex '
        >
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-left" style={{
            display: 'flex',
            alignContent: 'flex-start',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <h3 className="text-start" style={{color:mainColors.white}}>{homes?.description}</h3>
            <h1 className="text-start" style={{fontSize: '70px',color:mainColors.white, }}><b>{homes?.name}</b></h1>
            <br/>
            <a
              style={{...(btn.btnWhiteLight),fontSize:'25px'}}
              target={'_blank'}
              className="cstm-btn-red-outline py-1 px-5"
              href={homes?.applyLink}
              type="primary"
              rel="noreferrer"
            >
              Apply Now
            </a>
          </div>
        </div>
      </div>
      <div className='col-12 d-flex justify-content-start'>
        <div style={{transform:'translate(-50px, -50%)'}}>
          <DividerSvg type="red" />
        </div>
      </div>
      <div className='py-5 container'>
        <h1
          style={{
            fontFamily: 'JostBoldItalic',
            color: mainColors.blue,
            fontStyle: 'italic',
            fontWeight: 'bold',
          }}
        >
          <b>{abouts?.name}</b>
        </h1>
        <h5
          className='p-wrap'
          style={{
            color: mainColors.blue,
          }}
        >
          {abouts?.description}
        </h5>
        <h5 className='py-5'>
          <Link
            style={{...(btn.btnRedLight),fontSize:'25px', padding: '14px 36px'}}
            className="cstm-btn-red-outline"
            to="/about"
            type="primary"
          >
            ABOUT TAKWEEN
          </Link>
        </h5>
      </div>
      <Container fluid={true}
        style={{backgroundColor: mainColors.red}}
        className={' py-5 justify-content-center d-flex flex-wrap col-12 '}
      >
        <h1
          className={(isSmSc ? 'col-6' : '') + ' px-4 text-center'}
          style={{
            color: mainColors.white,
          }}
        >
          <div
            style={{ fontSize: '2.4em',fontFamily:'JostItalic'}}
            className='d-flex justify-content-center'
          >
          {homes?.startupsNum ? homes?.startupsNum : 0}
          <span style={countingNumStyle}>
            +</span>

          </div>
            <div
              style={{
                fontFamily: 'Jost',
                fontSize: '20px',
                color: mainColors.white,
              }}
            >Startups Trained</div>
        </h1>
        {isLgSc ? 
          <span style={{
            margin: '10px 0',
            borderRight: `2px solid ${mainColors.white}`,
          }}></span> : <></>
        }
        <h1
          className={(isSmSc ? 'col-6' : '') + ' px-4 text-center'}
          style={{
            color: mainColors.white,
          }}
        >
          <div
            style={{ fontSize: '2.4em',fontFamily:'JostItalic'}}
            className='d-flex justify-content-center'
          >
          {homes?.trainingHoursNum ? homes?.trainingHoursNum : 0}
          <span style={countingNumStyle}>
            +</span>

          </div>
            <div
              style={{
                fontFamily: 'Jost',
                fontSize: '20px',
                color: mainColors.white,
              }}
            >Hours of Training</div>
        </h1>
        {isLgSc ? <span style={{
            margin: '10px 0',
            borderRight: `2px solid ${mainColors.white}`,
          }}></span> : <></>        
        }
        <h1
          className={(isSmSc ? 'col-6' : '') + ' px-4 text-center'}
          style={{
            color: mainColors.white,
          }}
        >
          <div
            style={{ fontSize: '2.4em',fontFamily:'JostItalic'}}
            className='d-flex justify-content-center'
          >
          {homes?.mentorsNum ? homes?.mentorsNum : 0}
          <span style={countingNumStyle}>
            +</span>
          </div>
            <div
              style={{
                fontFamily: 'Jost',
                fontSize: '20px',
                color: mainColors.white,
              }}
            >Mentors</div>
        </h1>
        {isLgSc ? <span style={{
            margin: '10px 0',
            borderRight: `2px solid ${mainColors.white}`,
          }}></span> : <></>
        }

        <h1
          className={(isSmSc ? 'col-6' : '') + ' px-4 text-center'}
          style={{
            color: mainColors.white,
          }}
        >
          <div
            style={{ fontSize: '2.4em',fontFamily:'JostItalic'}}
            className='d-flex justify-content-center'
          >
          {homes?.cohortsNum ? homes?.cohortsNum : 0}

          </div>
            <div
              style={{
                fontFamily: 'Jost',
                fontSize: '20px',
                color: mainColors.white,
              }}
            >Cohorts Completed</div>
        </h1>
      </Container>
      <Container className='py-5'>
        {Array.isArray(programs) && <ProgramSection programs={programs}/>}
      </Container>
      <Container fluid className='pt-5 px-0'>
        {Array.isArray(startups) && <StartupSection title={'smallSquare'} startups={startups}/>}
      </Container>
      <Container fluid className='px-0' style={{backgroundColor:mainColors.red}}>
        <PartnerSection partners={partners} ourPartnerName={homes?.ourPartnerName} ourPartnerDescription={homes?.ourPartnerDescription} />
      </Container>
      {(Array.isArray(recentBlogs) && recentBlogs.length) ? (<Container className={isLgSc? "col-13 d-flex justify-content-center my-5 py-5 container" : "col-13 justify-content-center my-5 py-5 container"} style={{backgroundColor:mainColors.white}}>
        {((Array.isArray(lastGallery) && lastGallery.length) || (Array.isArray(lastGallery) && lastGallery.length)) ? (<div className={isLgSc ? 'col-6' : "col-12" } style={(isLgSc) ? {borderRight: '1px solid ' + mainColors.blue} : {}}>
          <div className={(isLgSc ? 'px-5' : 'px-2') + ' text-start'} style={{fontSize: '40px', fontFamily: 'JostBoldItalic', color: mainColors.blue}}>Gallery</div>
          {(Array.isArray(lastGallery) && lastGallery.length) ? <LastGallerySection lastGallery={lastGallery}/> : <></>}
        </div>) : (<></>)}
        {(Array.isArray(recentBlogs) && recentBlogs.length) ? (<div className={isLgSc ? 'col-6 px-5' : "col-12 px-2"}>
          <div className='text-start' style={{fontSize: '40px', fontFamily: 'JostBoldItalic', color: mainColors.blue}}>
            <span className='pe-3'>Blogs</span>
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="194.677" height="35" viewBox="0 0 194.677 35">
                <g id="Group_198" transform="translate(0 2.5)">
                  <path id="Path_85" d="M194.677.056c-2.5,9.507-4.813,18.562-7.311,27.557a3.5,3.5,0,0,1-2.738,1.775c-8.724.15-175.362.09-184.628.09.391-1.715.662-3.159,1.023-4.573C2.888,17.6,4.814,10.315,6.679,3,7.1,1.319,7.431-.065,9.838,0,18.562.146,185.23.056,194.677.056Z" transform="translate(0 0.006)" fill="#c81436"/>
                </g>
                <text id="Featured_News" transform="translate(12.5 26)" fill="#fff" fontSize="24" fontFamily="Jost-BoldItalic, Jost" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">Most Recent</tspan></text>
              </svg>
            </span>
          </div>
          <LastBlogsSection recentBlogs={recentBlogs} />
        </div>) : (<></>)}
      </Container>) : (<></>)}
      <div className={(isLgSc ? ' py-5 ': '' ) + ' col-12 d-flex justify-content-start my-5'}>
        <div style={{transform:'translate(-35px, 0px)'}}>
          <DividerSvg type="red" />
        </div>
      </div>
      <Container fluid className='py-5 p-0' style={{backgroundColor:mainColors.white}}>
        <TakeActionSection takeActions={takeActions} />
      </Container>
      <Container fluid className='px-0' style={{minHeight: isLgSc ? "500px" : "fit-content",backgroundColor: mainColors.blue}}>
        <TestimonialsSection testimonials={testimonials} />
      </Container>
    </div>
  );
}
const mapStateToProps = state => {
  return ({
    homeDashboard: state.home.homeDashboard,
  })
};
export default connect(mapStateToProps,null)(Home);