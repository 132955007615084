import { Link} from 'react-router-dom'
import { Row, Col, Button } from 'antd';
import {mainColors} from '../../values/colors'
import {btn} from '../../values/styles'
import {Container} from 'react-bootstrap'
import TkwnButton from '../linkButtonLong'
import { DividerSvg } from '../DividerSvg';
import TakeACtionHomePart from './TakeACtionHomePart'

const TakeActionSection = ({takeActions}) => {
  return (
    <>
      <Container className='my-5 d-flex flex-wrap justify-content-start align-content-center text-start flex-wrap' style={{height: "614px"}}>
        <div className="col-12 p-0"><TkwnButton text={'Become a Mentor'} color={mainColors.red} toUrl={takeActions.mentorLink} /></div>
      </Container>
      <TakeACtionHomePart takeActions={takeActions} />
    </>
  );
};

export default TakeActionSection;