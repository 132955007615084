import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { addProgram, editProgram, fetchPrograms } from '../../../actions/programActions';
import { Upload, Button, message, InputNumber, DatePicker, Row, Col, Modal, Input, Divider, Select } from 'antd';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import * as moment from 'moment';
import { mainColors } from '../../../values/colors';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const AddEditProgram = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  let [description, setDescription] = useState('');
  let [title1, setTitle1] = useState('');
  let [body1, setBody1] = useState('');
  let [title2, setTitle2] = useState('');
  let [body2, setBody2] = useState('');
  let [title3, setTitle3] = useState('');
  let [body3, setBody3] = useState('');
  let [name, setName] = useState('');
  let [duration, setDuration] = useState(null)
  let [durationType, setDurationType] = useState('Days')
  let [startDate, setStartDate] = useState(null)
  let [endDate, setEndDate] = useState(null)
  let [refLink, setRefLink] = useState('')
  const [file, setFile] = useState(null)
  // neccesary
  const [fileRead, setFileRead] = useState(null)
  const [loading, setloading] = useState(false)
  let dispatch = useDispatch();
  useEffect(async () => {
    await dispatch(fetchPrograms());
    if (action === 'Edit') {
      setDescription(payload.description)
      setTitle1(payload.title1)
      setBody1(payload.body1)
      setTitle2(payload.title2)
      setBody2(payload.body2)
      setTitle3(payload.title3)
      setBody3(payload.body3)
      setName(payload.name)
      setRefLink(payload.refLink)
      setFileRead(getFileLink(payload.logo));
      setStartDate(payload.startDate?.split('T')[0])
      setEndDate(payload.endDate?.split('T')[0])
      let durarr = []
      if (payload.duration) durarr = payload.duration?.split(' ')
      let durationTypeFnd
      const durtprd = durarr.pop()
      if (durtprd) durationTypeFnd = durtprd
      const durationFnd = durarr.join(' ')
      setDurationType(durationTypeFnd)
      setDuration(durationFnd)
    }
  },[dispatch]);

  function validate() {
    if(!name){
      message.error('Please provide name!');
      return
    }
    let data = new FormData();
    console.log("-------------payload: ", durationType, " duration: ",duration)
    data.append('description', description);
    data.append('title1', title1);
    data.append('body1', body1);
    data.append('title2', title2);
    data.append('body2', body2);
    data.append('title3', title3);
    data.append('body3', body3);
    data.append('name', name);
    if (durationType) data.append('duration', `${duration} ${durationType}`);
    if (startDate) data.append('startDate', startDate);
    if (endDate) data.append('endDate', endDate);
    data.append('refLink', refLink);
    if (file) data.append('logo', file);
    if(action === 'Add'){
      dispatch(addProgram(data));
    }else{
      console.log(file)
      data.append('code', payload.code)
      dispatch(editProgram(data));
    }
    message.success('updated!')
    handleOk();
  }

  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFile(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFileRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFile(null);
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal width={600} title={action+' Program'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Col span={24}>
            Program Cover: 
            <Upload
              {...uploaderProps}
            >
              {fileRead ? <img src={fileRead} alt="avatar" style={{ height: '128px' }} /> : uploadButton}
            </Upload>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Program Name" value={name} onChange={(evt) => {setName(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Program Description <Input.TextArea {...textSizeAreaOptions()} value={description} onChange={(evt) => {setDescription(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Duration <Input addonBefore={
            <Select onChange={(evt) => setDurationType(evt)} autoFocus defaultValue={durationType} value={durationType} className="select-before">
              <Select.Option value="Days">Days</Select.Option>
              <Select.Option value="Weeks">Weeks</Select.Option>
              <Select.Option value="Months">Months</Select.Option>
            </Select>
          } value={duration} onChange={(evt) => {setDuration(evt.target.value);}} />
          </Col>
          <hr/>
          <Divider>First Section</Divider>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Title" value={title1} onChange={(evt) => {setTitle1(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Body <ReactQuill
            theme='snow'
            value={body1}
            onChange={setBody1}
            style={{minHeight: '300px'}}
          /></Col>
          <Divider>Second Section</Divider>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Title" value={title2} onChange={(evt) => {setTitle2(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Body <ReactQuill
            theme='snow'
            value={body2}
            onChange={setBody2}
            style={{minHeight: '300px'}}
          /></Col>
          <Divider style={{color: mainColors.blue}}>Criteria Section</Divider>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Title" value={title3} onChange={(evt) => {setTitle3(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Body<ReactQuill
            theme='snow'
            value={body3}
            onChange={setBody3}
            style={{minHeight: '300px'}}
          /></Col>
          <Divider>Button Visibility Duration</Divider>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Apply Now - Link" value={refLink} onChange={(evt) => {setRefLink(evt.target.value);}} /></Col>
          <Col span={9}>Start Date ({startDate ? moment(startDate).format('YYYY-MM-DD').toString() :null }): </Col>
          <Col span={15}><DatePicker className='col-12' value={startDate && moment(startDate)} defaultPickerValue={startDate ? moment(startDate) : false} onChange={(evt,evtStr) => {setStartDate(evtStr);}} placeholder="Start Date" /></Col>
          <Col span={9}>Deadline ({endDate ? moment(endDate).format('YYYY-MM-DD').toString() :null }):
          <br/>
          (<small>At the end of this date the apply button will disappear</small>)
          </Col>
          <Col span={15}><DatePicker className='col-12' value={endDate && moment(endDate)} defaultPickerValue={endDate ? moment(endDate) : false} onChange={(evt,evtStr) => {setEndDate(evtStr);}} placeholder="End Date" /></Col>
        </Row>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  programs: state.program.programs,
});

export default connect(mapStateToProps, null)(AddEditProgram);