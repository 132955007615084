import React, { useState } from 'react';
import { Card,Row,Col,Input, Divider } from 'antd';
import { CloseSquareOutlined, EditOutlined, DeleteOutlined,AppstoreAddOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import AddEditAbout from './AddEditAbout';
import { Link, useParams } from 'react-router-dom';
import { AddOutlined, VisibilityOutlined } from '@mui/icons-material';
import AboutImageCard from './AboutImageCard';
import AddEditAboutImage from './AddEditAboutImage';
import { mainColors } from '../../../values/colors';
import { getFileLink } from '../../LibraryMethods';

const { Meta } = Card;

const AboutCard = ({about}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalImageVisible, setIsModalImageVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (action) => {
    setAction(action);
    setIsModalVisible(true);
  };

  const handleOkImage = () => {
    setIsModalImageVisible(false);
  };

  const handleCancelImage = () => {
    setIsModalImageVisible(false);
  };

  const showModalImage = () => {
    setIsModalImageVisible(true);
  };
  const currentAboutAttachments = about?.aboutImages
return(
  <>
    <Card
    className='shadow'
      style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
      cover={
        <div key={"aboutCard" + about.code}>
          <div style={{backgroundColor: mainColors.light,width: '100%', maxHeight: 400}} className='fillImg' >
            <img
              src={getFileLink(about?.logo)}
              alt="avatar"
            />
          </div>
          <hr/>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Title: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{about.name}</p></span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'><p className="p-wrap">{about.description}</p></span></div>
          <div className='text-start col-12' style={{fontSize: '22px'}}><b>Slide Images: </b></div>
          <Row gutter={[20,20]} className="p-2 col-12">
          {currentAboutAttachments && currentAboutAttachments.map(qwer => {
            return <Col key={qwer.code} span={6}>
              <AboutImageCard aboutImage={qwer}/>
            </Col>
          })}
            <Col
              key="addAboutImageButtonLastCol"
              span={6}
            >
              <div
                onClick={showModalImage}
                className='rounded shadow btn clickable hoverable d-flex justify-content-center align-items-center'
                style={{height: '150px'}}
              >
                <AddOutlined style={{fontSize:24}} onClick={showModalImage} key={"addImage" + about.id} />
              </div>
            </Col>
          </Row>
        </div>
      }
    >
    </Card>
    { isModalImageVisible &&
    <AddEditAboutImage action={'Add'} payload={{aboutCode: about.code}} handleCancel={handleCancelImage} handleOk={handleOkImage} isModalVisible={isModalImageVisible}  />
    }
    { isModalVisible &&
    <AddEditAbout action={action} payload={about} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
    }
  </>
)};

export default AboutCard;
