import { combineReducers } from 'redux';
import { ProgramReducer } from './programReducers';
import { StartupReducer } from './startupReducers';
import { HomeReducer } from './homeReducers';
import { AboutReducer } from './aboutReducers';
import { TeamReducer } from './teamReducers';
import { TrainerReducer } from './trainerReducers';
import { MentorReducer } from './mentorReducers';
import { PartnerReducer } from './partnerReducers';
import { FooterReducer } from './footerReducers';
import { BlogReducer } from './blogReducers';
import {GalleryReducer} from './galleryReducers'
import {PartialReducer} from './partialReducers'
import {TakeActionReducer} from './takeActionReducers'
import { AuthReducer } from './authReducers'
import { TestimonialReducer } from './testimonialReducers';

export default combineReducers({
  home: HomeReducer,
  about: AboutReducer,
  partial: PartialReducer,
  program: ProgramReducer,
  startup: StartupReducer,
  team: TeamReducer,
  trainer: TrainerReducer,
  mentor: MentorReducer,
  partner: PartnerReducer,
  footer: FooterReducer,
  blog: BlogReducer,
  gallery: GalleryReducer,
  takeAction: TakeActionReducer,
  testimonial: TestimonialReducer,
  auth: AuthReducer,
});