import React from 'react';
import { Card, Divider } from 'antd';
import { getFileLink } from '../../LibraryMethods';
import { mainColors } from '../../../values/colors';

const { Meta } = Card;

const PartialCard = ({partial}) => {
return(
  <>
    <Card
      className='rounded p-3 shadow'
      cover={
        <div className='col-12 text-start'>
          <h4>Cover</h4>
          <div style={{backgroundColor: mainColors.light,width: '100%', maxHeight: 400}} className='fillImg' >
            <img
              src={`${getFileLink(partial?.teamLogo)}`}
              alt="Team Logo"
            />
          </div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Name: </b></span><span style={{fontSize: '19px'}} className='col'>{partial.teamName}</span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'>{partial.teamDescription}</span></div>
        </div>
      }
    >
    </Card>
  </>
)};

export default PartialCard;
