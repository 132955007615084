import React, { useState } from 'react';
import { Card, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteTeams } from '../../../actions/teamActions';
import AddEditMentor from './AddEditMentor';
import { Link, useParams } from 'react-router-dom';
import { VisibilityOutlined } from '@mui/icons-material';
import { mainColors } from '../../../values/colors';

const { Meta } = Card;

const MentorCard = ({mentor}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const showModal = (action) => {
    setAction(action);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelConfirm = () => {
    setIsConfirmDeleteVisible(false)
  }
  const handleOkConfirm = () => {
    dispatch(deleteTeams({code:mentor.code}))
    setIsConfirmDeleteVisible(false);
  }
  const showModalConfirm = () => {
    setIsConfirmDeleteVisible(true);
  };

return(
  <>
  <Card
    style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
    cover={
      <>
        <div style={{backgroundColor: mainColors.light,width: '100%', height: 400}} className='fillImg' >
          <img
            src={mentor.logo ? `${process.env.REACT_APP_API_URL}/${mentor.logo}`  : 'https://icon-library.com/images/1041139-200_42853.png'}
            alt="avatar"
          />
        </div>
        <hr/>
        <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Name: </b></span><span style={{fontSize: '19px'}} className='col'>{mentor.name}</span></div>
        <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Position: </b></span><span style={{fontSize: '19px'}} className='col'>{mentor.position}</span></div>
        <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Portfolio Link: </b></span><span style={{fontSize: '19px'}} className='col'>{mentor.refLink}</span></div>

        <Modal title="Delete Image" visible={isConfirmDeleteVisible} onOk={handleOkConfirm} onCancel={handleCancelConfirm}>
          <p>Are you sure?</p>
        </Modal>
      </>
    }
    actions={[
      <DeleteOutlined onClick={()=> showModalConfirm()} key={"delete" + mentor.id} />,
      <EditOutlined onClick={()=>showModal('Edit')} key={"edit" + mentor.id} />,
      // <Link to={ "/mentors/" + mentor.code}><VisibilityOutlined onClick={()=>showModal('Edit')} key={"edit" + mentor.id} /></Link>,
    ]}
  />
  { isModalVisible &&
   <AddEditMentor action={action} payload={mentor} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
  }
  </>
)};

export default MentorCard;
