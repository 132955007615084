import { FETCH_ABOUTS } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = "abouts"

export const fetchAbouts = () => async dispatch => {
  try {
    let firstResponse = {}
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/`);
    let responseImages = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/Images`);
    console.log(response.data)
    if (response.data.data[0]) {
      firstResponse = response.data.data[0]
    }
    firstResponse['aboutImages'] = []
    if(responseImages.data.data) {
      firstResponse['aboutImages'] = responseImages.data.data
    }
    console.log("aboutActions: ",firstResponse)
    dispatch({
      type: FETCH_ABOUTS,
      payload: {
        abouts: firstResponse
      }
    })
  } catch(err) {
    console.log(err);
  }
};

export const editAbout = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/update`,payload)
    dispatch(fetchAbouts());
  }catch(err) {
    console.log(err)
  }
};

export const deleteAbouts = (payload, id) => async dispatch => {
  try{
      await axiosConn.post(`/${modelName}/delete`, payload);
      dispatch(fetchAbouts(id));
    }catch(err) {
  }
};

export const addAboutImages = ( payload, code ) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/addImages`,payload)
    dispatch(fetchAbouts());
  }catch(err) {
    console.log(err)
  }
};
export const deleteAboutImages = (payload, id) => async dispatch => {
  try{
    await axiosConn.post(`/${modelName}/removeImage`, payload);
    dispatch(fetchAbouts(id));
  }catch(err) {
  }
};