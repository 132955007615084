import { Link} from 'react-router-dom'
import { Row, Col, Button } from 'antd';
import {mainColors} from '../../values/colors'
import {btn} from '../../values/styles'
import {Container} from 'react-bootstrap'
import TkwnButton from '../linkButtonLong'
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment'
import { getFileLink } from '../LibraryMethods';
import { useEffect, useState } from 'react';

function parseDat(datt) {
  return datt ? moment(datt).format('DD/MMM/YYYY') : ''
}

const LastBlogsSection = ({recentBlogs}) => {
  const [isLgSc, setisLgSc] = useState(true)
  const [isSmSc, setisSmSc] = useState(false)
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
    setisSmSc(window.innerWidth < 601)
  },[])
  console.log("blogs: ------------------- recent : ", recentBlogs?.sort((a,b) => {return new Date(b['createdAt'] ) - new Date(a['createdAt']) }))
  var allBlogsCarsl = recentBlogs?.sort((a,b) => {return new Date(b['createdAt'] ) - new Date(a['createdAt']) })?.map(e => {
    return {code: e.code,createdAt: parseDat(e.createdAt), date: parseDat(e.date),category: e.category,name: e.name, logo: getFileLink(e.logo), description: e.description}
  })
  return (
    <>
      <Container fluid={isSmSc} className={isSmSc ? ' px-2' : ""}>
        <InfiniteScroll
          className={(isSmSc ? 'mx-0' : '') + ' row col-12 text-start'}
          dataLength={allBlogsCarsl?.length}
          loader={<h4>Loading...</h4>}
          style={{width: "100%",height: '492px'}}
        >
          {allBlogsCarsl && allBlogsCarsl?.map(ee => {
            return (
              <div
                className='col-12 px-0 my-2'
                key={"dedevrdeswgfr" + ee.code}
              >
                <Link
                className='col-12 p-0 d-flex'
                to={ "/blog/" + ee.code}
                style={{textDecorationLine: 'none',color: mainColors.blue,height:'188px'}}
                >
                  <span className="pr-2 py-0 col-5">
                    <span style={{
                      backgroundImage: `url(${ee.logo})`,
                      height: '100%',
                      flexShrink: '0',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center center'
                    }} className="fillImg" />
                  </span>
                  <span className=' d-flex col-7 p-3' style={{
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}>
                    <div className='text-start' style={{fontFamily:'RobotoItalic',fontSize:'11px'}}>{ee.createdAt}</div>
                    <div className="mb-4" style={{
                      fontFamily:'JostMedium',
                      fontSize:'22px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: isLgSc ? '200px' : '100px',
                    }}>
                      {ee.name}
                    </div>
                    <div style={{...btn.btnBlueLight, fontFamily:'JostMedium', fontSize:'22px'}} className='col-12 d-flex justify-content-between align-items-center cstm-btn-blue-outline'>
                      <span className=''>Read More</span>
                      {/* <RightOutlined /> */}
                    </div>
                  </span>
                </Link>
              </div>
            )
          })}
        </InfiniteScroll>
      </Container>
    </>
  );
};

export default LastBlogsSection;