import { Upload, Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Modal, Card, Input, DatePicker } from 'antd';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { addPartner } from '../../../actions/partnerActions';
import { useParams } from 'react-router-dom';
import { Select, Col, Row } from 'antd';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import { checkSize, getBase64, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';

function handleChange(value) {
  console.log(`selected ${value}`);
}

const AddEditPartner = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  let dispatch = useDispatch();
  let [file, setFile] = useState(null);
  const [fileRead, setFileRead] = useState(null)
  const [loading, setloading] = useState(false)

  function validate() {
    console.log("file: ",file)
    if(!file){
      message.error(`Please provide file!`);
      return
    }
    let data = new FormData();
    data.append('logo', file);
    dispatch(addPartner(data));
    handleOk();
  }

  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFile(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFileRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFile(null);
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <>
      <Modal title={action+' Partner'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Upload
          {...uploaderProps}
        >
          {fileRead ? <img src={fileRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  galleries: state.partner.galleries,
});

export default connect(mapStateToProps, null)(AddEditPartner);