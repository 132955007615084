import { Menu, Dropdown, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const FilterDropDown = ({handleFilter,payload}) => {
  const menu = (
    <Menu key="dlrejnflrjfn" onClick={handleFilter}>
      <Menu.Item key="">All</Menu.Item>
      {payload.map((ee) => {
        return (
          <Menu.Item key={ee.code}>{ee.name}</Menu.Item>
        )
      })}
    </Menu>
  );
  return (
    <Dropdown className='btn btn-light' overlay={menu}>
      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        Filter <DownOutlined />
      </a>
    </Dropdown>
  )
}

export default FilterDropDown;