import React, { useEffect, useState } from 'react';
import { mainColors } from '../../values/colors';
import { LeftOutlined,RightOutlined} from '@ant-design/icons'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick';
import { getFileLink } from '../LibraryMethods';

const TestimonialsSection = ({testimonials}) => {
  const [isLgSc, setisLgSc] = useState(window.innerWidth > 600)
  const [isArrowAllowed, setisArrowAllowed] = useState(true)
  const [allTestimonialsCarsl, setreadyItems] = useState([])
  useEffect(() => {
    setisLgSc(window.innerWidth > 600)
    setisArrowAllowed(isLgSc)
  }, [isLgSc, testimonials?.length])

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    ><LeftOutlined /></button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    ><RightOutlined /></button>
  );
  const slideSettings = {
    dots: true,
    arrows: isArrowAllowed,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    dotsClass: isLgSc ? ' slick-dots customTetimonialSection ' : ' slick-dots customTestimonialsRespoSection ',
    swipeToSlide: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    slidesToScroll: 1,
  }
  useEffect(() => {
    setreadyItems(testimonials?.map(e => {
      return {
        code: e.code,
        name: e.name,
        position: e.position,
        logo: getFileLink(e.logo),
        avatar: getFileLink(e.avatar),
        mainDesc: e.mainDesc,
        description: e.description
      }
    }))
  },[testimonials])
  console.log("testimonials in component: ", testimonials)
  return (
    <>
      <Container className='px-0'>            
        {allTestimonialsCarsl.length && <Slider className='d-flex justify-content-center' {...slideSettings}>
          {allTestimonialsCarsl?.map((e, indx) => {
            return (
              <div key={indx}>
                {isLgSc ? <div dangerouslySetInnerHTML={{ __html: `
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1342" height="742" viewBox="0 0 1342 742">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_2" data-name="Rectangle 2" width="1342" height="742"/>
                      </clipPath>
                    </defs>
                    <g id="Web_1920_1" data-name="Web 1920 – 1" clip-path="url(#clip-path)">
                      <rect id="Rectangle_1" data-name="Rectangle 1" width="1342" height="742" fill="#fff"/>
                      <g id="Group_8" data-name="Group 8" transform="translate(33 -4421)">
                        <path id="Path_82" data-name="Path 82" d="M1255.977.975c-73.3,262.156-141.3,511.869-214.6,759.922-7.065,22.4-52.1,48.947-80.363,48.947C705.8,813.992,449.7,812.333,177.7,812.333c11.48-47.288,19.428-87.109,30.025-126.1C262.478,485.467,319,283.872,374.633,83.107,387,36.649,396.711-1.513,467.36.146,722.579,3.464,977.8.975,1255.977.975Z" transform="translate(268.548 4419.703)" fill="#015076"/>
                        <path id="Path_311" data-name="Path 311" d="M1255.977.975c-73.3,262.156-141.3,511.869-214.6,759.922-7.065,22.4-52.1,48.947-80.363,48.947C705.8,813.992,449.7,812.333,177.7,812.333c11.48-47.288,19.428-87.109,30.025-126.1C262.478,485.467,319,283.872,374.633,83.107,387,36.649,396.711-1.513,467.36.146,722.579,3.464,977.8.975,1255.977.975Z" transform="translate(-605.488 4419.703)" fill="#015076"/>
                      </g>
                      <g id="Rectangle_3" data-name="Rectangle 3" transform="translate(685 111)" fill="none" stroke="#fff" stroke-width="1">
                        <rect width="405" height="400" stroke="none"/>
                        <rect x="0.5" y="0.5" width="404" height="399" fill="none"/>
                        <foreignObject width="404" height="360" viewBox="0 0 399 404">
                          <rect height="360" class="p-5 p-wrap d-flex" style="font-size: 18px; fontFamily: 'RobotoLight';text-align:left; color: ${mainColors.white}" xmlns="http://www.w3.org/1999/xhtml">${e.description}</rect>
                        </foreignObject>
                      </g>
                      <path id="Path_3" data-name="Path 3" d="M431.79.287c-17.272,57.342-33.3,111.962-50.568,166.219-1.665,4.9-12.278,10.706-18.937,10.706-60.141.907-120.49.544-184.585.544,2.705-10.343,4.578-19.054,7.075-27.582C197.678,106.26,211,62.165,224.106,18.251c2.913-10.162,5.2-18.509,21.85-18.146C306.1.831,366.239.287,431.79.287Z" transform="translate(275.584 249.708)" fill="#fff"/>
                      <text id="_" data-name="“" transform="translate(723 177)" fill="#fff" font-size="110" font-family="Jost-BoldItalic, Jost" font-weight="700" font-style="italic"><tspan x="-26.125" y="0">“</tspan></text>
                      <text id="_2" data-name="“" transform="translate(1054 448) rotate(180)" fill="#fff" font-size="110" font-family="Jost-BoldItalic, Jost" font-weight="700" font-style="italic"><tspan x="-26.125" y="0">“</tspan></text>
                      <image id="_3" width="404" height="349" transform="translate(0 159)" xlink:href="${e.logo}"/>
                      <text id="TESTIMONIALS" transform="translate(0 135)" fill="#fff" font-size="40" font-family="Jost-BoldItalic, Jost" font-weight="700" font-style="italic"><tspan x="0" y="0">TESTIMONIALS
                      </tspan></text>
                      <text id="${e.name}" data-name="${e.name}" transform="translate(510 342)" fill="#c81436" font-size="22" font-family="JostMedium" font-weight="500"><tspan x="0" y="0">${e.name}</tspan></text>
                      <text id="${e.position}" data-name="${e.position}" transform="translate(510 365)" fill="#c81436" font-size="14" font-family="Jost-Light, JostLight" font-weight="300"><tspan x="0" y="0">${e.position}</tspan></text>
                      <image id="${e.avatar}" width="90" height="31" transform="translate(519 282)" xlink:href="${e.avatar}"/>
                    </g>
                  </svg>                    
                `}}/>
                : <div className='col-12 d-flex container justify-content-center mx-0 py-3 row'>
                    <div className='col-12 py-4' style={{color:mainColors.white,fontSize: '40px', fontFamily: 'JostBoldItalic'}}>TESTIMONIALS</div>
                    <div className='col-12 py-3 bg-white rounded'>
                      <div className='fillImg col-12'><img alt="startup logo" style={{width:'50%'}} src={e.avatar}/></div>
                      <div className='col-12 p-wrap' style={{fontFamily: 'JostMedium', fontSize: '22px',color:mainColors.red }}>{e.name}</div>
                      <div className='col-12 p-wrap' style={{fontFamily: 'JostLight', fontSize: '14px',color:mainColors.red }}>{e.position}</div>
                      <div className='fillImg py-4 px-0 col-12'><img alt="startup logo" style={{width:'100%'}} src={e.logo}/></div>
                      <div className='col-12' style={{fontFamily: 'Jost', fontSize: '22px',color:mainColors.blue }}>{e.description}</div>
                    </div>
                </div>
              }
              </div>
            )
          })
          }
        </Slider>}
      </Container>
    </>
  );
};

export default TestimonialsSection;