import axios from 'axios';

const axiosConn = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export default axiosConn;

// Request interceptor (Outgoing)
axiosConn.interceptors.request.use(function (config) {
    // Do something before request is sent   
    console.log('Interceptor Request (Outgoing) ', config);
   
    // config.headers.API_KEY = 'YOUR_API_KEY';

    if(localStorage.getItem('token')) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    return config;
  }, function (error) {
    // Request error
    return Promise.reject(error);
});
  
// Response interceptor (Incoming) - Optional
axiosConn.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger

    // Response data
    console.log('Interceptor Response (Incoming) ', response);

    return response;
  }, function (error) {

    // Any status codes that falls outside the range of 2xx cause this function to trigger

    // Do something with response error
    return Promise.reject(error);
});  
axios.defaults.withCredentials = true;
