import { FETCH_GALLERIES, FETCH_LAST_GALLERY } from '../actions/types';
const initialState = {
  galleries: [],
  lastGallery: {},
};

export const GalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GALLERIES:
      return {
        ...state,
        galleries: action.payload.galleries,
      };
      case FETCH_LAST_GALLERY:
        return {
          ...state,
          lastGallery: action.payload.lastGallery,
        };
      default:
      return {...state};
  }
}