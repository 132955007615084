import './App.css';
import {
  Switch,
  Route,
  Redirect,
  HashRouter as BrowserRouter,
} from "react-router-dom";
import AdminLayout from './Layouts/admin/AdminLayout';
import HomeLayout from './Layouts/HomeLayout';
import HomePage from './pages/HomePage';
import StartupsPage from './pages/StartupsPage'
import TeamsPage from './pages/TeamsPage'
import TrainersPage from './pages/TrainersPage'
import MentorsPage from './pages/MentorsPage'
import ProgramsPage from './pages/ProgramsPage';
import store from './store'
import { Provider } from 'react-redux'
import AboutPage from './pages/AboutPage';
import BlogsPage from './pages/BlogsPage';
import BlogPage from './pages/BlogPage';
import GalleryPage from './pages/GalleryPage';
import takeActionsPage from './pages/TakeActionsPage';
import LoginPage from './pages/admin/LoginPage'
import AdminHomePage from './pages/admin/home/HomePage'
import AdminAboutPage from './pages/admin/home/AboutPage';
import AdminAboutTeamSection from './pages/admin/home/AboutTeamSection';
import AdminMentorPage from './pages/admin/home/MentorPage';
import AdminTeamPage from './pages/admin/home/TeamPage';
import AdminTrainerPage from './pages/admin/home/TrainerPage';
import AdminStartupPage from './pages/admin/home/StartupPage';
import AdminProgramPage from './pages/admin/home/ProgramPage';
import AdminTestimonialPage from './pages/admin/home/TestimonialPage';
import AdminGalleryPage from './pages/admin/home/GalleryPage';
import AdminBlogPage from './pages/admin/home/BlogPage';
import AdminTakeActionPage from './pages/admin/home/TakeActionPage';
import AdminPartnerPage from './pages/admin/home/PartnerPage';
import AdminFooterPage from './pages/admin/home/FooterPage';
import AdminPartialPage from './pages/admin/home/PartialPage';
import PageNotFound from './pages/PageNotFound404'

function App() {
  return (
    <Provider store={store}>
    <div className="App">
      <BrowserRouter>
        <div>
          <Switch>
            <Route path="/" exact>
              <HomeLayout component={HomePage} pageName={'home'}/>
            </Route>
            <Route path="/programs" exact>
              <HomeLayout component={ProgramsPage} pageName={'program'}/>
            </Route>
            <Route path="/startups" exact>
              <HomeLayout component={StartupsPage} pageName={'startup'}/>
            </Route>
            <Route path="/about" exact>
              <HomeLayout component={AboutPage} pageName={'about'}/>
            </Route>
            <Route path="/teams" exact>
              <HomeLayout component={TeamsPage} pageName={'teams'}/>
            </Route>
            <Route path="/gallery" exact>
              <HomeLayout component={GalleryPage} pageName={'gallery'}/>
            </Route>
            <Route path="/blog/:code" exact>
              <HomeLayout component={BlogPage} pageName={'blogs'}/>
            </Route>
            <Route path="/blogs" exact>
              <HomeLayout component={BlogsPage} pageName={'blogs'}/>
            </Route>
            <Route path="/trainers" exact>
              <HomeLayout component={TrainersPage} pageName={'trainers'}/>
            </Route>
            <Route path="/mentors" exact>
              <HomeLayout component={MentorsPage} pageName={'mentors'}/>
            </Route>
            <Route path="/programs/:code" exact>
              <HomeLayout component={ProgramsPage} pageName={'program'}/>
            </Route>
            <Route path="/takeAction" exact>
              <HomeLayout component={takeActionsPage} pageName={'takeAction'}/>
            </Route>

            <Route path="/admin" exact>
              <Redirect to="/admin/home" />
              <AdminLayout pageName={'home'} component={AdminHomePage} />
            </Route>
            <Route path="/admin/home" exact>
              <AdminLayout pageName={'home'} component={AdminHomePage} />
            </Route>
            <Route path="/admin/about" exact>
              <AdminLayout pageName={'about'} component={AdminAboutPage} />
            </Route>
            <Route path="/admin/aboutTeamSection" exact>
              <AdminLayout pageName={'aboutTeamSection'} component={AdminAboutTeamSection} />
            </Route>
            <Route path="/admin/mentors" exact>
              <AdminLayout pageName={'mentors'} component={AdminMentorPage} />
            </Route>
            <Route path="/admin/teams" exact>
              <AdminLayout pageName={'teams'} component={AdminTeamPage} />
            </Route>
            <Route path="/admin/trainers" exact>
              <AdminLayout pageName={'trainers'} component={AdminTrainerPage} />
            </Route>
            <Route path="/admin/startups" exact>
              <AdminLayout pageName={'startups'} component={AdminStartupPage} />
            </Route>
            <Route path="/admin/programs" exact>
              <AdminLayout pageName={'programs'} component={AdminProgramPage} />
            </Route>
            <Route path="/admin/testimonials" exact>
              <AdminLayout pageName={'testimonials'} component={AdminTestimonialPage} />
            </Route>
            <Route path="/admin/gallery" exact>
              <AdminLayout pageName={'gallery'} component={AdminGalleryPage} />
            </Route>
            <Route path="/admin/partners" exact>
              <AdminLayout pageName={'partners'} component={AdminPartnerPage} />
            </Route>
            <Route path="/admin/footer" exact>
              <AdminLayout pageName={'footer'} component={AdminFooterPage} />
            </Route>
            <Route path="/admin/partial" exact>
              <AdminLayout pageName={'partial'} component={AdminPartialPage} />
            </Route>
            <Route path="/admin/blogs" exact>
              <AdminLayout pageName={'blogs'} component={AdminBlogPage} />
            </Route>
            <Route path="/admin/takeActions" exact>
              <AdminLayout pageName={'takeActions'} component={AdminTakeActionPage} />
            </Route>
            <Route path="/admin/login" exact>
              <AdminLayout pageName={'login'} component={LoginPage} />
            </Route>
            <Route path="*" exact>
              <HomeLayout pageName={'notFound'} component={PageNotFound} />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </div>
    </Provider>
  );
}

export default App;
