import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import AddEditHome from "../../../components/admin/Homes/AddEditHome";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import { fetchHomes } from '../../../actions/homeActions';
import HomeCard from '../../../components/admin/Homes/HomeCard';
import { Link, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { Container } from '@mui/material';
import { fetchStartups } from '../../../actions/startupActions';

const Homes = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [action, setAction] = useState(false);
    let dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchHomes());
      dispatch(fetchStartups());
    },[dispatch]);

    let homeSingle = useSelector(state => state.home.homes);
    let homes = [homeSingle];

    const showModal = (action) => {
      setAction(action);
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return (
      <>
        <PageHeader
          className="site-page-header shadow"
          title={'Home'}
          extra={
            <Button key={"edit" + homeSingle?.id} onClick={() => showModal('Edit')} type="primary" icon={<EditOutlined />} />
          }
          onBack={() => window.history.back()}
        />
        <Container>
          <Row gutter={[20,20]} className="my-3 col-12 row" style={{padding: 15}}>
            {homes.length ===0 && <div style={{marginTop:17}}>No homes are available</div>}
            {homes.map((home,ind) => {
              return (
                <Col span={24} key={ind} className="pa-5">
                  {homeSingle && <HomeCard home={homeSingle} />}
                </Col>
              );
            })}
          </Row>
          { isModalVisible &&
            <AddEditHome action={action} payload={homeSingle} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
          }
        </Container>
      </>
    );
  }

  const mapStateToProps = state => ({
    homes: state.home.homes,
    startups: state.startup.startups,
  });

  export default connect(mapStateToProps, null)(Homes);