import React, { useState } from 'react';
import { Card,Row,Col } from 'antd';
import { CloseSquareOutlined, EditOutlined, DeleteOutlined,AppstoreAddOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import AddEditHome from './AddEditHome';
import { Link, useParams } from 'react-router-dom';
import { AddOutlined, VisibilityOutlined } from '@mui/icons-material';
import { mainColors } from '../../../values/colors';
import { getFileLink } from '../../LibraryMethods';

const { Meta } = Card;

const HomeCard = ({home}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalImageVisible, setIsModalImageVisible] = useState(false);
  const [action, setAction] = useState(false);
  let dispatch = useDispatch();
  let {code} = useParams();
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (action) => {
    setAction(action);
    setIsModalVisible(true);
  };

return(
  <>
    <Card
      className='shadow'
      style={{height: 'auto',width:'300px', maxWidth: '100%', minWidth: '100%',borderRadius:5, marginTop: 2.5, padding:15 }}
      cover={
        <>
          <div style={{backgroundColor: mainColors.light,width: '100%', maxHeight: 400}} className='fillImg' >
            <img
              src={`${getFileLink(home?.logo)}`}
              alt="avatar"
            />
          </div>
          <hr/>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Text on Cover: </b></span><span style={{fontSize: '19px'}} className='col'>{home.name}</span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Description on Cover: </b></span><span className="p-wrap" style={{fontSize: '19px'}} className='col p-wrap'>{home.description}</span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Apply Now Button Url on Cover: </b></span><span style={{fontSize: '19px'}} className='col'>{home.applyLink}</span></div>
          <hr/>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Startups Count: </b></span><span style={{fontSize: '19px'}} className='col'>{home.startupsNum}</span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Training Hours: </b></span><span style={{fontSize: '19px'}} className='col'>{home.trainingHoursNum}</span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Mentors Count: </b></span><span style={{fontSize: '19px'}} className='col'>{home.mentorsNum}</span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Cohorts Count: </b></span><span style={{fontSize: '19px'}} className='col'>{home.cohortsNum}</span></div>
          <hr/>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Our Partners - Name: </b></span><span style={{fontSize: '19px'}} className='col'>{home.ourPartnerName}</span></div>
          <div className='text-start col-12'><span style={{fontSize: '22px'}} className='col'><b>Our Partners - Description: </b></span><span style={{fontSize: '19px'}} className='col p-wrap'>{home.ourPartnerDescription}</span></div>
        </>
      }
    />
    { isModalVisible &&
      <AddEditHome action={action} payload={home} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}  />
    }
  </>
)};

export default HomeCard;
