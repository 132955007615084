import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddEditGallery from "../../../components/admin/Galleries/AddEditGallery";
import { useDispatch } from 'react-redux';
import { connect, useSelector } from "react-redux";
import { fetchGalleries } from '../../../actions/galleryActions';
import GalleryCard from '../../../components/admin/Galleries/GalleryCard';
import { useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { Container } from 'react-bootstrap';

const Galleries = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [action, setAction] = useState(false);
    let dispatch = useDispatch();

    let { id } = useParams()

    useEffect(() => {
      dispatch(fetchGalleries());
    },[dispatch]);

    let galleries = useSelector(state => state.gallery.galleries);

    const showModal = (action) => {
      setAction(action);
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return (
      <>
        <PageHeader
          className="site-page-header shadow"
          title={'Galleries ' + '(' + galleries.length + ')'}
          extra={[
            <Button key="buttonAdd" onClick={() => showModal('Add')} type="primary" icon={<PlusOutlined />} />
          ]}
          onBack={() => window.history.back()}
        />
        <Container>
          <Row gutter={[20,20]} className="my-3 col-12 row" style={{padding: 15}}>
            {galleries.length === 0 && <div style={{marginTop:17}}>No galleries are available</div>}
            {galleries.map((gallery,ind) => {
              return (
                  <Col span={24} key={ind} className="pa-5">
                    <GalleryCard gallery={gallery} />
                  </Col>
              );
            })}
          </Row>
          { isModalVisible &&
            <AddEditGallery action={action} handleCancel={handleCancel} handleOk={handleOk} isModalVisible={isModalVisible}/>
          }
        </Container>
      </>
    );
  }

  const mapStateToProps = state => ({
    galleries: state.gallery.galleries,
  });

  export default connect(mapStateToProps, null)(Galleries);