import { FETCH_TRAINERS } from '../actions/types';
const initialState = {
  trainers: []
};

export const TrainerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRAINERS:
      return {
        ...state,
        trainers: action.payload.trainers,
      };
    default:
      return {...state};
  }
}