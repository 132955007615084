import React, { useEffect, useState } from 'react';
import { Upload, Button, message, Tabs, Modal, Input, DatePicker, Select, Col, Row } from 'antd';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { addGalleryImages, addGalleryVideos, fetchGalleries } from '../../../actions/galleryActions';
import { checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { useParams } from 'react-router-dom';

const { TabPane } = Tabs;
function handleChange(value) {
  console.log(`selected ${value}`);
}

const AddEditGalleryImage = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  let [file, setFile] = useState(null);
  // neccesary
  const [fileRead, setFileRead] = useState(null)
  const [loading, setloading] = useState(false)
  const [fileType, setFileType] = useState('')
  
  let dispatch = useDispatch();

  let {code} = useParams();

  useEffect(async () => {
    await dispatch(fetchGalleries());
  },[dispatch]);

  async function validate() {
    if (fileType === 'image') {
      let data = new FormData();
      data.append('galleryCode', payload.galleryCode)
      data.append('logo', file);
      if(!file){
        message.error(`Please provide file!`);
        return
      }
      await dispatch(addGalleryImages(data, code))
      message.success(`Image Added!`);
    }

    if (fileType === 'video') {
      let data = {
        galleryCode: payload.galleryCode,
        logo: file,
      }
      if(!file){
        message.error(`Please provide a Youtube Link!`);
        return
      }
      await dispatch(addGalleryVideos(data, code))
      message.success(`Youtube Link Added!`);
    }
    handleOk();
  }

  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFile(info.file)
      setFileType('image')
      setloading(true)
      getBase64(info.file, imageUrl => { setFileRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFile(null);
        setFileType('')
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal title={action+' Gallery'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <div className="card-container">
          <Tabs type="card">
            <TabPane tab="Add Image" key="1">
              <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
                <Col span={24}>
                  <Upload
                    {...uploaderProps}
                  >
                    {fileRead ? <img src={fileRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Add Youtube Link" key="2">
              <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
                <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Youtube Link Address" value={file} onChange={(evt) => {setFile(evt.target.value);setFileType('video')}} /></Col>
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  galleries: state.gallery.galleries,
});

export default connect(mapStateToProps, null)(AddEditGalleryImage);