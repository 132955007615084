import { FETCH_HOMES,FETCH_FOOTERS, FETCH_ALL_DASHBOARD } from './types'
import axios from 'axios';
import axiosConn from '../axiosConn';
const modelName = "homes"
const footerName = "footers"

export const fetchHomes = () => async dispatch => {
  try {
    let firstResponse = {}
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}`);
    if (response.data.data[0]) {
      firstResponse = response.data.data[0]
    }
    await dispatch({
      type: FETCH_HOMES,
      payload: {
        homes: firstResponse
      }
    })
  } catch(err) {
    console.log(err);
  }
};
export const fetchFooters = () => async dispatch => {
  try {
    let firstResponse = {}
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/${footerName}/`);
    if (response.data.data[0]) {
      firstResponse = response.data.data[0]
    }
    await dispatch({
      type: FETCH_FOOTERS,
      payload: {
        homes: firstResponse
      }
    })
  } catch(err) {
    console.log(err);
  }
};
export const fetchHomesDashboard = () => async dispatch => {
  try {
    let homes = []
    let homesFetched = await axios.get(`${process.env.REACT_APP_API_URL}/homes`);
    if (homesFetched.data.data[0]) {
      homes = homesFetched.data.data[0]
    }

    let partials = []
    let partialsFetched = await axios.get(`${process.env.REACT_APP_API_URL}/partials`);
    if (partialsFetched.data.data[0]) {
      partials = partialsFetched.data.data[0]
    }

    let partners = []
    let partnersFetched = await axios.get(`${process.env.REACT_APP_API_URL}/partners`);
    if (partnersFetched.data.data[0]) {
      partners = partnersFetched.data.data
    }

    let abouts ={aboutImages: []}
    let aboutsFetched = await axios.get(`${process.env.REACT_APP_API_URL}/abouts`);
    let responseImages = await axios.get(`${process.env.REACT_APP_API_URL}/${modelName}/Images`);
    if (aboutsFetched.data.data[0]) {
      abouts = aboutsFetched.data.data
    }
    abouts.aboutImages = responseImages?.data?.data

    let programs = []
    let programsFetched = await axios.get(`${process.env.REACT_APP_API_URL}/programs/recent`);
    if (programsFetched.data.data[0]) {
      programs = programsFetched.data.data
    }

    let testimonials = []
    let testimonialsFetched = await axios.get(`${process.env.REACT_APP_API_URL}/testimonials`);
    if (testimonialsFetched.data.data[0]) {
      testimonials = testimonialsFetched.data.data
    }

    let lastGallery = []
    let lastGalleryFetched = await axios.get(`${process.env.REACT_APP_API_URL}/galleries/lastGallery`);
    if (lastGalleryFetched.data.data) {
      lastGallery = lastGalleryFetched.data.data?.galleries
    }

    let recentBlogs = []
    let recentBlogsFetched = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/recent`);
    if (recentBlogsFetched.data.data[0]) {
      recentBlogs = recentBlogsFetched.data.data
    }

    let startups = []
    let startupsFetched = await axios.get(`${process.env.REACT_APP_API_URL}/startups/recent`);
    if (startupsFetched.data.data[0]) {
      startups = startupsFetched.data.data
    }

    let takeActions = {}
    let takeActionsFetched = await axios.get(`${process.env.REACT_APP_API_URL}/takeActions`);
    if (takeActionsFetched.data.data[0]) {
      takeActions = takeActionsFetched.data.data[0]
    }

    const payloadFetched = {
      homes: homes,
      partials: partials,
      partners: partners.sort((a,b) => {return a["createdAt"] - b["createdAt"] }),
      startups: startups.sort((a,b) => {return a["createdAt"] - b["createdAt"] }),
      abouts: abouts,
      programs: programs.sort((a,b) => {return b["createdAt"] - a["createdAt"] }),
      takeActions: takeActions,
      testimonials: testimonials.sort((a,b) => {return b["createdAt"] - a["createdAt"] }),
      lastGallery: lastGallery,
      recentBlogs: recentBlogs.sort((a,b) => {return b["createdAt"] - a["createdAt"] }),
    }
    console.log("payloadFetched: ", payloadFetched)
    await dispatch({
      type: FETCH_ALL_DASHBOARD,
      payload: {
        homeDashboard: payloadFetched,
      }
    });
  } catch (err) {
    console.log('err iccured in dashboard: ', err)
  }
}
export const editHome = ( payload ) => async dispatch => {
  try{
      console.log("payloadformdata: ", payload)
      await axiosConn.post(`/${modelName}/update`,payload)
      dispatch(fetchHomes());
  }catch(err) {
      console.log(err)
   }
};
