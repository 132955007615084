import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { addBlog, editBlog, fetchBlogs } from '../../../actions/blogActions';
import { Upload, Button, message, InputNumber, DatePicker, Row, Col, Modal, Input, Select } from 'antd';
import { blogCategoryOptions, checkSize, getBase64, getFileLink, isJpgOrPng, textSizeAreaOptions } from '../../LibraryMethods';
import { UploadOutlined,LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import * as moment from 'moment';
import {Checkbox} from 'antd'
const {Option} = Select
const AddEditBlog = ({isModalVisible, handleOk, handleCancel, action, payload}) => {
  let [description, setDescription] = useState('');
  let [summary, setSummary] = useState('');
  let [name, setName] = useState('');
  let [status, setStatus] = useState(false);
  let [category, setCategory] = useState('');
  const [file, setFile] = useState(null)
  // neccesary
  const [fileRead, setFileRead] = useState(null)
  const [loading, setloading] = useState(false)
  let dispatch = useDispatch();

  useEffect(async () => {
    await dispatch(fetchBlogs());
    if (action === 'Edit') {
      setDescription(payload.description)
      setSummary(payload.summary)
      setCategory(payload.category)
      setFileRead(getFileLink(payload.logo))
      setName(payload.name)
      setStatus(payload.status === 'featured' ? true : false)
    }
  },[dispatch]);

  function validate() {
    if(!category){
      message.error('Please provide Category!');
      return
    }
    if(!name){
      message.error('Please provide name!');
      return
    }
    let data = new FormData();
    data.append('description', description);
    data.append('summary', summary);
    data.append('category', category);
    data.append('name', name);
    data.append('status', status);
    if (file) data.append('logo', file);
    if(action === 'Add'){
      dispatch(addBlog(data));
    }else{
      console.log(file)
      data.append('code', payload.code)
      dispatch(editBlog(data));
    }
    message.success('updated!')
    handleOk();
  }

  const uploaderProps = {
    listType: "picture-card",
    showUploadList: false,
    className: "col-12 avatar-uploader",
    addonBefore: "Cover Image",
    maxCount: 1,
    beforeUpload: file => {
      if (!isJpgOrPng(file.type)) {
        message.error(`${file.name} ${file.type} is not image file, You can only upload JPG/PNG file!`);
      }
      const isLt2M = checkSize(file.size, 10);
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
      }
      return isJpgOrPng(file) && isLt2M;
    },
    onChange: info => {
      setFile(info.file)
      setloading(true)
      getBase64(info.file, imageUrl => { setFileRead(imageUrl); setloading(false)} );
      if(info.fileList.length === 0) {
        setFile(null);
        setloading(false)
      }
    },
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Modal title={action+' Blog'} visible={isModalVisible} onOk={()=>{ validate(); }} onCancel={() => {handleCancel();}}>
        <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 },{ xs: 12, sm: 16, md: 24, lg: 32 }]}>
          <Col span={24}>
            Blog Cover: 
            <Upload
              {...uploaderProps}
            >
              {fileRead ? <img src={fileRead} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>
            Category:
            <Select value={category} defaultValue={category} className="col-12 my-3" onChange={(evt) => {setCategory(evt);}}>
              {blogCategoryOptions?.map((e, indx) => {
                return (
                  <Option key={"ddecevce" + indx} value={e.value}>{e.name}</Option>
                )
              })}
            </Select>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''><Input addonBefore="Name" value={name} onChange={(evt) => {setName(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Summary <Input.TextArea {...textSizeAreaOptions()} value={summary} onChange={(evt) => {setSummary(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Description <Input.TextArea {...textSizeAreaOptions()} value={description} onChange={(evt) => {setDescription(evt.target.value);}} /></Col>
          <Col lg={24} md={24} sm={24} xs={24} className=''>Featured in Success Stories: <Checkbox disabled={category !== "story"} className='my-3' checked={category === "story" && status} onChange={(evt) => {setStatus(evt.target.checked);}} placeholder="Featured?" /></Col>
        </Row>
      </Modal>
    </>
  );
};
  
const mapStateToProps = state => ({
  blogs: state.blog.blogs,
});

export default connect(mapStateToProps, null)(AddEditBlog);