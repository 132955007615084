import { FETCH_BLOGS, FETCH_LAST_BLOGS } from '../actions/types';
const initialState = {
  blogs: [],
  lastBlogs: []
};

export const BlogReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOGS:
      return {
        ...state,
        blogs: action.payload.blogs,
      };
    case FETCH_LAST_BLOGS:
      return {
        ...state,
        lastBlogs: action.payload.lastBlogs,
      };  
    default:
      return {...state};
  }
}